import { useEffect, useState } from "react"
import { dataService } from "utils/dataService"
import { getLoggerInstance } from "utils/logger"

const logger = getLoggerInstance()

export const useReport = (interactionId, interactionName) => {
  const [callReported, setCallReported] = useState(false)
  const [callReporting, setCallReporting] = useState(false)

  useEffect(() => {
    setCallReported(false)
  }, [interactionId])

  const report = async () => {
    setCallReporting(true)
    try {
      await dataService.Interactions.report({ interactionId, text: "ALERT" })
      logger.info(`reported call ${interactionName} (${interactionId})`)
      setCallReported(true)
    } catch (err) {
      logger.error(`reporting call ${interactionName} (${interactionId}) failed`, err)
    } finally {
      setCallReporting(false)
    }
  }

  return { report, callReported, callReporting }
}
