export const QUEUED = "Queued"
export const SUPERVISOR = "Supervisor Calls"
export const ACTIVE = "Active Calls"

export const AUDIO = "AUDIO"
export const VIDEO = "VIDEO"

export const callChannels = {
  AUDIO,
  VIDEO,
}

const callTypes = { QUEUED, SUPERVISOR, ACTIVE }

export default callTypes
