// Task and Reservation statuses
const RINGING = "ASSIGNED"
const ANSWERED = "ACTIVE"

const ACCEPTED = "ACCEPTED"
const COMPLETED = "COMPLETED"
const TIMED_OUT = "TIMED_OUT"
const CANCELLED = "CANCELLED"

const RESERVATION_STATUS = {
  ACCEPTED,
  COMPLETED,
  TIMED_OUT,
  CANCELLED,
  RINGING,
}

const TASK_STATUS = {
  RINGING,
  ANSWERED,
}

const VIDEO = "VIDEO"
const AUDIO = "AUDIO"

module.exports = {
  RESERVATION_STATUS,
  TASK_STATUS,
  VIDEO,
  AUDIO,
}
