const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

const serviceRequest = `
Languages {
  languageService {
    languages(getLanguagesInput: {
      all: true,
      orderBy: NAME,
      orderType: ASC,
    }) {
      results {
        id
        name
      }
    }
  }
}`

const legacyDbRequest = `
Languages {
  languages(
    where: {name: {_neq: "English"}},
    order_by: {name: asc},
  ) {
    id: full18DigitId
    name
    pgId: id
  }
}`

class Languages extends DataServiceEndPoint {
  static key = "Languages"

  _hasuraProcessor = (hasuraData) => {
    this._logger.debug("[DATA] Languages updated", hasuraData)
    return hasuraData?.data?.languages || hasuraData?.data?.languageService?.languages?.results
  }

  _getMarttiApi = async () => {
    return this._httpService.get(`${this.url}/v1/languages`, {
      headers: { Authorization: `Bearer ${this.token}` },
    })
  }

  _getHasura = async (query) => {
    const result = await this._hasuraService.query({ query })
    return { data: this._hasuraProcessor(result) }
  }

  get = async (...params) => {
    const config = this._config.get("dataService.languages")
    if (["hasura-api", "hasura-service"].includes(config)) {
      const query = `query ${config === "hasura-service" ? serviceRequest : legacyDbRequest}`
      return this._getHasura(query)
    }
    return this._getMarttiApi(...params)
  }

  subscribe(fn, { data, onError } = {}) {
    return this._hasuraService.subscribe(
      fn,
      {
        subscriptionQuery: `subscription ${legacyDbRequest}`,
        data,
        postProcess: this._hasuraProcessor,
      },
      onError
    )
  }
}

module.exports = Languages
