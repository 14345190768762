import { Logger, LoggerAdapters } from "@cloudbreakus/logger"
import { config } from "./configService"
import { setupLoggerProxies } from "./electronLoggerProxy"

const _instance = new Logger()

const ignoredConsoleAttributes = [
  "ip",
  "version",
  "electronVersion",
  "level",
  "User Name",
  "User Email",
  "User Role",
  "interactionName",
  "interaction Partner",
  "User Partner ID",
  "ringingInteractionId",
]

_instance
  .attach(new LoggerAdapters.ConsoleAdapter(config, ignoredConsoleAttributes))
  .attach(new LoggerAdapters.NewRelicAdapter(config))

const instance = setupLoggerProxies(_instance)

export const getLoggerInstance = () => instance
export const logger = instance
