const { DataServiceEndPoint, HttpErrors } = require("@cloudbreakus/network-utilities")
const { DataError, ValidationError, buildLogoutErrorMessage } = HttpErrors

class Auth extends DataServiceEndPoint {
  static key = "Auth"

  handleErrorPayload(err, status) {
    if (err.response) {
      return {
        status: err.response.status,
        data: err.response.data,
        message: err.response?.data?.message,
        base: err.response,
        error: true,
      }
    } else {
      return {
        status,
        data: null,
        message: null,
        base: null,
        error: true,
      }
    }
  }

  async logout() {
    const res = await this._httpService
      .delete(`${this._config.get("authenticator.url")}/${this._config.get("authenticator.logoutEndpoint")}`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .catch((err) => this.handleErrorPayload(err, 500))
    const { status, data, error, message, base } = res

    if (`${status}`[0] !== "2") {
      throw new ValidationError(buildLogoutErrorMessage(status, error && message ? message : null), status, base)
    }

    return {
      status,
      data,
    }
  }

  async resetPassword(email) {
    try {
      const res = await this._httpService.post(
        this._config.get("authenticator.resetUrl"),
        {
          username: email,
          factorType: "EMAIL",
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
      return res
    } catch (err) {
      this._logger?.error("Password reset fail", err)
      let message = err?.response?.data?.errorSummary
      throw new DataError(message)
    }
  }

  async enableMfa() {
    const res = await this._httpService.post(`${this.url}/v1/authentication/mfa/enable`, null, {
      headers: { Authorization: `Bearer ${this.token}` },
    })
    return res
  }

  keepAlive() {
    const url = `${this._config.get("authenticator.url")}/v1/authentication/refresh`
    return this._httpService.post(
      url,
      {},
      {
        headers: { Authorization: `Bearer ${this.token}` },
      }
    )
  }
}

module.exports = Auth
