import { useCallback, useState, useEffect } from "react"
import { capitalize } from "lodash"
import { askForPermissionAndShowNotification, removePersistentNotifications } from "utils/browserNotification"

const ANSWER = "Answer"

export const useBrowserCallNotification = ({ ringingCall, acknowledged, onAccept, onAcknowledged }) => {
  const [showing, setShowing] = useState(false)
  const [clicked, setClicked] = useState(null)

  const onClick = useCallback(
    (action) => {
      if (action === ANSWER) {
        onAccept(ringingCall)
      }
      onAcknowledged(true)
    },
    [ringingCall, onAccept, onAcknowledged]
  )

  useEffect(() => {
    const broadcast = new BroadcastChannel("notification-channel")
    broadcast.onmessage = (event) => setClicked({ clicked: true, event })
    return () => {
      broadcast?.close?.()
    }
  }, [])

  useEffect(() => {
    if (clicked?.clicked) {
      setClicked(null)
      onClick(clicked?.event?.data?.action)
    }
  }, [clicked, onClick])

  useEffect(() => {
    if (!ringingCall || acknowledged) {
      setShowing(false)
      removePersistentNotifications()
      return
    }
    if (showing) {
      return //TODO: properly implement notification updating once built
    }
    setShowing(true)
    askForPermissionAndShowNotification({
      title: `Incoming ${capitalize(ringingCall?.interaction?.channel)} Call`,
      body: [
        `Unit ID: ${ringingCall?.interaction?.unitId || ringingCall?.interaction?.customerPhoneNumber || ""}`,
        `Interaction ID: ${ringingCall?.interaction?.name?.toUpperCase()}`,
        `Queue: ${ringingCall?.queue?.name}`,
      ].join("\n"),
      badge: "/favicon.ico",
      icon: "/favicon.ico",
      actions: [{ action: ANSWER, title: "Answer" }],
    })
  }, [ringingCall, acknowledged, showing])

  return
}
