import { useEffect, useState } from "react"
import { logger } from "utils/logger"
import { dataService } from "utils/dataService"
import { useAlertMessage } from "hooks/useAlertMessage"

export const useQueueSubscribe = ({ enableMine, enableAll, agentId, partnerId, setMyQueues, setAllQueues }) => {
  const { showCustomToast } = useAlertMessage()
  const [resubscribeAll, setResubscribeAll] = useState(false)
  const [resubscribeMine, setResubscribeMine] = useState(false)

  useEffect(() => {
    if (!enableAll) {
      return
    }

    let unsubscribeAll
    try {
      const onError = (err) => logger.warn("An error occurred with the queues/skills subscription", err)
      const data = partnerId ? { partnerId } : {}
      unsubscribeAll = dataService.Queues.subscribe(setAllQueues, { onError, data })
      logger.debug("Successfully subscribed to Queues/skills")
    } catch (err) {
      logger.error("An error occurred while subscribing to queues/skills", err)
      showCustomToast({
        message: `Application error: Unable to subscribe to queues/skills.`,
        level: "error",
        onRetry: () => setResubscribeAll((r) => !r),
      })
    }
    return () => {
      unsubscribeAll?.()
    }
  }, [enableAll, setAllQueues, partnerId, showCustomToast, resubscribeAll])

  useEffect(() => {
    if (!enableMine || !agentId) {
      return
    }

    let unsubscribes
    try {
      const warningMessage = "An error occurred with the active and available queues/skills subscription"
      const onError = (err) => logger.warn(warningMessage, err)
      const data = { agentId }
      unsubscribes = dataService.Queues.subscribe(setMyQueues, { onError, data })
      logger.debug("Successfully subscribed to active and available Queues/skills")
    } catch (err) {
      logger.error("An error occurred while subscribing to active and available queues/skills", err)
      showCustomToast({
        message: `Application error: Unable to subscribe to active and available queues/skills.`,
        level: "error",
        onRetry: () => setResubscribeMine((r) => !r),
      })
    }
    return () => {
      unsubscribes?.map((unsub) => unsub?.())
    }
  }, [enableMine, setMyQueues, agentId, showCustomToast, resubscribeMine])

  return
}
