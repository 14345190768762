import React, { memo, useEffect } from "react"
import { useAuth } from "contexts/Auth"
import { useProfile } from "contexts/Profile"
import { config } from "utils/configService"

const FeatureFlaggingConfig = ({ children }) => {
  const { userData } = useAuth()
  const { profile, additionalUserData } = useProfile()

  useEffect(() => {
    if (userData?.userId) {
      config.setUserId(userData?.userId)
    }
  }, [userData?.userId])

  useEffect(() => {
    if (profile?.firstName && profile?.lastName) {
      config.setUserName(`${profile.firstName} ${profile.lastName}`)
    }
  }, [profile?.firstName, profile?.lastName])

  useEffect(() => {
    if (additionalUserData?.partnerId) {
      config.setPartnerId(additionalUserData?.partnerId)
    }
  }, [additionalUserData?.partnerId])

  return <>{children}</>
}

export default memo(FeatureFlaggingConfig)
