import { useEffect, useMemo, useState } from "react"
import { clone } from "lodash"
import WebRTCClient from "@cloudbreakus/martti-webrtc-client"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { getLogLevelValue } from "@cloudbreakus/logger"
import { VIRTUAL_BACKGROUND_ENABLED } from "constants/preferences"
import { useProfile } from "contexts/Profile"
import { logger } from "utils/logger"
import { useStorage } from "@cloudbreakus/storage"
import { usePlayerBackground } from "./usePlayerBackground"

export const labelText = (role, interpreterNumber, enableAgentRoleInjector, enableAgentIdInjector) => {
  let output = []
  if (role && enableAgentRoleInjector) {
    output.push(role)
  }
  if (interpreterNumber && enableAgentIdInjector) {
    output.push(`ID: ${interpreterNumber}`)
  }
  return output.join(" ")
}

const getLoggerProxy = (webRTCLogLevel) => {
  const loggerPrefix = "WebRTCClient:"
  const lvl = getLogLevelValue(webRTCLogLevel)
  const loggerProxy = clone(logger)
  for (const evt of ["debug", "log", "info", "warn", "error"]) {
    if (getLogLevelValue(evt) >= lvl) {
      loggerProxy[evt] = (...args) => logger[evt](loggerPrefix, ...args)
    } else {
      loggerProxy[evt] = () => null
    }
  }
  return loggerProxy
}

export const usePlayerConfig = () => {
  const { additionalUserData, profile } = useProfile()
  const [virtualBackgroundAssetsFetched, setVirtualBackgroundAssetsFetched] = useState(false)
  const marttiRestUrl = useConfigFlag("oldURL")
  const webRTCLogLevel = useConfigFlag("player.logLevel")
  const allowScreenInteraction = useConfigFlag("player.allowScreenInteraction")
  const showParticipantControls = useConfigFlag("participantManagement.webRTCplayer")
  const allowParticipantPinning = useConfigFlag("player.allowParticipantPinning")
  const showLocalVideoLayoutControls = useConfigFlag("player.showLocalVideoLayoutControls")
  const [virtualBackgroundEnabledPreference] = useStorage(VIRTUAL_BACKGROUND_ENABLED, Boolean)
  const virtualBackgroundEnabledConfig = useConfigFlag("player.virtualBackground.enabled")
  const enableAgentRoleInjector = useConfigFlag("player.virtualBackground.injectAgentRole")
  const enableAgentIdInjector = useConfigFlag("player.virtualBackground.injectAgentId")
  const virtualBackgroundType = useConfigFlag("player.virtualBackground.type")
  const mccType = useConfigFlag("player.virtualBackground.mccType")
  const enableForegroundLabelInjection = useConfigFlag("player.virtualBackground.enableForegroundLabelInjection")
  const enableZoomFeatures = useConfigFlag("player.zoom.enabled")
  const zoomScaleFactor = useConfigFlag("player.zoom.scaleFactor")
  const collectStats = useConfigFlag("player.stats.collect")
  const statsCollectionIntervalSeconds = useConfigFlag("player.stats.collectionIntervalSeconds")
  const showNetworkWarnings = useConfigFlag("player.showNetworkWarnings")
  const networkWarningGracePeriodSeconds = useConfigFlag("player.networkWarningGracePeriodSeconds")
  const nmsOutboundNetworkErrorThresholdKbps = useConfigFlag("player.nmsOutboundNetworkErrorThresholdKbps")
  const nmsOutboundNetworkWarnThresholdKbps = useConfigFlag("player.nmsOutboundNetworkWarnThresholdKbps")
  const nmsLeaveCallIfAllTransportsFail = useConfigFlag("player.nmsLeaveCallIfAllTransportsFail")
  const nmsPeerConnectionStateTimeoutMs = useConfigFlag("player.nmsPeerConnectionStateTimeoutMs")
  const displayName = useMemo(
    () =>
      labelText(profile?.role, additionalUserData?.interpreterNumber, enableAgentRoleInjector, enableAgentIdInjector),
    [enableAgentRoleInjector, enableAgentIdInjector, profile?.role, additionalUserData?.interpreterNumber]
  )
  const mccUser = useMemo(() => !!additionalUserData?.partnerId, [additionalUserData?.partnerId])
  const virtualBackgroundUrl = usePlayerBackground(displayName)

  const playerConfig = useMemo(
    () => ({
      marttiRestUrl,
      logger: getLoggerProxy(webRTCLogLevel),
      allowScreenInteraction,
      showParticipantControls,
      allowParticipantPinning,
      showLocalVideoLayoutControls,
      enableVirtualBackgroundFeatures: virtualBackgroundEnabledConfig && virtualBackgroundEnabledPreference,
      virtualBackground: {
        type: mccUser && mccType ? mccType : virtualBackgroundType,
        url: virtualBackgroundUrl,
        foregroundLabelConfig: {
          showForegroundLabel: enableForegroundLabelInjection,
          foregroundLabelText: displayName,
        },
      },
      enableZoomFeatures,
      zoomScaleFactor,
      collectStats, // toggles whether to collect and log RTC stats while the call in ongoing
      statsCollectionIntervalSeconds, // the interval at which the player collects, logs, and emits stats from the webrtc connections
      showNetworkWarnings,
      networkWarningGracePeriodSeconds,
      nmsOutboundNetworkErrorThresholdKbps,
      nmsOutboundNetworkWarnThresholdKbps,
      nmsLeaveCallIfAllTransportsFail,
      nmsPeerConnectionStateTimeoutMs,
      // showWhiteboard: boolean; // if true you have to provide the whiteboard url
      // showMinMaxControls: boolean;
      // showRemoteParticipantLabels: boolean;
      // videoPreferences?: {
      //   deviceId?: string;
      //   constraint?: VideoConstraintPreset; // 'FULL_HD', 'HD', or 'VGA', 'DISABLED'
      // };
      // audioPreferences?: {
      //   deviceId?: string;
      //   constraint?: boolean; // lol // how rude
      // };
    }),
    [
      marttiRestUrl,
      webRTCLogLevel,
      allowScreenInteraction,
      showParticipantControls,
      allowParticipantPinning,
      showLocalVideoLayoutControls,
      virtualBackgroundEnabledConfig,
      virtualBackgroundEnabledPreference,
      virtualBackgroundType,
      virtualBackgroundUrl,
      mccType,
      mccUser,
      enableForegroundLabelInjection,
      enableZoomFeatures,
      zoomScaleFactor,
      displayName,
      collectStats,
      statsCollectionIntervalSeconds,
      showNetworkWarnings,
      networkWarningGracePeriodSeconds,
      nmsOutboundNetworkErrorThresholdKbps,
      nmsOutboundNetworkWarnThresholdKbps,
      nmsLeaveCallIfAllTransportsFail,
      nmsPeerConnectionStateTimeoutMs,
    ]
  )

  useEffect(() => {
    if (virtualBackgroundEnabledConfig && virtualBackgroundEnabledPreference && !virtualBackgroundAssetsFetched) {
      setVirtualBackgroundAssetsFetched(true)
      WebRTCClient.loadVirtualBackgroundAssets()
    }
  }, [virtualBackgroundEnabledConfig, virtualBackgroundEnabledPreference, virtualBackgroundAssetsFetched])

  return { playerConfig }
}
