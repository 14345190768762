import { useEffect, useState, useCallback } from "react"

/**
 * @typedef {Object} Arguments
 * @property {function(): Promise<{ results: Array<Object>, total: number }>} onDataFetch (required) - a function that resolves to an object with a `results` array and `total` number
 * @property {number} initialLimit - initial number of results per page (defaults to 10)
 * @property {number} initialPage - initial page (defaults to 1)
 * @property {number} initialResults - initial results (defaults to an empty array [])
 * @property {number} initialTotal - initial total number of results (defaults to 0)
 * @property {boolean} auto - Whether onDataFetch gets fired on render and when any of its dependencies change (defaults to true)
 */

/**
 * @typedef {Object} PaginatedResults
 * @property {Array<Object>} results Array of Objects for each element in the returned data set
 * @property {function(): Promise} getData Function to execute for refreshing the data from the provided onDataFetch function
 * @property {boolean} refreshing whether a data refresh is currently in progress
 * @property {function} setPage Takes a page number to change the current page
 * @property {number} currentPage the current page
 * @property {function} setLimit function used to set the pagination limit
 * @property {number} limit max number of elements in page
 * @property {number} totalResults total results across all pages
 * @property {boolean} executed whether the data function has been executed
 */

/**
 * @param {Arguments} arguments Object (described in {@link Arguments}) containing onDataFetch, initialLimit, and auto
 * @returns {PaginatedResults} Object described in {@link PaginatedResults}
 */
export const usePaginatedData = ({
  onDataFetch,
  initialLimit = 10,
  initialPage = 1,
  initialResults = [],
  initialTotal = 0,
  auto = true,
}) => {
  const [page, setPage] = useState(initialPage)
  const [limit, setLimit] = useState(initialLimit)
  const [totalResults, setTotalResults] = useState(initialTotal)
  const [refreshing, setRefreshing] = useState(false)
  const [results, setResults] = useState(initialResults)
  const [executed, setExecuted] = useState(false)

  const getData = useCallback(
    async (params) => {
      setRefreshing(true)
      try {
        const payload = await onDataFetch({ page, limit, params })
        const { results, total } = payload
        setResults(results)
        setTotalResults(total)
      } catch (err) {
        setResults([])
        setTotalResults(0)
      }
      setRefreshing(false)
      setExecuted(true)
    },
    [onDataFetch, page, limit]
  )

  useEffect(() => {
    // fires every time page changes, limit changes or the data function changes
    if (auto) {
      getData()
    }
  }, [auto, getData])

  return { results, getData, refreshing, setPage, currentPage: page, setLimit, limit, totalResults, executed }
}
