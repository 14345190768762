const { NON_BILLABLE_OPTIONS } = require("contexts/Call/Form/constants")
const { DTO } = require("martti-agent-services")

class CallFormClientDTO extends DTO {
  optionalFields = [
    "accessCodeMarttiUnitId",
    "partnerId",
    "partnerFriendlyName",
    "departmentId",
    "departmentName",
    "providerFirstName",
    "providerLastName",
    "patientFirstName",
    "patientLastName",
    "patientMRN",
    "patientDOB",
    "nonBillableReason",
    "phoneNumberAC",
    "bchOaklandHospitalConfirmation",
    "location",
    "memberIdNumber",
    "nyuAccessCode",
    "sentaraAccessCode",
    "visitId",
    "customerPhoneNumber",
    "accessCodeCode",
    "callerFirstName",
    "callerLastName",
  ]

  toPresentationalObject(data) {
    const {
      accessCodeMarttiUnitId,
      partnerId,
      partnerFriendlyName,
      departmentId,
      departmentName,
      providerFirstName,
      providerLastName,
      patientFirstName,
      patientLastName,
      patientMRN,
      patientDOB,
      nonBillableReason,
      phoneNumberAC,
      bchOaklandHospitalConfirmation,
      location,
      memberIdNumber,
      nyuAccessCode,
      sentaraAccessCode,
      visitId,
      customerPhoneNumber,
      accessCodeCode,
      callerFirstName,
      callerLastName,
    } = data

    return {
      department: departmentId || "",
      departmentName: departmentName || "",
      nonBillableReasons: nonBillableReason || NON_BILLABLE_OPTIONS.NONE.key,
      unitId: accessCodeMarttiUnitId || "",
      hospitalId: partnerId || "",
      hospitalName: partnerFriendlyName || "",
      patientDOB: patientDOB || null,
      patientMRN,
      patientLastName: patientLastName || "",
      patientFirstName: patientFirstName || "",
      providerFirstName: providerFirstName || "",
      providerLastName: providerLastName || "",
      nyuAccessCode: nyuAccessCode || "",
      ivrAccessCode: accessCodeCode || "",
      customerPhoneNumber: customerPhoneNumber || "",
      phoneNumberAC: phoneNumberAC || "",
      bchOaklandHospitalConfirmation: bchOaklandHospitalConfirmation || false,
      location: location || "",
      memberIdNumber: memberIdNumber || "",
      sentaraAccessCode: sentaraAccessCode || "",
      visitId: visitId || "",
      callerFirstName: callerFirstName || "",
      callerLastName: callerLastName || "",
    }
  }
}

export default CallFormClientDTO
