export const BILLING_OPTIONS = {
  BILLABLE: {
    key: "BILLABLE",
    text: "Billable",
  },
  NOT_BILLABLE: {
    key: "NOT_BILLABLE",
    text: "Not billable",
  },
}

export const NON_BILLABLE_OPTIONS = {
  NONE: {
    key: "NONE",
    text: "None",
  },
  DROPPED_CALL: {
    key: "Dropped Call",
    text: "Dropped Call",
  },
  TEST_CALL: {
    key: "Test Call",
    text: "Test Call",
  },
  NO_SERVICE_PROVIDED: {
    key: "No Service Provided",
    text: "No Service Provided",
  },
}

export const callFormKeys = {
  INTERACTION_ID: "interactionId",
  INTERACTION_NAME: "interactionName",
  DEPARTMENT: "department",
  DEPARTMENT_NAME: "departmentName",
  LANGUAGE: "language",
  NON_BILLABLE_REASONS: "nonBillableReasons",
  UNIT_ID: "unitId",
  HOSPITAL_ID: "hospitalId",
  HOSPITAL_NAME: "hospitalName",
  PATIENT_DOB: "patientDOB",
  PATIENT_MRN: "patientMRN",
  PATIENT_LAST_NAME: "patientLastName",
  PATIENT_FIRST_NAME: "patientFirstName",
  PROVIDER_FIRST_NAME: "providerFirstName",
  PROVIDER_LAST_NAME: "providerLastName",
  PHONE_NUMBER_AC: "phoneNumberAC",
  BCH_OAKLAND_HOSPITAL_CONFIRMATION: "bchOaklandHospitalConfirmation",
  LOCATION: "location",
  MEMBER_ID_NUMBER: "memberIdNumber",
  NYU_ACCESS_CODE: "nyuAccessCode",
  SENTARA_ACCESS_CODE: "sentaraAccessCode",
  VISIT_ID: "visitId",
  IVR_ACCESS_CODE: "ivrAccessCode",
  CUSTOMER_PHONE_NUMBER: "customerPhoneNumber",
  CALLER_FIRST_NAME: "callerFirstName",
  CALLER_LAST_NAME: "callerLastName",
}
