import React, { createContext, useContext } from "react"
import { usePresence as usePresenceData } from "./usePresence"
export * from "./usePresence"

const PresenceContext = createContext()

export const usePresence = () => {
  const context = useContext(PresenceContext)
  if (context === undefined) {
    throw new Error("usePresence must be used within a PresenceProvider")
  }
  return context
}

export const PresenceProvider = ({ children }) => {
  const value = usePresenceData()
  return (
    <PresenceContext.Provider
      value={value}
    >
      {children}
    </PresenceContext.Provider>
  )
}

export default PresenceContext
