import { useMemo } from "react"
import { ROLE_WEIGHTS } from "constants/roles"
import useStore from "store"
import { selectRole } from "store/selectors/auth"

export const isRoleAllowed = ({ userRole, role, minRole, maxRole }) => {
  switch (true) {
    case role === userRole:
      return true
    case minRole && ROLE_WEIGHTS[userRole] >= ROLE_WEIGHTS[minRole]:
      return true
    case maxRole && ROLE_WEIGHTS[userRole] <= ROLE_WEIGHTS[maxRole]:
      return true
    default:
      return false
  }
}

export const useRoleValidation = ({ role, minRole, maxRole }) => {
  const userRole = useStore(selectRole)

  const isAllowed = useMemo(() => {
    return isRoleAllowed({ userRole, role, minRole, maxRole })
  }, [maxRole, minRole, role, userRole])

  return isAllowed
}
