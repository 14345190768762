import { reactLikeState } from "store/utils"

const callSlice = {
  answeredCall: null,
  error: null,
  callSegments: null,
}

const createCallScreenSupportSlice = (set) => ({
  ...callSlice,
  setCallSegments: reactLikeState(set, ["callSegments"]),
  setAnsweredCall: reactLikeState(set, ["answeredCall"]),
})

export default createCallScreenSupportSlice
