import React from "react"
import cs from "classnames"
import Button from "../Button"

const defaults = {
  header: "Oops, an error has occurred",
  title: "An unexpected error has occurred",
  messages: [],
}

const ErrorPage = ({ header = defaults.header, title = defaults.title, messages = defaults.messages, onNavigate }) => {
  return (
    <>
      <main
        className={cs("min-h-screen", "max-w-screen", "flex", "justify-center", "items-center")}
        role="main"
        id="main"
      >
        <div className={cs("flex-col", "flex", "justify-center", "items-center", "p-4", "rounded-md")}>
          <h1 className={cs("text-bblue-500", "text-6xl", "font-semibold", "my-3")}>{header}</h1>
          <h2 className={cs("text-bblue-500", "text-4xl", "font-semibold", "my-2")}>{title}</h2>
          {messages?.map((message, index) => (
            <span key={index} className={cs("text-bblue-400", "text-2xl", "my-1")}>
              {message}
            </span>
          ))}
          {onNavigate && (
            <Button name={"back-home"} onClick={onNavigate} className={"rounded-3xl mt-10"}>
              Go back to home
            </Button>
          )}
        </div>
      </main>
      <footer role="contentinfo" className="hidden">
        Error Page
      </footer>
    </>
  )
}

export default ErrorPage
