import { useCallback, useMemo } from "react"
import useStore from "store/callFormStore"
import CallFormDTO from "store/dto/callForm"
import { selectFullForm, selectReset, selectStateSetter, selectStateSetterForKey } from "store/selectors/callForm"
import { callFormKeys } from "../constants"

export const useCallFormState = () => {
  const formState = useStore(selectFullForm)
  const onChange = useStore(selectStateSetterForKey)
  const reset = useStore(selectReset)
  const setDeepState = useStore(selectStateSetter)

  const setters = useMemo(() => {
    const fn = (key) => (value) => onChange(key, value)

    return Object.values(callFormKeys)
      .map((key) => {
        const str = `${key[0].toUpperCase()}${key.slice(1)}`
        return [key, `set${str}`]
      })
      .reduce(
        (setters, [actualKey, functionName]) => ({
          ...setters,
          [functionName]: fn(actualKey),
        }),
        {}
      )
  }, [onChange])

  const onCallSheetLoad = useCallback(
    (callSheet) => {
      setDeepState(new CallFormDTO(callSheet).present())
    },
    [setDeepState]
  )

  return {
    ...formState,
    ...setters,

    onChange,
    reset,
    onCallSheetLoad,
  }
}
