import React, { useCallback, useEffect, useState } from "react"
import cs from "classnames"
import ReactDatePicker from "react-datepicker"
import StringService from "@cloudbreakus/stringify"
import * as StyleSheet from "../styles.js"
import Wrapper from "../Wrapper"
import "react-datepicker/dist/react-datepicker.css"

/*
For detailed API usage, see https://reactdatepicker.com/
*/

const dateOrDefaultToMidnightUTC = (data) => {
  return new Date(StringService.format(data, StringService.HANDLERS.TIMESTAMP, { timezone: "local" }))
}

// Library uses local timezone
const CalendarComponent = ({
  name,
  label = "",
  onChange,
  theme,
  disabled = false,
  className,
  value,
  time = false,
  format = "short",
  ...rest
}) => {
  const [date, setDate] = useState(value ? dateOrDefaultToMidnightUTC(value) : null)
  const styleSheet = StyleSheet[theme]?.(disabled) || StyleSheet["profile"](disabled)
  const [userChanged, setUserChanged] = useState(false)

  useEffect(() => {
    if (onChange && userChanged) {
      const [handler, config] = time
        ? [StringService.HANDLERS.TIMESTAMP]
        : [StringService.HANDLERS.DATE, { dateFormat: format }]

      onChange(date ? StringService.format(date, handler, config) : null)
    }
  }, [date, format, onChange, time, userChanged])

  const onUserDateChange = useCallback((v) => {
    setUserChanged(() => true)
    setDate(v)
  }, [])
  
  return (
    <Wrapper label={label} className={className} name={`calendar-${name}`} styleSheet={styleSheet}>
      <ReactDatePicker
        selected={date}
        onChange={onUserDateChange}
        className={cs(styleSheet.input)}
        disabled={disabled}
        dateFormat={"yyyy-MM-dd"}
        showTimeSelect={time}
        popperClassName={"!z-50"}
        {...rest}
      />
    </Wrapper>
  )
}

export default CalendarComponent
