import { useCallback, useEffect, useMemo, useState } from "react"
import { debounce } from "lodash"

/**
 * @typedef {Object} useDebounceReturn
 * @property {Boolean} bouncing - true when any value has recently changed, false otherwise
 * @property {Function} onDebouncedChange - setState wrapper function for each subscribed input
 * @property {Function} cancel - prevents any pending debounces from eventually firing
 */

/**
 * React hook for delaying an action if any of its subscribers have recently changed their value
 *
 * @param {Number} timing - debounce delay in milliseconds (defaults to 1500)
 * @param {Function} changeDetected - fires this function with "true" when a change is detected
 * @returns {useDebounceReturn} - Object with bouncing (Boolean) and onDebouncedChange (setState wrapper function)
 */
export const useDebouncedForm = (timing = 1500, changeDetected) => {
  const [bouncing, setBouncing] = useState(false)

  const debouncedSubmission = useMemo(() => {
    return debounce(() => setBouncing(false), timing)
  }, [timing])

  const onDebouncedChange = useCallback(
    (fn) => {
      return (...args) => {
        setBouncing(true)
        changeDetected(true)
        debouncedSubmission()
        fn?.(...args)
      }
    },
    [debouncedSubmission, changeDetected]
  )

  const cancelDebounce = useCallback(() => {
    setBouncing(false)
    changeDetected(false)
    debouncedSubmission?.cancel?.()
  }, [debouncedSubmission, changeDetected])

  useEffect(() => {
    return () => {
      debouncedSubmission?.cancel?.()
    }
  }, [debouncedSubmission])

  return {
    bouncing,
    onDebouncedChange,
    cancel: cancelDebounce,
  }
}
