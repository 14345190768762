import React, { useMemo } from "react"
import CallSheetFormSection from "./CallSheetFormSection"
import { NON_BILLABLE_OPTIONS } from "contexts/Call/Form/constants"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { generateValidationFunction } from "../utils"
import WrappedInput from "./WrappedInput"
import { VALIDATION_FAILURES } from "hooks/useValidation"

const nonBilableReasonsOptions = Object.values(NON_BILLABLE_OPTIONS).map((opt) => ({
  text: opt.text,
  value: opt.key,
}))

const ValidatedInput = ({ configKey, ...rest }) => {
  const length = useConfigFlag(`callsheet.constraints.${configKey}.length`)
  const validationFunctions = useMemo(() => {
    return generateValidationFunction({
      length,
    })
  }, [length])

  return <WrappedInput {...rest} maxLength={length} validation={validationFunctions} />
}

const _departmentValidation = (evt) => {
  if (!evt.target.value) {
    return VALIDATION_FAILURES.REQUIRED
  }
  return null
}

const departmentValidation = {
  onChange: _departmentValidation,
}

const CallSheet = ({
  className,
  interactionId,
  language,
  hospitalName,
  unitId,
  department,
  departmentName,
  patientFirstName,
  patientLastName,
  patientMRN,
  patientDOB,
  providerFirstName,
  providerLastName,
  nonBillableReasons,
  departmentOptions,
  onPatientDOB,
  onNonBillableReasons,
  onDepartment,
  onPatientFirstName,
  onPatientLastName,
  onPatientMRN,
  onProviderFirstName,
  onProviderLastName,
  showPatientFirstName,
  showPatientLastName,
  showPatientMRN,
  showPatientDOB,
  showProviderFirstName,
  showProviderLastName,
  initialFetch,
  customerPhoneNumber,
  exitConfirmation,
  ExtraFields,
}) => {
  return (
    <div className={className}>
      <CallSheetFormSection title={"Call details"}>
        <WrappedInput name="interaction-id" label="Interaction ID *" value={interactionId} disabled />
        <WrappedInput name="account" label="Account *" value={hospitalName} disabled />
        <WrappedInput name="unit-id" label="Unit ID *" value={customerPhoneNumber || unitId} disabled />
        <WrappedInput
          name="non-billable-reasons"
          label="Non Billable Reasons *"
          value={nonBillableReasons}
          onChange={onNonBillableReasons}
          options={nonBilableReasonsOptions}
          type={"select"}
          disabled={exitConfirmation}
        />
        <WrappedInput
          name="language"
          label="Language *"
          value={language}
          options={[`${language}`]}
          type={"select"}
          disabled
        />
        <WrappedInput
          name="department"
          label="Department *"
          value={department || departmentName}
          options={departmentOptions?.length > 0 ? departmentOptions : [`${departmentName}`]}
          onChange={onDepartment}
          type={"select"}
          disabled={exitConfirmation || !departmentOptions?.length}
          validation={departmentValidation}
        />
      </CallSheetFormSection>
      <CallSheetFormSection
        title={"Patient"}
        className={{ hidden: !(showPatientFirstName || showPatientLastName || showPatientMRN || showPatientDOB) }}
      >
        {showPatientFirstName && (
          <ValidatedInput
            name="patient-first-name"
            label="Patient First Name *"
            value={patientFirstName}
            disabled={exitConfirmation}
            onChange={onPatientFirstName}
            configKey="patientFirstName"
          />
        )}
        {showPatientLastName && (
          <ValidatedInput
            name="patient-last-name"
            label="Patient Last Name *"
            value={patientLastName}
            onChange={onPatientLastName}
            disabled={exitConfirmation}
            configKey="patientLastName"
          />
        )}
        {showPatientMRN && (
          <ValidatedInput
            name="patient-mrn"
            label="Patient MRN *"
            value={patientMRN}
            onChange={onPatientMRN}
            disabled={exitConfirmation}
            configKey="patientMRN"
          />
        )}
        {initialFetch && showPatientDOB && (
          <ValidatedInput
            name="patient-dob"
            label="Patient DOB *"
            value={patientDOB}
            onChange={onPatientDOB}
            type={"calendar"}
            disabled={exitConfirmation}
            configKey="patientDOB"
            minDate={new Date().setFullYear("1900")}
            maxDate={new Date()}
          />
        )}
      </CallSheetFormSection>
      <CallSheetFormSection title={"Provider"} className={{ hidden: !(showProviderFirstName || showProviderLastName) }}>
        {showProviderFirstName && (
          <ValidatedInput
            name="provider-first-name"
            label="Provider First Name *"
            value={providerFirstName}
            onChange={onProviderFirstName}
            disabled={exitConfirmation}
            configKey="providerFirstName"
          />
        )}
        {showProviderLastName && (
          <ValidatedInput
            name="provider-last-name"
            label="Provider Last Name *"
            value={providerLastName}
            onChange={onProviderLastName}
            disabled={exitConfirmation}
            configKey="providerLastName"
          />
        )}
      </CallSheetFormSection>
      <ExtraFields />
    </div>
  )
}

export default CallSheet
