import React, { createContext, useContext, useCallback, useState } from "react"
import Spinner from "../../components/Spinner"

const initialState = {
  loading: false,
}

const LoadingContext = createContext()

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(initialState.loading)
  const [curtain, setCurtain] = useState(false)

  const showLoading = useCallback(() => {
    setLoading(() => true)
    setCurtain(true)
  }, [])

  const hideLoading = useCallback(() => {
    setLoading(() => false)
    setCurtain(false)
  }, [])

  const showCurtain = useCallback(() => {
    setCurtain(true)
  }, [])

  const hideCurtain = useCallback(() => {
    setCurtain(false)
  }, [])

  return (
    <LoadingContext.Provider
      value={{
        loading,
        showLoading,
        hideLoading,
        curtain,
        showCurtain,
        hideCurtain,
      }}
    >
      <>
        {curtain && (
          <div
            data-testid="curtain"
            className="
              flex flex-row justify-center items-center opacity-50 bg-gray-900 z-40 top-0 left-0 min-h-screen w-full fixed"
          />
        )}
        {loading && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50" data-testid="spinner">
            <Spinner data-testid="global-overlay" color="#146f9d" className="w-48" />
          </div>
        )}
      </>
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => {
  const context = useContext(LoadingContext)
  if (context === undefined) {
    throw new Error("useLoading must be used within an LoadingProvider")
  }
  return context
}

export default LoadingContext
