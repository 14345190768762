export const cleanFalsyValues = (json, exceptions = [false, ""]) => {
  return Object.entries(json).reduce((res, [key, value]) => {
    if (!value && exceptions.indexOf(value) === -1) {
      return res
    }
    return {
      ...res,
      [key]: value,
    }
  }, {})
}

export const parseJWT = (token) => {
  try {
    let base64Url = token.split(".")[1]
    let base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/")
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join("")
    )

    return JSON.parse(jsonPayload)
  } catch {
    return null
  }
}

export const sortAlphabeticalByName = (a, b) => (a.name?.toLowerCase().trim() > b.name?.toLowerCase().trim() ? 1 : -1)

import { AUDIO } from "constants/callTypes"
const namingClutter = /[(\s-]*(audio|video)[)]*/i
export const renameQueues = (q) => ({
  ...q,
  name: `${q?.name?.replace(namingClutter, "")}${q.channel === AUDIO ? " (audio)" : ""}`,
})
