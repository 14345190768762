import { reactLikeState } from "../utils"

const authSlice = {
  user: null,
  token: null,
  isAuthenticated: false,
  basicAuthenticated: false,
}

const createAuthSlice = (set) => {
  const setIsAuthenticated = reactLikeState(set, ["isAuthenticated"])
  const setBasicAuthenticated = reactLikeState(set, ["basicAuthenticated"])

  return {
    ...authSlice,
    setUser: (user) => set((state) => ({ user: typeof user === "function" ? user(state.user) : user })),
    setToken: (token) => set((state) => ({ token: typeof token === "function" ? token(state.token) : token })),
    setIsAuthenticated,
    setBasicAuthenticated,
  }
}

export default createAuthSlice
