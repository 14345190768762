import { useState, useCallback, useEffect } from "react"
import { dataService } from "utils/dataService"
import { getLoggerInstance } from "utils/logger"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { useAlertMessage } from "./useAlertMessage"
import { noop } from "lodash"
import { useAuth } from "contexts/Auth"

const logger = getLoggerInstance()

export const useScripts = () => {
  const { isAuthenticated } = useAuth()
  const enableHasuraSub = useConfigFlag("dataService.scripts") === "hasura-sub"
  const [scripts, _setScripts] = useState([])
  const { showCustomToast } = useAlertMessage()

  const setScripts = useCallback((scriptsData) => {
    if (!scriptsData?.sort) {
      return
    }
    _setScripts(scriptsData?.sort((a, b) => a.order - b.order))
  }, [])

  const getScripts = useCallback(async () => {
    try {
      const scriptsData = await dataService.Scripts.get()
      logger.debug("Scripts retrieved:", scriptsData.data)
      setScripts(scriptsData.data)
    } catch (err) {
      logger.error("An error occurred while fetching scripts", err)
      showCustomToast({
        message: `Unable to fetch scripts.`,
        level: "error",
        onRetry: getScripts,
      })
    }
  }, [setScripts, showCustomToast])

  useEffect(() => {
    if (!isAuthenticated || !enableHasuraSub) {
      return
    }

    let unsubscribe
    try {
      unsubscribe = dataService.Scripts.subscribe(setScripts)
      logger.debug("Successfully subscribed to Scripts")
    } catch (err) {
      logger.error("An error occurred while subscribing to scripts", err)
    }
    return () => {
      unsubscribe?.()
    }
  }, [enableHasuraSub, isAuthenticated, setScripts])

  return {
    scripts,
    getScripts: enableHasuraSub ? noop : getScripts,
  }
}

export default useScripts
