const { WINDOW_MESSAGE_TYPES } = require("../constants")
const BaseAdapter = require("./BaseAdapter")

class BrowserAdapter extends BaseAdapter {
  supportedMessages = [WINDOW_MESSAGE_TYPES.CLOSED]
  _keyToWindowMap = {}
  _services = null

  constructor(services) {
    super()
    this._services = services
  }

  send(service, payload) {
    const source = this._keyToWindowMap[service]
    source?.postMessage(payload)
  }

  attachWindow(windowObject, screenKey) {
    if (!windowObject) {
      throw new Error("No windowObject provided")
    }
    this._keyToWindowMap[screenKey] = windowObject
  }

  // TODO: Consider sending the order to close the windowObject instead of force closing it
  closeWindow(screenKey) {
    const source = this._keyToWindowMap[screenKey]
    source?.close()
  }

  handleWindowClosed(payload) {
    const { screenKey } = payload
    delete this._keyToWindowMap[screenKey]
  }

  handleGenericMessage(event) {
    if (this._services?.config?.get("inAppMessaging.logUnknownMessages")) {
      this._services?.logger?.debug(`Unknown event: `, event)
    }
  }

  handleIncomingMessage(event) {
    try {
      const { data } = event
      const type = data?.meta?.type
      switch (type) {
        case WINDOW_MESSAGE_TYPES.CLOSED:
          return this.handleWindowClosed(data?.payload)
        default:
          return this.handleGenericMessage(event)
      }
    } catch (err) {
      this?._services?.logger?.error(err)
    }
  }
}

module.exports = BrowserAdapter
