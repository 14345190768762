import React from "react"
import { useAuth } from "contexts/Auth"
import { useCall } from "contexts/Call"
import { TestDataPoint } from "components/TestDataPoint/TestDataPoint"

export function Testing() {
  const { userData } = useAuth()
  const { answeredCall, ringingCall } = useCall()
  return (
    <>
      {ringingCall ? <TestDataPoint name="ringing-call-id">{ringingCall.taskId}</TestDataPoint> : null}
      {answeredCall ? <TestDataPoint name="answered-call-id">{answeredCall.taskId}</TestDataPoint> : null}
      {userData?.userId ? <TestDataPoint name="agentId">{userData.userId}</TestDataPoint> : null}
    </>
  )
}
