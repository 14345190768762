const { pickBy } = require("lodash")
const { buildUrl, DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

class Calls extends DataServiceEndPoint {
  static key = "Calls"

  _get = (payload) => {
    return this._httpService.post(`${this.url}/v1/tasks`, pickBy(payload), {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  getSupervisor = ({ queueId, limit, page }) => {
    return this._get({
      limit,
      page,
      statuses: ["ACTIVE", "ASSIGNED", "QUEUED"],
      queueId: queueId,
    })
  }

  getQueued = ({ search, orderBy, orderType, channel, limit, page }) => {
    return this._get({
      limit,
      page,
      statuses: ["QUEUED", "ASSIGNED"],
      search,
      orderBy,
      orderType,
      channel,
    })
  }

  getActive = ({ search, orderBy, orderType, channel, limit, page }) => {
    return this._get({
      limit,
      page,
      statuses: ["ACTIVE"],
      search,
      orderBy,
      orderType,
      channel,
    })
  }

  get = ({ agentId, status = "COMPLETED", from = "", to = "", order = "" }) => {
    const parameters = buildUrl({
      status,
      "from-date": from,
      "to-date": to,
      "order-by": order,
    })
    return this._httpService.get(`${this.url}/v1/agents/${agentId}/tasks?${parameters}`, {
      headers: { Authorization: `bearer ${this.token}` },
      "axios-retry": { retries: 3 },
    })
  }

  replaceFailed = ({ taskId, failureReason }) => {
    const updateType = "FAILED"
    return this._httpService.put(
      `${this.url}/v1/tasks/${taskId}`,
      { failureReason, updateType, taskId },
      {
        headers: { Authorization: `bearer ${this.token}` },
      }
    )
  }

  answer = ({ taskId, reservationId }) => {
    const urlEnd =
      reservationId && this._config.get("calls.answerByReservationId") ? `reservations/${reservationId}` : "accept"
    const method = reservationId && this._config.get("calls.answerByReservationId") ? "put" : "post"
    return this._httpService?.[method](`${this.url}/v1/tasks/${taskId}/${urlEnd}`, null, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  close = ({ taskId, nextPresence }) => {
    return this._httpService.post(
      `${this.url}/v1/tasks/${taskId}/close`,
      nextPresence
        ? {
            nextPresence,
          }
        : null,
      {
        headers: { Authorization: `bearer ${this.token}` },
      }
    )
  }

  selfAssign = ({ taskId }) =>
    this._httpService.post(`${this.url}/v1/tasks/${taskId}/assign-to-self`, null, {
      headers: { Authorization: `bearer ${this.token}` },
    })

  thirdPartyVendors = {
    get: async ({ languageId }) => {
      return this._httpService.get(`${this.url}/v1/${languageId}`, {
        headers: { Authorization: `bearer ${this.token}` },
      })
    },
  }

  stats = {
    agents: async () =>
      this._httpService.get(`${this.url}/v1/agent-statistics`, {
        headers: { Authorization: `bearer ${this.token}` },
      }),

    interactions: async () =>
      this._httpService.get(`${this.url}/v1/interaction-statistics`, {
        headers: { Authorization: `bearer ${this.token}` },
      }),
  }

  transfer = async ({ taskId, queueId }) => {
    if (!queueId) {
      throw new Error(`DataService.Calls.Transfer requires "queueId"`)
    }
    return this._transferToQueue({ taskId, queueId })
  }

  _transferToQueue = ({ taskId, queueId }) => {
    return this._httpService.post(`${this.url}/v1/tasks/${taskId}/transfer-to-queue/${queueId}`, null, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  transferToAgent = ({ taskId, agentId, queueId }) => {
    const [url, payload] = this._config.get("calls.transferToAgentWithQueue")
      ? [`${this.url}/v1/tasks/${taskId}/transfers`, { agentId, queueId }]
      : [`${this.url}/v1/tasks/${taskId}/transfer-to-agent/${agentId}`, null]

    return this._httpService.post(url, payload, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }
}

module.exports = Calls
