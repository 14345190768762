const { invert, capitalize } = require("lodash")
const { RESERVATION_STATUS } = require("./callConstants")
const { clientEventNames, serverEventNamesV2 } = require("../constants")
const { VIDEO } = require("./callConstants")

const serverTaskToClientTask = (task) => ({
  id: task?.id,
  meetingUrl: task?.meetingUrl,
  createdDate: task?.interactionCreateTime,
  acceptTime: task?.acceptTime,
  interactionId: task?.interactionId,
  interactionMediaServer: task?.mediaServer,
  interactionName: task?.interactionName,
  interactionChannel: task?.channel,
  interactionMarttiId: task?.marttiUnitId, // used to fetch marttiData
  interactionPartnerId: task?.partnerId, // used to fetch partner data
  interactionPartnerFriendlyName: task?.hospitalName,
  customerPhoneNumber: task?.customerPhoneNumber, // displayed in UI
  languageId: task?.languageId,
  queueId: task?.queueId,
  queueName: task?.queueName, // shown in notification
  languageName: task?.languageName,
})

const serverPresenceToClientStatus = (serverPresence) => ({
  presenceName: serverPresence.name,
  presenceId: serverPresence.id,
  startTime: serverPresence.startTime,
})

const serverCallSegmentToClientCallSegment = (cs) => ({
  callSegmentId: cs?.id,
  type: cs?.type,
  notes: cs?.notes,
  language: {
    languageId: cs?.languageId,
  },
  interpreter: {
    interpreterId: cs?.interpreterId,
    external: cs?.interpreterMicroCallCenter, // used only for filtering
  },
  channel: cs?.channel,
  fullName: cs?.fullName,
  outsourcePartner: {
    name: cs?.outsourcePartnerName,
    language: cs?.outsourcePartnerLanguage,
  },
  participantMode: cs?.participantMode,
})

const AUDIO = "OTHERS"
const CUSTOMER = "CUSTOMER"
const CONSULTATION_DOCTOR = "CONSULTATION_DOCTOR"

const participantTypes = {
  INHOUSE: "Agent",
  [AUDIO]: "Third Party",
  UNKNOWN: "Guest",
  [CUSTOMER]: "Customer",
  [CONSULTATION_DOCTOR]: "Customer",
}
const serverCallParticipantToClientCallParticipant = (cp) => ({
  id: cp?.id,
  name: cp?.name,
  mode: cp?.mode,
  self: cp?.self,
  muted: cp?.muted,
  onHold: cp?.onHold,
  videoSuspended: cp?.videoSuspended,
  agent: cp?.agent,
  label: cp?.number || cp?.name,
  userId: cp?.agent?.id || cp?.agent?.interpreterId || cp?.userId || cp?.name,
  type: participantTypes[cp?.type] || capitalize(cp?.type),
  isHoldable: !(cp?.type === AUDIO),
  isCustomer: cp?.type === CUSTOMER,
})

const serverReservationToClientTask = (reservation) => ({
  reservationId: reservation.reservationId,
  interactionId: reservation.interactionId,
  interactionName: reservation.interactionName,
  taskId: reservation.taskId,
  status: reservation.status,
  createdDate: reservation.createTime,
  callerDetail: reservation.callerDetail,
  queueId: reservation.queueId,
  queueName: reservation.queueName,
  queueChannel: reservation.queueChannel,
  languageName: reservation.queueName.replace(/(\s*-)*\s*audio/i, ""),
  acceptTime: reservation.status === RESERVATION_STATUS.ACCEPTED ? reservation.createTime : null,
  interactionMarttiId: reservation.callerDetail,
  interactionChannel: reservation.interactionChannel,
  // While video channel calls are always answered through the media server, audio channel calls can be either twilio or nms, and we lack information to make the right decision just by checking the channel
  // Whenever available, mediaServer is going to tell us which subsystem was used to answer the call
  interactionMediaServer: reservation.mediaServer ?? (reservation.interactionChannel === VIDEO ? "NMS" : null),
  interaction: {
    id: reservation.interactionId,
    name: reservation.interactionName,
    mediaServer: reservation.interactionChannel === VIDEO ? "NMS" : null,
  },
})

/**
 * Used during subscription/unsubscription to get the server name for an event to subscribe to
 */
const clientToServerEventNames = {
  [clientEventNames.PROFILE]: serverEventNamesV2.AGENT,
  [clientEventNames.STATUS]: serverEventNamesV2.AGENT_PRESENCE,
  [clientEventNames.CALL_SEGMENTS]: serverEventNamesV2.INTERACTION_CALL_SEGMENTS,
  [clientEventNames.CALL_PARTICIPANTS]: serverEventNamesV2.INTERACTION_PARTICIPANTS,
  [clientEventNames.RESERVATION_UPDATED]: serverEventNamesV2.RESERVATION_UPDATED,
}

module.exports = {
  clientToServer: {
    eventNames: clientToServerEventNames,
  },
  serverToClient: {
    eventNames: invert(clientToServerEventNames),
    task: serverTaskToClientTask,
    presence: serverPresenceToClientStatus,
    callSegment: serverCallSegmentToClientCallSegment,
    callParticipant: serverCallParticipantToClientCallParticipant,
    reservation: serverReservationToClientTask,
  },
}
