import { useCallback, useEffect, useState } from "react"
import { SSE } from "utils/SSE"
import { useAuth } from "contexts/Auth"
import { useCall } from "contexts/Call"
import { useProfile } from "contexts/Profile"
import { usePureCallback } from "hooks/usePureCallback"
import { dataService } from "utils/dataService"
import { logger } from "utils/logger"

export const OUTSOURCE = "OUTSOURCE"
export const OVER_PHONE = "OPI"
export const UNKNOWN = "UNKNOWN"

export const mergeCallSegments = (current, newCs) => {
  return newCs
    ? newCs?.map((cs) => {
        const currentElement = current.find((curr) => curr.id === cs.id) || {}
        return {
          ...currentElement,
          ...cs,
          notes: cs?.notes || currentElement?.notes,
        }
      })
    : current
}

export const useCallSegments = () => {
  const { userData } = useAuth()
  const { answeredCall } = useCall()
  const { additionalUserData } = useProfile()

  const [callSegments, setCallSegments] = useState([])

  const showSegment = useCallback(
    (cs) => {
      if ((cs.type || cs.callSegmentType) === UNKNOWN) {
        return false
      }
      if (cs.participantMode === "LISTEN" && userData.role !== "SUPERVISOR") {
        return false
      }
      const inHouseUser = !additionalUserData?.microCallCenter
      const inHouseSegment = !cs?.interpreter?.external && !cs?.agentMicroCallCenter
      if (inHouseUser === inHouseSegment) {
        return true
      }
      if ([OVER_PHONE, OUTSOURCE].includes(cs.type || cs.callSegmentType)) {
        return true
      }
    },
    [userData?.role, additionalUserData?.microCallCenter]
  )

  const parseAPICallSegments = useCallback(
    (response) => {
      return response?.filter?.(showSegment)?.map((cs) => {
        const {
          id,
          callId,
          name,
          notes,
          languageId,
          callSegmentType,
          agentId,
          channel,
          agentName = "",
          interpreterName = "",
          outsourcePartnerName,
        } = cs
        return {
          id,
          callId,
          name,
          notes,
          languageId,
          type: callSegmentType,
          partnerName: outsourcePartnerName,
          interpreterId: agentId || additionalUserData.id,
          interpreterName: agentName || interpreterName,
          channel,
        }
      })
    },
    [showSegment, additionalUserData]
  )

  const fetchAPICallSegments = usePureCallback(async (connected) => {
    if (!connected || !answeredCall?.interaction) {
      return null
    }
    try {
      const response = await dataService.Interactions.CallSegments.get({ interactionId: answeredCall.interaction.id })
      logger.debug("Call Segments fetched", response?.data)
      const parsed = parseAPICallSegments(response?.data)
      setCallSegments((current) => mergeCallSegments(current, parsed))
    } catch (err) {
      logger.error("Unable to fetch call segments", err)
    }
  })

  const parseSSECallSegments = useCallback(
    (callSegments) => {
      return callSegments?.filter?.(showSegment).map((cs) => {
        const {
          callSegmentId,
          callSegmentName,
          notes,
          language,
          type,
          channel,
          interpreter,
          fullName = "",
          interaction,
          outsourcePartner,
        } = cs
        return {
          id: callSegmentId,
          callId: interaction?.interactionId,
          name: callSegmentName,
          notes,
          languageId: language?.languageId,
          type,
          partnerName: outsourcePartner?.name,
          interpreterId: interpreter?.interpreterId || additionalUserData.id,
          interpreterName: fullName,
          channel: channel,
        }
      })
    },
    [showSegment, additionalUserData]
  )

  const onCallSegmentsReceived = usePureCallback((newSegments) => {
    const parsed = parseSSECallSegments(newSegments)
    setCallSegments((current) => mergeCallSegments(current, parsed))
  })

  useEffect(() => {
    if (!answeredCall?.interaction?.id) {
      return
    }

    SSE.on("call-segments", onCallSegmentsReceived)
    SSE.on("connect", fetchAPICallSegments)
    SSE.subscribe("call-segments", answeredCall?.interaction?.id )
    return () => {
      SSE.off("call-segments", onCallSegmentsReceived)
      SSE.unsubscribe("call-segments")
      SSE.off("connect", fetchAPICallSegments)
      setCallSegments([])
    }
  }, [answeredCall?.interaction?.id, onCallSegmentsReceived, fetchAPICallSegments])

  return {
    callSegments,
  }
}
