import { config } from "utils/configService"
import callScreenSupportStore from "store/callScreenSupportStore"
import { selectAnsweredCall } from "store/selectors/callScreenSupport"

export const onInitialSetup = (event) => {
  const { payload } = event
  const { accessToken, sessionId, agentId, role, answeredCall } = payload

  const [, setAnsweredCall] = selectAnsweredCall(callScreenSupportStore.getState())

  setAnsweredCall(answeredCall)
  config.merge({
    accessToken,
    sessionId,
    agentId,
    role,
  })
}
