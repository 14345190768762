/* istanbul ignore file */
//TODO: actually write tests for this file once it matters
import React from "react"
import CallSheet from "screens/Call/components/CallForm/CallSheet/components/CallSheet"

const useCall = () => {
  return { exitConfirmation: false }
}

const noop = () => null
const useCallFormData = () => {
  return {
    interactionId: "12345",
    language: "LAWREM",
    hospitalName: "Cocacola",
    unitId: "UnitId2",
    department: "2145",
    departmentName: "Dep 2145",
    patientFirstName: "",
    patientLastName: "",
    patientMRN: "",
    patientDOB: "",
    providerFirstName: "",
    providerLastName: "",
    nonBillableReasons: "NOT_BILLABLE",
    departmentOptions: null,
    onPatientDOB: noop,
    onNonBillableReasons: noop,
    onDepartment: noop,
    onPatientFirstName: noop,
    onPatientLastName: noop,
    onPatientMRN: noop,
    onProviderFirstName: noop,
    onProviderLastName: noop,
    showPatientFirstName: noop,
    showPatientLastName: noop,
    showPatientMRN: noop,
    showPatientDOB: noop,
    showProviderFirstName: noop,
    showProviderLastName: noop,
    initialFetch: noop,
    customerPhoneNumber: noop,
  }
}

const ExtraFieldsContainer = () => {
  return <div>extra fields c:</div>
}

const CallSheetContainer = (props) => {
  return <CallSheet {...props} useCall={useCall} useCallFormData={useCallFormData} ExtraFields={ExtraFieldsContainer} />
}

export default CallSheetContainer
