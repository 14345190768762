import { useRef, useCallback, useLayoutEffect } from "react"

export const usePureCallback = (callback) => {
  const ref = useRef()

  useLayoutEffect(() => {
    ref.current = callback
  }, [callback])

  const output = useCallback((...params) => {
    ref.current?.(...params)
  }, [])

  return output
}
