"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useConfigFlag", {
  enumerable: true,
  get: function get() {
    return _useConfigFlag.useConfigFlag;
  }
});
var _useConfigFlag = require("./useConfigFlag");