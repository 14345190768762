import { useContext } from "react"

export const createContextHook = (Context, error = null) => {
  if (!Context) {
    throw new Error("Function called without a context value")
  }
  return () => {
    const context = useContext(Context)
    if (context === undefined) {
      throw new Error(error ? error : "A context wrapper hook has been used outside of it's corresponding Provider")
    }
    return context
  }
}
