const buildIdentityOutputStream = (controller) => {
  return new WritableStream({
    write(chunk) {
      controller.enqueue(chunk)
    },
  })
}

exports = module.exports = {
  buildIdentityOutputStream,
}
