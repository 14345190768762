const serverEventNamesV2 = {
  NEW_EVENT_SOURCE: "NEW_EVENT_SOURCE",
  KEEP_ALIVE_MESSAGE: "keep-alive",
  EXPIRED_SUBSCRIPTION: "EXPIRED_SUBSCRIPTION",
  NEW_SUBSCRIPTION: "NEW_SUBSCRIPTION",
  AGENT: "AGENT",
  AGENT_TASKS: "AGENT_TASKS",
  AGENT_PRESENCE: "AGENT_PRESENCE",
  INTERACTION_CALL_SEGMENTS: "INTERACTION_CALL_SEGMENTS",
  INTERACTION_PARTICIPANTS: "INTERACTION_PARTICIPANTS",
  RESERVATION_UPDATED: "RESERVATION_UPDATED",
}

const clientEventNames = {
  PROFILE: "profile",
  RINGING: "ringing",
  ANSWERED: "answered",
  END_CALL: "endcall",
  MULTI_CALL: "multicall",
  CONNECT: "connect",
  // LOGIN: "login",
  LOGOUT: "logout",
  STATUS: "status",
  // STATUS_STATS: "status-stats",
  // QUEUE_STATS: "queue-stats",
  // CALL_STATS: "call-stats",
  RECONNECTING: "reconnecting",
  CALL_SEGMENTS: "call-segments",
  CALL_PARTICIPANTS: "call-participants",
}

const taskStatuses = {
  RINGING: "ASSIGNED",
  ANSWERED: "ACTIVE",
}

module.exports = {
  clientEventNames,
  serverEventNamesV2,
  taskStatuses,
}
