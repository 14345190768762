const { PUBLIC_MESSAGES } = require("../constants")
const { fetchEventTarget } = require("../SSEPipeline")

class FetchEventStream {
  _abortController = null
  _eventTarget = null
  _onmessage = null
  _onopen = null
  _onerror = null
  _config = null
  _listeners = {}

  get onmessage() {
    return this._onmessage
  }

  set onmessage(fn) {
    this.removeEventListener(PUBLIC_MESSAGES.MESSAGE)
    this._onmessage = this.addEventListener(PUBLIC_MESSAGES.MESSAGE, fn)
  }

  get onopen() {
    return this._onopen
  }

  set onopen(fn) {
    this.removeEventListener(PUBLIC_MESSAGES.OPEN)
    this._onopen = this.addEventListener(PUBLIC_MESSAGES.OPEN, fn)
  }

  get onerror() {
    return this._onerror
  }

  set onerror(fn) {
    this.removeEventListener(PUBLIC_MESSAGES.ERROR)
    this._onerror = this.addEventListener(PUBLIC_MESSAGES.ERROR, fn)
  }

  constructor(services) {
    this._config = services?.config
    this._logger = services?.logger
    this._auth = services?.auth
    this._eventTarget = fetchEventTarget({
      config: this._config,
      logger: this._logger,
      auth: this._auth,
    })
  }

  initialize(url, { inputSignal } = {}) {
    this._abortController = new AbortController()

    // if the incoming signal aborts, dispose resources and resolve:
    inputSignal?.addEventListener("abort", () => {
      this._dispose()
    })

    this._eventTarget.initializeFetchPipe({
      url,
      config: {
        method: "GET",
        signal: this._abortController.signal,
      },
    })

    return this
  }

  abort() {
    try {
      this._abortController?.abort()
    } catch (err) {
      this._logger?.error("[FetchEventStream] An error has occurred while attempting to abort the connection", err)
    }
  }

  close() {
    this._dispose()
  }

  addEventListener(...args) {
    this._listeners[args[0]] = true
    return this._eventTarget.addEventListener(...args)
  }

  removeEventListener(...args) {
    delete this._listeners[args[0]]
    return this._eventTarget.removeEventListener(...args)
  }

  dispatchEvent(...args) {
    return this._eventTarget.dispatchEvent(...args)
  }

  _dispose() {
    this.abort()
    for (const sub of Object.keys(this._listeners)) {
      this.removeEventListener(sub)
    }
    this._eventTarget = null
  }
}

FetchEventStream.PUBLIC_MESSAGES = PUBLIC_MESSAGES

module.exports = FetchEventStream
