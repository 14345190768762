/* istanbul ignore file */

class BaseAdapter {
  supportedMessages = []

  send() {}
  handleIncomingMessage() {}
  handleGenericMessage() {}

  canHandleMessage(type) {
    return this.supportedMessages.includes(type)
  }
}

exports = module.exports = BaseAdapter
