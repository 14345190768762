export const AVAILABLE = "Available"
export const CONNECTING = "Connecting..."
export const RECONNECTING = "Reconnecting..."
export const RELOAD_REQUIRED = "Reload Required"
export const ANA = "Agent Not Answering"
export const ACD_ASSIGNED = "ACD Assigned"
export const BUSY = "Busy"
export const OFF_QUEUE = "Off Queue"
export const ON_BREAK = "On Break"
export const OFFLINE = "Offline"
export const IN_QA_MEETING = "In a QA Meeting"
export const FOLLOW_UP = "Follow-up"
export const FOLLOW_UP_EXTENDED = "Follow-up Extended"
