import React from "react"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"

export const TestDataPoint = ({ name, children }) => {
  const showTestDataPoints = useConfigFlag("tests.dataPoints")

  if (!showTestDataPoints) {
    return null
  }

  return (
    <div 
      data-testid={`test-data-point-${name}`}
      className="absolute w-0 h-0 overflow-hidden"
      aria-hidden="true"
    >
      {children}
    </div>
  )
}
