import { useCallback, useEffect, useState } from "react"
import { useCallSheetData } from "./useCallSheetData"
import { NON_BILLABLE_OPTIONS } from "../constants"
import { useCallFormState } from "./useCallFormState"

export const useCallFormFields = (answeredCall, setServerState) => {
  const { callSheetData, getCallSheetData } = useCallSheetData(answeredCall?.interaction?.id)

  const { reset, onCallSheetLoad, ...rest } = useCallFormState()

  const { setInteractionId, setInteractionName, setUnitId, setCustomerPhoneNumber, setLanguage, setHospitalName } = rest

  const [initialFetch, setInitialFetch] = useState(false)

  const refreshCallSheet = useCallback(() => {
    return getCallSheetData()
  }, [getCallSheetData])

  useEffect(() => {
    if (!(answeredCall?.interaction || answeredCall?.language)) {
      return
    }
    /* NOTE:
      This assumes that neither the interaction nor language objects ever change once set.
      Since some of this data can come from the interaction object instead, they will be nullified if answeredCall updates.
    */
    const { id, name, unitId, customerPhoneNumber, hospitalName } = answeredCall.interaction
    setInteractionId(id)
    setInteractionName(name)
    setUnitId(unitId || "")
    setCustomerPhoneNumber(customerPhoneNumber)
    setLanguage(answeredCall.language?.name)
    setHospitalName(hospitalName)
    return () => {
      reset()
    }
  }, [
    answeredCall?.interaction,
    answeredCall?.language,
    reset,
    setCustomerPhoneNumber,
    setHospitalName,
    setInteractionId,
    setInteractionName,
    setLanguage,
    setUnitId,
  ])

  useEffect(() => {
    if (callSheetData) {
      const {
        departmentId,
        providerFirstName,
        providerLastName,
        patientFirstName,
        patientLastName,
        patientMRN,
        patientDOB,
        nonBillableReason,
        phoneNumberAC,
        bchOaklandHospitalConfirmation,
        location,
        memberIdNumber,
        nyuAccessCode,
        sentaraAccessCode,
        visitId,
        callerFirstName,
        callerLastName,
      } = callSheetData

      onCallSheetLoad(callSheetData)
      setInitialFetch(() => true)
      setServerState({
        nonBillableReason: nonBillableReason === NON_BILLABLE_OPTIONS.NONE.key ? null : nonBillableReason,
        departmentId: departmentId || "",
        patientFirstName: patientFirstName || "",
        patientLastName: patientLastName || "",
        patientMRN: patientMRN,
        patientDOB: patientDOB || null,
        providerFirstName: providerFirstName || "",
        providerLastName: providerLastName || "",
        phoneNumberAC: phoneNumberAC || "",
        bchOaklandHospitalConfirmation: bchOaklandHospitalConfirmation || false,
        location: location || "",
        memberIdNumber: memberIdNumber || "",
        nyuAccessCode: nyuAccessCode || "",
        sentaraAccessCode: sentaraAccessCode || "",
        visitId: visitId || "",
        callerFirstName: callerFirstName || "",
        callerLastName: callerLastName || "",
      })
    }
  }, [callSheetData, onCallSheetLoad, setServerState])

  return {
    ...rest,
    initialFetch,
    refreshCallSheet,
  }
}
