const { PUBLIC_MESSAGES } = require("../constants")
const { buildOutputStream } = require("../Dispatcher")
const { buildSSEMessageDecoder, PEEK } = require("../MessageDecoder")
const { buildIdentityOutputStream } = require("../SupportingStreams")

const BASE_CONNECTION_DELAY = 2500
const BASE_RETRY_ATTEMPTS = 3

const createPipeline = (eventTarget, { url, config }, services) => {
  return new ReadableStream({
    start(controller) {
      fetch(url, config)
        .then((response) => {
          eventTarget.dispatchEvent(new Event(PUBLIC_MESSAGES.OPEN))

          response.body
            // eslint-disable-next-line no-undef
            .pipeThrough(new TextDecoderStream())
            .pipeThrough(buildSSEMessageDecoder(PEEK, services))
            .pipeTo(buildIdentityOutputStream(controller))
            .catch((error) => {
              controller.error(error)
            })
        })
        .catch((err) => {
          controller.error(err)
        })
    },
  })
}

class SSEPipeline {
  _retries = 0
  _timeout = null
  _services = null
  _eventTarget = null
  _shouldRetry = true

  constructor(services) {
    this._services = services
  }

  prepareEventStream() {
    this._eventTarget = new EventTarget()
    this._cleanup()
    return this
  }

  _cleanup() {
    if (this._timeout) {
      clearTimeout(this._timeout)
      this._timeout = null
    }
    this._retries = 0
  }

  addEventListener(...args) {
    return this._eventTarget?.addEventListener(...args)
  }

  removeEventListener(...args) {
    return this._eventTarget?.removeEventListener(...args)
  }

  dispatchEvent(...args) {
    return this._eventTarget?.dispatchEvent(...args)
  }

  // TODO: there's likely a better solution instead of building headers right here
  initializeFetchPipe({ url, config }) {
    this._cleanup()
    const start = () => {
      const requestConfig = {
        ...config,
        headers: new Headers({
          accept: "text/event-stream",
          Authorization: `bearer ${this._services?.auth?.()?.token}`,
        }),
      }
      return createPipeline(this._eventTarget, { url, config: requestConfig }, this._services)
        .pipeTo(buildOutputStream(this._eventTarget))
        .catch((error) => {
          // TODO: maybe listen to the open event and try to reset the retry count?
          const retryLimit = this._services?.config?.()?.get("sse.v2.eventStream.retryLimit") ?? BASE_RETRY_ATTEMPTS
          const shouldRetry = this._services?.config?.()?.get("sse.v2.eventStream.retriesEnabled") ?? true
          const retryDelay =
            this._services?.config?.()?.get("sse.v2.eventStream.retryDelayMilliseconds") ?? BASE_CONNECTION_DELAY

          this._retries += 1
          const exceededRetries = this._retries > retryLimit
          if (!exceededRetries && shouldRetry) {
            this._eventTarget.dispatchEvent(
              new CustomEvent(PUBLIC_MESSAGES.ERROR, {
                detail: {
                  error,
                  type: "ERROR",
                },
              })
            )
          } else {
            this._eventTarget.dispatchEvent(
              new CustomEvent(PUBLIC_MESSAGES.CHANNEL_DISCONNECTED, {
                detail: {
                  type: "ERROR",
                },
              })
            )
          }
          if (!config.signal.aborted && shouldRetry && !exceededRetries) {
            this._timeout = setTimeout(start, retryDelay)
          }
        })
    }
    return start()
  }
}

exports = module.exports = SSEPipeline
