const CallSegmentsUpdateModel = require("./Models/CallSegmentsUpdateModel")
const CallParticipantDTO = require("./Models/CallParticipantsDTO")
const TelemetryDTO = require("./Models/TelemetryDTO")
const CallSheetDTO = require("./Models/CallSheetDTO")

const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

class Interactions extends DataServiceEndPoint {
  static key = "Interactions"

  AccessCode = {
    get: async (accessCodeOrId) => {
      if (!accessCodeOrId) {
        throw new Error("Invalid value for access code provided, must be a non-empty string", accessCodeOrId)
      }
      const url = `${this.url}/v1/access-codes/${accessCodeOrId}`
      const headers = { Authorization: `bearer ${this.token}` }
      return await this._httpService.get(url, { headers })
    },

    validate: async ({ interactionId, accessCode }) => {
      const url = `${this.url}/v1/interactions/${interactionId}/access-code/${accessCode}`
      const headers = { Authorization: `bearer ${this.token}` }
      return await this._httpService.put(url, null, { headers })
    },
  }

  CallSheet = {
    get: ({ interactionId }) => {
      return this._httpService.get(`${this.url}/v1/interactions/${interactionId}`, {
        headers: { Authorization: `bearer ${this.token}` },
      })
    },

    set: (data) => {
      const { interactionId, payload: _payload } = data
      const payload = new CallSheetDTO(_payload).present()
      return this._httpService.put(`${this.url}/v1/interactions/${interactionId}`, payload, {
        headers: { Authorization: `bearer ${this.token}` },
      })
    },
  }

  Participants = {
    get: async ({ interactionId }) => {
      const { data } = await this._httpService.get(`${this.url}/v1/interactions/${interactionId}/participants`, {
        headers: { Authorization: `bearer ${this.token}` },
      })
      return data.map((participant) => new CallParticipantDTO().buildObjectWithData(participant).present())
    },
  }

  CallSegments = {
    get: ({ interactionId }) => {
      return this._httpService.get(`${this.url}/v1/interactions/${interactionId}/call-segments?orderBy=createdDate`, {
        headers: { Authorization: `bearer ${this.token}` },
      })
    },

    _setURLs: {
      INHOUSE: "inhouse-call-segments",
      OPI: "opi-call-segments",
      OUTSOURCE: "outsource-call-segments",
    },

    set: (data) => {
      const { type, callSegmentId } = data
      if (!this.CallSegments._setURLs[type]) {
        throw new Error(
          `DataService.Interactions.CallSegments.set requires type of either "OPI" or "INHOUSE" but got "${type}"`
        )
      }
      const url = `${this.url}/v1/${this.CallSegments._setURLs[type]}/${callSegmentId}`
      const payload = new CallSegmentsUpdateModel(data).present()
      const headers = { Authorization: `bearer ${this.token}` }
      return this._httpService.put(url, payload, { headers })
    },
  }

  addThirdParty = ({ interactionId, number }) => {
    return this._httpService.post(
      `${this.url}/v1/interactions/${interactionId}/dial-out`,
      {
        number,
      },
      {
        headers: { Authorization: `bearer ${this.token}` },
        timeout: 60_000,
      }
    )
  }

  report = ({ interactionId, text }) =>
    this._httpService.post(
      `${this.url}/v1/interactions/${interactionId}/incidents`,
      {
        text,
      },
      {
        headers: { Authorization: `bearer ${this.token}` },
      }
    )

  updateTelemetry = ({ interactionId, userId, statsEvent }) => {
    const payload = new TelemetryDTO({ interactionId, userId, statsEvent }).present()

    return this._httpService.put(`${this.url}/v1/interactions/${interactionId}/telemetry`, payload, {
      headers: { Authorization: `bearer ${this.token}` },
      timeout: this._config.get("player.stats.timeoutMilliseconds", 5_000),
    })
  }

  joinCall = ({ mode, agentId, taskId, languageId, interactionId }) => {
    return this._httpService.put(
      `${this.url}/v1/interactions/${interactionId}/participants`,
      {
        mode,
        agentId,
        taskId,
        languageId,
      },
      {
        headers: { Authorization: `bearer ${this.token}` },
      }
    )
  }

  barge = (params) => this.joinCall({ ...params, mode: "BARGE" })

  listenIn = (params) => this.joinCall({ ...params, mode: "LISTEN" })

  reconnect = ({ mode, ...params }) => this.joinCall({ ...params, mode: mode ? mode : "JOIN" })
}

module.exports = Interactions
