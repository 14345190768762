import React from "react"
import cs from "classnames"
import Container from "components/Container/Container"
import DoctorsGraphic from "assets/login_graphic_general.svg"
import getAppInfo from "utils/appInfo"
import "./RightPane.scss"

const RightPane = () => {
  return (
    <Container
      name={"sign-in-right"}
      className={["hidden", "lg:flex", "flex-1", "flex-col", "right-pane__container", "min-h-screen"]}
    >
      <div className="flex-grow bg-center bg-no-repeat" style={{ backgroundImage: `url(${DoctorsGraphic})` }}></div>
      <div className={cs("py-6", "pr-12", "flex", "justify-end", "text-tiny", "text-white")}>
        Connect. Communicate. Care | V {getAppInfo.id}
      </div>
    </Container>
  )
}

export default RightPane
