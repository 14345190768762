import React from "react"
import cs from "classnames"
import Button from "../Button"

const Error = ({ title, message, options = [] }) => {
  return (
    <div
      className={cs("bg-white opacity-100 max-w-xl max-h-96 flex flex-col rounded p-4")}
      data-testid="error-container"
    >
      <span
        className={cs("w-full h-4 text-bblue-500 flex flex-col py-4 text-lg font-semibold items-center justify-center")}
      >
        {title}
      </span>
      <span className={cs("w-full flex-1 text-bblue-500 flex flex-col items-center justify-center text-justify")}>
        {message}
      </span>
      {options?.length > 0 && (
        <div className={cs("w-full h-8 flex my-4 flex-row justify-evenly items-center")}>
          {options.map((opt, index) => {
            return (
              <Button key={index} onClick={opt.onClick}>
                {opt.text}
              </Button>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Error
