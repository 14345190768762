class Message {
  type = null
  id = null
  service = null // this is the service that the message will be posted to
  payload = null

  constructor(type, payload, id, service) {
    this.type = type
    this.payload = payload
    this.id = id
    this.service = service
  }

  toSerializable() {
    return {
      meta: {
        id: this.id,
        type: this.type,
        service: this.service,
      },
      payload: this.payload,
    }
  }
}

exports = module.exports = Message
