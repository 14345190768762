import { useEffect, useState } from "react"
import { oktaAuth } from "../okta/client"
import { logger } from "utils/logger"
import { useAlertMessage } from "hooks/useAlertMessage"
import { config } from "utils/configService"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { authService } from "utils/authService"

const logOktaEvent =
  (event) =>
  (...params) =>
    logger.debug(`Okta "${event}" event fired`, ...params)
const logOktaExpired = logOktaEvent("expired")
const logOktaError = (...params) => logger.error(`Okta "error" event fired`, ...params)
const logOktaRenewed = logOktaEvent("renewed")
const logOktaRemoved = logOktaEvent("removed")

export const useOkta = ({ isAuthenticated }) => {
  const [initialAuthChecked, setInitialAuthChecked] = useState(false)
  const { showErrorToast } = useAlertMessage()
  const logEvents = useConfigFlag("oktaEvents.logAll")

  useEffect(() => {
    if (initialAuthChecked) {
      return
    }
    const checkToken = () => {
      const authState = oktaAuth.authStateManager.getAuthState()
      if (authState?.isAuthenticated && authState?.accessToken) {
        authService.onAuthSuccess(authState)
      }
      setInitialAuthChecked(true)
    }
    checkToken()
  }, [initialAuthChecked])

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    let timeout = null

    let errors = 0

    const handleOktaTokenAdded = (key, token) => {
      if (key.startsWith("access")) {
        if (timeout) {
          clearTimeout(timeout)
          timeout = null
        }
        const allowedFailures = config.get("oktaTokenRefresh.allowedFailures", 2)
        try {
          const oktaError = authService.validateOktaToken(token)
          if (oktaError) {
            throw oktaError
          }

          authService.onDataUpdate(token)
          logger.info(`Successfully refreshed token`, token)
          errors = 0
        } catch (err) {
          errors = errors + 1
          showErrorToast(
            `Your session has expired and the application is in a broken state.  ${
              errors > allowedFailures
                ? "Log out and log back in to continue"
                : `Attempting to recover (attempt ${errors} of ${allowedFailures + 1})`
            }.`,
            {
              autoClose: errors < allowedFailures,
            }
          )
          logger.error(`An error has occurred while refreshing the token ${errors}/${allowedFailures}`, err, token)
          if (config.get("oktaTokenRefresh.retryOnFail") && errors && errors <= allowedFailures) {
            timeout = setTimeout(() => {
              oktaAuth.tokenManager.renew("accessToken")
            }, errors * config.get("oktaTokenRefresh.delay", 5) * 1000)
          }
        }
      }
    }

    oktaAuth.tokenManager.on("added", handleOktaTokenAdded)
    oktaAuth.tokenManager.on("expired", async (key, token) => {
      logOktaExpired(key, token)
      try {
        logger.debug("Attempting to renew token")
        await oktaAuth.getOrRenewAccessToken()
      } catch (err) {
        logger.error("An error has occurred while renewing the token", err)
      }
    })
    if (logEvents) {
      oktaAuth.tokenManager.on("error", logOktaError)
      oktaAuth.tokenManager.on("renewed", logOktaRenewed)
      oktaAuth.tokenManager.on("removed", logOktaRemoved)
    }

    return () => {
      clearTimeout(timeout)
      oktaAuth.tokenManager.off("added", handleOktaTokenAdded)
      oktaAuth.tokenManager.off("error", logOktaError)
      oktaAuth.tokenManager.off("removed", logOktaRemoved)
      oktaAuth.tokenManager.off("renewed", logOktaRenewed)
      oktaAuth.tokenManager.off("expired", logOktaExpired)
    }
  }, [isAuthenticated, showErrorToast, logEvents])

  return {
    initialAuthChecked,
  }
}
