const { useMemo } = require("react")
const { useQueues } = require(".")

export const useRoleQueues = () => {
  const { queues } = useQueues()

  const operatorQueueIds = useMemo(
    () =>
      new Set([
        ...queues.all
          .filter((queue) => /operator/i.test(queue.name))
          .sort((queue) => (queue.name?.toLowerCase() === "operator" ? -1 : 1))
          .map((queue) => queue.id),
        undefined,
      ]),
    [queues?.all]
  )

  const supervisorQueueId = useMemo(() => queues?.supervisor?.id, [queues?.supervisor?.id])
  const mainOperatorQueueId = useMemo(() => [...operatorQueueIds][0], [operatorQueueIds])

  return {
    operatorQueueIds,
    supervisorQueueId,
    mainOperatorQueueId,
  }
}
