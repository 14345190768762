import { dataService } from "utils/dataService"
import { sortAlphabeticalByName } from "utils/helpers"

const outsourceSlice = {
  outsourceVendors: [],
  outsourceLanguages: [],
}

export const createOutsourceSlice = (set, get) => ({
  ...outsourceSlice,
  getVendorsForLanguage: async ({ languageId }) => {
    const response = await dataService.Outsource.getPartnersByLanguage({ languageId })
    const outsourceVendors = response?.data
    set({ outsourceVendors })
    return outsourceVendors
  },
  getOutsourcedLanguages: async () => {
    const response = await dataService.Outsource.getOutsourcedLanguages()
    const outsourceLanguages = response?.data.sort(sortAlphabeticalByName)
    set({ outsourceLanguages })
    return outsourceLanguages
  },
  transferOut: async ({ interactionId, providerId, languageId }) => {
    const provider = get().outsourceVendors?.find((vendor) => vendor.id === providerId)
    const language = get().outsourceLanguages?.find((language) => language.id === languageId)
    return dataService.Outsource.outsourceCall({
      interactionId,
      payload: {
        outsourcePartnerId: provider.id,
        channel: provider?.channel,
        outsourceNumber: provider?.phoneNumbers?.[0],
        outsourceLanguageId: language.id,
        outsourceLanguageName: language.name,
      },
    })
  },
})
