import React, { Fragment } from "react"
import cs from "classnames"
import LeftPane from "./components/LeftPane"
import RightPane from "./components/RightPane"
import getAppInfo from "utils/appInfo"

const SignInScreen = () => {
  return (
    <Fragment>
      <main className={cs("min-h-screen", "max-w-screen", "flex-row", "flex")} role="main" id="main">
        <LeftPane data-testid={"sign-in-right-pane"} />
        <RightPane data-testid={"sign-in-right-pane"} />
      </main>
      <footer role="contentinfo" className="hidden">
        Martti - Connect. Communicate. Care. | V {getAppInfo.id}
      </footer>
    </Fragment>
  )
}

export default SignInScreen
