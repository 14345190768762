import { useCallback } from "react"

export const useEventWrapper = (onChange, processingFn) => {
  const callback = useCallback(
    (evt) => {
      const value = processingFn ? processingFn(evt) : evt.target.value
      onChange(value)
    },
    [onChange, processingFn]
  )

  return callback
}
