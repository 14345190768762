const { VALIDATION_FAILURES } = require("hooks/useValidation")

const getInputLengthValidationHandlerForConstraint = (length) => (evt) => {
  const isPrintableCharacter = evt?.key?.length === 1
  if (
    (isPrintableCharacter && length <= evt.target.value.length) ||
    (evt.type === "paste" && length < evt.clipboardData?.getData?.("Text")?.length + evt.target.value.length)
  ) {
    return VALIDATION_FAILURES.LENGTH
  }
  return null
}

export const generateValidationFunction = (constraint) => {
  if (!constraint?.length) {
    return null
  }

  const lengthValidationHandler = getInputLengthValidationHandlerForConstraint(constraint.length)
  return {
    onKeyDown: lengthValidationHandler,
    onPaste: lengthValidationHandler,
  }
}

export const generateValidationFunctions = (constraints) => {
  return Object.keys(constraints || {}).reduce((prev, key) => {
    const value = constraints[key]
    const validationFunctions = generateValidationFunction(value)
    if (!validationFunctions) {
      return prev
    }
    return {
      ...prev,
      [key]: validationFunctions,
    }
  }, {})
}
