import { useEffect, useState } from "react"
import { useNavigate as useBaseNavigate, useLocation } from "react-router-dom"

export const useNavigate = () => {
  const [navigate, setNavigate] = useState(null)
  const _navigate = useBaseNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    setNavigate(null)
    if (navigate && navigate !== pathname) {
      _navigate(navigate)
    }
  }, [navigate, _navigate, pathname])

  return setNavigate
}
