import React from "react"
import cs from "classnames"
import Icon from "@cloudbreakus/cloudbreak-react-icon"
import { THEMES, stylesheet, icons } from "./styles.js"

const Tag = (props) => props.href ? <a {...props}>{props.children} </a> : <button {...props}>{props.children}</button>

const Button = ({
  name,
  className,
  onClick,
  href,
  theme,
  width,
  halfHeight,
  icon,
  children,
  type = "submit",
  disabled,
  loading,
  ...rest
}) => {
  const styleSheet = stylesheet({ theme: THEMES[theme] ? theme : THEMES.bblue, disabled, halfHeight, loading })
  return (
    <Tag
      className={cs(
        styleSheet,
        "group flex flex-row whitespace-nowrap items-center justify-center px-4",
        width,
        className
      )}
      data-testid={`button-${name}`}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      href={href}
      {...rest}
    >
      {(icon || loading) && (
        <span className="pr-2">
          <Icon
            icon={loading ? "spinner" : icon}
            className={cs({ "animate-spin": loading }, icons[theme] || "stroke-white fill-white" )}
          />
        </span>
      )}
      {children}
    </Tag>
  )
}

export default Button
