import React from "react"
import cs from "classnames"
import Icon from "@cloudbreakus/cloudbreak-react-icon"

const Wrapper = ({
  name,
  label,
  className,
  children,
  styleSheet,
  error,
  hideLabel,
  onRefresh,
  refreshing,
  refreshLabel = "refresh",
}) => {
  return (
    <div data-testid={`${name}`} className={cs(className, styleSheet.root)}>
      <div className="block relative">
        <span className={cs("flex", styleSheet.label)}>
          <label aria-label={label} className={cs({ hidden: hideLabel })}>
            <span>{label}</span>
          </label>
          {onRefresh && (
            <Icon
              data-testid="refresh-icon"
              aria-label={refreshLabel}
              icon="refresh"
              onClick={onRefresh}
              size={24}
              className={cs("hover:bg-gray-100 ml-1 fill-[#146f9d]", { "animate-spin": refreshing })}
            />
          )}
        </span>
        <div className={cs("flex", "flex-col")}>{children}</div>
        <span className="absolute text-xs text-red-500 w-full left-0 text-left">{error}</span>
      </div>
    </div>
  )
}

export default Wrapper
