import { useEffect } from "react"
import { config } from "./utils/configService"
import { logger } from "./utils/logger"
import { inAppMessaging } from "./utils/messaging"

export const runServices = () => {
  try {
    config.addLogger(logger)
    config.connect()
    window.onmessage = inAppMessaging.handleIncomingMessage
    return true
  } catch (err) {
    logger?.error("An error has ocurred while running service dependents", err)
    throw err
  }
}

export const closeServices = () => {
  config.close()
}

export const useServices = () => {
  useEffect(() => {
    runServices()
    return () => closeServices()
  }, [])

  return {
    config,
    logger,
  }
}
