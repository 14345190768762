import React from "react"
import cs from "classnames"
import Wrapper from "../Wrapper.jsx"
import * as StyleSheet from "../styles.js"

const Toggle = ({
  name,
  value,
  onChange = () => {},
  label,
  theme,
  disabled,
  className,
  hideLabel,
  onText = "On",
  offText = "Off",
  ...rest
}) => {
  const styleSheet = StyleSheet[theme]?.(disabled) || StyleSheet["profile"](disabled)

  return (
    <Wrapper label={label} className={className} name={`toggle-${name}`} styleSheet={styleSheet} hideLabel={hideLabel}>
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          id="toggle-switch"
          value={value}
          checked={value}
          onChange={() => onChange(!value)}
          className="sr-only"
          {...rest}
        />
        <label
          htmlFor="toggle-switch"
          className={cs(
            "relative w-14 h-8 rounded-full cursor-pointer transition-colors duration-300",
            value ? "bg-primary" : "bg-gray-300"
          )}
        >
          <div
            className={cs("absolute top-1.5 left-1.5 w-5 h-5 bg-white rounded-full transition-transform duration-300", {
              "transform translate-x-6": value,
            })}
          />
        </label>
        <span className="text-lg font-medium">{value ? onText: offText}</span>
      </div>
    </Wrapper>
  )
}

export default Toggle
