"use strict";

var Logger = require("./Logger");
var Adapters = require("./adapters");
var Interfaces = require("./interfaces/index.js");
var _require = require("./utils"),
  serializeError = _require.serializeError;
var _require2 = require("./constants/loggingLevels"),
  DEBUG = _require2.DEBUG,
  INFO = _require2.INFO,
  ERROR = _require2.ERROR,
  WARNING = _require2.WARNING,
  LOG = _require2.LOG,
  ADD_ATTRIBUTE = _require2.ADD_ATTRIBUTE,
  REMOVE_ATTRIBUTE = _require2.REMOVE_ATTRIBUTE,
  JSERROR = _require2.JSERROR,
  getLogLevelValue = _require2.getLogLevelValue;
module.exports = {
  Logger: Logger,
  LoggerAdapters: Adapters,
  getLogLevelValue: getLogLevelValue,
  Interfaces: Interfaces,
  LoggerMessages: {
    DEBUG: DEBUG,
    INFO: INFO,
    WARNING: WARNING,
    ERROR: ERROR,
    LOG: LOG,
    ADD_ATTRIBUTE: ADD_ATTRIBUTE,
    REMOVE_ATTRIBUTE: REMOVE_ATTRIBUTE,
    JSERROR: JSERROR
  },
  utils: {
    serializeError: serializeError
  }
};