import React from "react"
import { useLocation } from "react-router-dom"
import cs from "classnames"
import { Button, THEMES } from "@cloudbreakus/ui-components"

const VersionNotification = ({ snooze }) => {
  const onCallScreen = useLocation().pathname?.split("/")[1] === "current-call"
  const reload = () => {
    window.location.reload()
  }
  return (
    <div className={cs("flex", "flex-row")}>
      <div className={cs("w-3/4", "text-sm")}>
        <span>
          {onCallScreen
            ? "There is an update to MVCC. Please finish your call, change your status to Off Queue, and press this restart button to get the new version."
            : "Restart MVCC to use the latest version."}
        </span>
      </div>
      <div className={cs("w-1/4", "flex", "flex-col", "gap-y-4", "items-center", "justify-center")}>
        <Button
          name={"version-refresh"}
          className={cs("w-16", "text-sm")}
          halfHeight
          onClick={reload}
          theme={THEMES.button.error}
          disabled={onCallScreen}
          type="button"
        >
          Restart
        </Button>
        <Button name={"version-snooze"} className={cs("w-16", "text-sm")} halfHeight onClick={snooze} type="button">
          Snooze
        </Button>
      </div>
    </div>
  )
}

export default VersionNotification
