import React, { createContext, memo, useEffect, useMemo, useRef, useState } from "react"
import cs from "classnames"
import { useLocation } from "react-router-dom"
import { createContextHook } from "utils/contextHelpers"

const initialState = {
  message: "",
}

const AccessibilityContext = createContext()

const msgDelay = process.env.ACCESSIBILITY_TIMEOUT || 5000

const pagesWithoutSkipNavigation = [/\/signin/i, /error-404/i]

const hiddenLinkStyles = [
  "sr-only",
  "focus:not-sr-only",
  "focus:bg-red-700",
  "text-white",
  "focus:fixed",
  "top-0",
  "left-0",
]

const _AccessibilityProvider = ({ children, timeout = msgDelay }) => {
  const ref = useRef(null)
  const { pathname } = useLocation()
  const [message, setMessage] = useState(initialState.message)

  const showMessage = async (message) => {
    setMessage(() => message)
  }

  useEffect(() => {
    let timer1 = message ? setTimeout(() => setMessage(""), timeout) : null
    return () => {
      if (timer1) {
        clearTimeout(timer1)
      }
    }
  }, [message, timeout])

  useEffect(() => {
    ref.current?.focus()
  }, [pathname])

  useEffect(() => {
    const location =
      pathname === "/"
        ? "home"
        : pathname
            ?.split("/")
            .filter((el) => el)
            .join(", ")
    showMessage(`Your location in the site is: ${location}`)
  }, [pathname])

  const hideSkipToNavigation = useMemo(() => {
    return pagesWithoutSkipNavigation.reduce((result, page) => {
      return result ? result : pathname.match(page)
    }, false)
  }, [pathname])

  return (
    <AccessibilityContext.Provider
      value={{
        showMessage,
      }}
    >
      <span ref={ref} tabIndex={-1} />
      {message && (
        <span role="alert" aria-atomic="true" aria-live="polite" className="sr-only" data-testid="hidden-sr-alert">
          {message}
        </span>
      )}
      {!hideSkipToNavigation && (
        <a data-testid={"link-accesible-navigation"} href="#navigation" className={cs(hiddenLinkStyles)}>
          Go to navigation
        </a>
      )}

      <a data-testid={"link-accesible-content"} href="#main" className={cs(hiddenLinkStyles)}>
        Go to content
      </a>
      {children}
    </AccessibilityContext.Provider>
  )
}

export const AccessibilityProvider = memo(_AccessibilityProvider)

export const useAccesibility = createContextHook(
  AccessibilityContext,
  "useAccesibility must be used within a AccessibilityProvider"
)

export default AccessibilityContext
