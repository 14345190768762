const ElectronAdapter = require("./adapters/ElectronAdapter")
const BrowserAdapter = require("./adapters/BrowserAdapter")
const EventEmitter2 = require("eventemitter2")
const { WINDOW_MESSAGE_TYPES, MESSAGES, CLIENT_MESSAGE_TYPES, SCREENS } = require("./constants")
const Message = require("./Message")

class InAppMessaging extends EventEmitter2 {
  messageCount = null
  adapter = null
  screens = SCREENS
  messages = MESSAGES
  isElectron = false

  constructor(services) {
    super()
    this.messageCount = 0
    this.services = services
    this.pendingResponses = new Map()
    this.isElectron = window?.electronWindow?.isElectron
    this.adapter = this.isElectron ? new ElectronAdapter(this.services) : new BrowserAdapter(this.services)
    this.handleIncomingMessage = this.receiveMessage.bind(this)
  }

  handleNewWindowOpened(windowObject, windowKey) {
    if (this.isElectron) {
      return
    }
    this.adapter.attachWindow(windowObject, windowKey)
  }

  requestCloseWindow(windowKey) {
    if (this.isElectron) {
      return
    }
    this.adapter.closeWindow(windowKey)
  }

  sendMessage(type, message, service) {
    const id = ++this.messageCount

    const payload = new Message(type, message, id, service)

    this._send(service, payload)

    // Return a promise that will be resolved when the response is received.
    return this.services?.config?.get("inAppMessaging.enablePromises") === true
      ? new Promise((resolve, reject) => {
          this.pendingResponses.set(this.messageCount, { resolve, reject })
        })
      : null
  }

  async receiveMessage(event) {
    const { data } = event

    const id = data?.meta?.id

    // Promise API if promise based api is enabled
    if (!this.services?.config?.get("inAppMessaging.enablePromises") || !this.pendingResponses.has(id)) {
      const response = this._handleIncomingMessage(event)

      if (response?.shouldSendResponse) {
        this._send(response.message.service, response.message)
      }
    } else {
      const promise = this.pendingResponses.get(id)
      promise?.resolve(data)
      this.pendingResponses.delete(id)
    }
  }

  _open(event) {
    this.emit(CLIENT_MESSAGE_TYPES.SIDE_WINDOW_OPENED, event.data)
  }

  _close(event) {
    this.emit(CLIENT_MESSAGE_TYPES.SIDE_WINDOW_CLOSED, event.data)
  }

  _send(service, payload) {
    this.adapter.send(service, payload.toSerializable())
  }

  _emitUnhandledMessage(type, data) {
    if (this.services?.config?.get("inAppMessaging.logEmittedMessages")) {
      this.services?.logger?.debug(
        `Emitting message to the app not handled by internal IAP services or adapters: ${type}`,
        data
      )
    }
    this.emit(type, data)
  }

  _handleIncomingMessage(event) {
    const { data } = event
    const type = data?.meta?.type
    if (!type) {
      return
    }

    switch (type) {
      case WINDOW_MESSAGE_TYPES.OPEN:
        return this._open(event)
      case WINDOW_MESSAGE_TYPES.CLOSE:
        return this._close(event)
      default:
        return this.adapter.canHandleMessage(type)
          ? this.adapter.handleIncomingMessage(event)
          : this._emitUnhandledMessage(type, data)
    }
  }
}

module.exports = InAppMessaging
