import React from "react"
import Icon from "@cloudbreakus/cloudbreak-react-icon"
import Tooltip from "../Tooltip"

const HelpButton = ({ tooltip, col, tooltipStyle = { maxWidth: "200px" } }) => {
  return (
    <div className="absolute inline-flex ml-1 -mt-1" data-testid="help-icon">
      <Icon data-tooltip-id={`tooltip-${col}`} aria-label="Show tooltip for details" icon="help" size={24} />
      <Tooltip style={tooltipStyle} id={`tooltip-${col}`} place="top" variant="info" content={tooltip} />
    </div>
  )
}

export default HelpButton
