const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

const skillProperties = [
  "id",
  "sfid",
  "name",
  "timeoutskillsfid: timeoutSkillSfid",
  "microcallcenter: microCallCenter",
  "channel",
  "service",
  "partnersfid: partnerSfid",
  "partner: partnerId",
]

const myQueuesSubQuery = (skillType) => `skills(where: {
  skillType: {_neq: USER},
  skillUser: {_is_null: true},
  sfid: {_is_null: false},
  callCenterPlatform: {_eq: MARTTI_NEXT},
  skillAssignmentIdsBySkill: {
    skillType: {_in: [${skillType}]},
    agentSfid: {_eq: $agentId},
  },
}) {
  ${skillProperties.join("\n  ")}
}`

const myQueuesQuery = `
query MySkills($agentId: String!){
  activeQueues: ${myQueuesSubQuery("Active")}
  availableQueues: ${myQueuesSubQuery("Available")}
}`

const myQueuesSubscriptions = ["Active", "Available"].map((skillType) => `
subscription ${skillType}Skills($agentId: String!){
  ${skillType.toLocaleLowerCase()}Queues: ${myQueuesSubQuery(skillType)}
}`)

const allQueuesGraphRequest = `Skills {
  skills(where: {
    skillType: {_neq: USER},
    skillUser: {_is_null: true},
    sfid: {_is_null: false},
    callCenterPlatform: {_eq: MARTTI_NEXT},
    _or: [
      <PARTNER_CONDITION>,
      {partnerSfid: {_is_null: true}},
    ],
  }){
    ${skillProperties.join("\n      ")}
  }
}
`
const getAllQuery = ({ partnerId, sub = false }) => {
  const prefix = sub ? "subscription" : "query"
  const graphRequest = allQueuesGraphRequest.replace(
    "<PARTNER_CONDITION>",
    partnerId ? `{partnerSfid: {_eq: "${partnerId}"}}` : ""
  )
  return `${prefix} ${graphRequest}`
}

const remapSkill = (queue) => ({
  ...queue,
  postgresId: queue?.id,
  id: queue?.sfid,
  timeoutQueueId: queue?.timeoutskillsfid,
  microCallCenter: queue?.microcallcenter,
})

class Queues extends DataServiceEndPoint {
  static key = "Queues"

  _processAll = (hasuraData) => {
    this._logger.debug("[DATA] All Queues updated", hasuraData?.data?.skills)
    return hasuraData?.data?.skills?.map(remapSkill)
  }

  _processMine = (hasuraData) => {
    this._logger.debug("[DATA] My Queues updated", hasuraData.data)
    let processed = {}
    for (const skillType of Object.keys(hasuraData.data)) {
      processed[skillType] = hasuraData.data[skillType].map(remapSkill)
    }
    return processed
  }

  _subscribeMy = (fn, { data, onError }) => {
    const queries = myQueuesSubscriptions
    const postProcess = this._processMine
    const sub1 = this._hasuraService.subscribe(fn, { subscriptionQuery: queries[0], data, postProcess }, onError)
    const sub2 = this._hasuraService.subscribe(fn, { subscriptionQuery: queries[1], data, postProcess }, onError)
    return [sub1, sub2]
  }

  _subscribeAll = (fn, { data = {}, onError, partnerId }) => {
    delete data.partnerId
    const subscriptionQuery = getAllQuery({ partnerId, sub: true })
    const postProcess = this._processAll
    return this._hasuraService.subscribe(fn, { subscriptionQuery, data, postProcess }, onError)
  }

  subscribe(fn, { data, onError } = {}) {
    const { partnerId, agentId } = data || {}
    return agentId ? this._subscribeMy(fn, { data, onError }) : this._subscribeAll(fn, { data, onError, partnerId })
  }

  _getHasura = async (partnerId, agentId) => {
    const mine = !!agentId
    const query = mine ? myQueuesQuery : getAllQuery({ partnerId })
    const data = { agentId }
    const result = await this._hasuraService.query({ query, data })
    return { data: mine ? this._processMine(result) : this._processAll(result) }
  }

  _getMy({ agentId, partnerId }) {
    const useHasura = this._config.get("dataService.myQueues") === "hasura-api"
    return useHasura ? this._getHasura(partnerId, agentId) : this._getMartti({ agentId })
  }

  _getAll({ partnerId }) {
    const useHasura = this._config.get("dataService.queues") === "hasura-api"
    return useHasura ? this._getHasura(partnerId) : this._getMartti()
  }

  get({ agentId, partnerId } = {}) {
    return agentId ? this._getMy({ agentId, partnerId }) : this._getAll({ partnerId })
  }

  _getMartti = async ({ agentId } = {}) => {
    /*
      Fetches queues
      
      - returns Array of system-wide list of queues (when agentId is not provided)
      - returns Object containing activeQueues (Array) and availableQueues (Array) when agentId is specified
    */
    const endpoint = agentId ? `/v1/agents/${agentId}/queues` : "/v1/queues"
    const response = await this._httpService.get(`${this.url}${endpoint}`, {
      headers: { Authorization: `bearer ${this.token}` },
      "axios-retry": {
        retries: 3,
      },
    })
    return response
  }

  stats = async ({ queueId }) => {
    return await this._httpService.get(`${this.url}/v1/queues/${queueId}/interpreter-statistics`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  agents = {
    get: ({ queueId, presenceId }) => {
      return this._httpService.get(`${this.url}/v1/queues/${queueId}/agents/${presenceId}`, {
        headers: { Authorization: `bearer ${this.token}` },
      })
    },
  }
}

module.exports = Queues
