const ClientInterface = require("./clientInterface")
const ServerConnection = require("./serverConnection")
const MessageProcessing = require("./messageProcessing")

const { clientEventNames } = require("../constants")

const supportedEvents = {
  [clientEventNames.CONNECT]: true,
  [clientEventNames.PROFILE]: true,
  [clientEventNames.RINGING]: true,
  [clientEventNames.ANSWERED]: true,
  [clientEventNames.END_CALL]: true,
  [clientEventNames.CALL_SEGMENTS]: true,
  [clientEventNames.CALL_PARTICIPANTS]: true,
  [clientEventNames.STATUS]: true,
  [clientEventNames.LOGOUT]: true,
  [clientEventNames.RECONNECTING]: true,
  [clientEventNames.MULTI_CALL]: true, //TODO multi-call: remove after multi-call is resolved
}

/**
 * Server-Sent Event V2
 *
 * To use call the .open() method
 *
 * To dispose of, call the .close() method
 *
 * Missing too many keep-alive's will emit the "logout" message which means your connection has probably been broken.
 * To prevent memory leaks please dispose of this instance once this happens.
 */
class SSEv2 extends ClientInterface {
  _loggerService = null
  _configService = null
  _dataService = null
  _authService = null

  _serverConnection = null
  _messageProcessing = null

  constructor({ logger, configService, dataService, authService }) {
    super()
    this._loggerService = logger
    this._configService = configService
    this._dataService = dataService
    this._authService = authService

    this._serverConnection = new ServerConnection({
      logger: this._getLogger.bind(this),
      dataService: this._getDataService.bind(this),
      configService: this._getConfigService.bind(this),
      authService: this._getAuthService.bind(this),
      onMessage: this._onMessage.bind(this),
      onConnect: () => this.emit(clientEventNames.CONNECT, true),
      onDisconnect: () => this.emit(clientEventNames.CONNECT, false),
      onClose: () => this.emit(clientEventNames.LOGOUT, true),
    })
    this._messageProcessing = new MessageProcessing({
      logger: this._getLogger.bind(this),
      dataService: this._getDataService.bind(this),
      configService: this._getConfigService.bind(this),
      onEmit: this.emit.bind(this),
      onOpen: this._serverConnection.onOpen.bind(this._serverConnection),
      onKeepAlive: this._serverConnection.onKeepAlive.bind(this._serverConnection),
      onSubscribe: this._serverConnection.onSubscribe.bind(this._serverConnection),
      onUnsubscribe: this._serverConnection.onUnsubscribe.bind(this._serverConnection),
    })
  }

  _getLogger() {
    return this._loggerService
  }

  _getConfigService() {
    return this._configService
  }

  _getDataService() {
    return this._dataService
  }

  _getAuthService() {
    return this._authService
  }

  _onMessage(...params) {
    return this._messageProcessing.process(...params)
  }
}

module.exports = SSEv2
module.exports.supportedEvents = supportedEvents
