import { useEffect } from "react"
import { logger } from "utils/logger"

const useCallAttributes = ({ answeredCall, ringingCall }) => {
  useEffect(() => {
    if (!ringingCall) {
      return
    }

    logger.addAttribute("ringingInteractionId", ringingCall.interaction?.name)

    return () => logger.removeAttribute("ringingInteractionId")
  }, [ringingCall])

  useEffect(() => {
    if (!answeredCall) {
      return
    }

    logger.addAttribute("interactionName", answeredCall?.interaction?.name)
    logger.addAttribute("interaction Partner", answeredCall?.interaction?.hospitalId)

    return () => {
      logger.removeAttribute("interactionName")
      logger.removeAttribute("interaction Partner")
    }
  }, [answeredCall])

  return
}

export default useCallAttributes
