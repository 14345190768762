const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

class Config extends DataServiceEndPoint {
  static key = "Config"

  audio = () => {
    return this._httpService.get(`${this.url}/v1/configuration/audio-conference-configuration`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  session = () => {
    return this._httpService.get(`${this.url}/v1/configuration/session`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  webRtcTestConfig = () => {
    return this._httpService.get(`${this.url}/v1/webrtc-tests`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  webRtcTestResultSubmit = ({ agentId, result, tests }) => {
    return this._httpService.post(
      `${this.url}/v1/webrtc-tests`,
      {
        agentId,
        result,
        tests,
      },
      {
        headers: { Authorization: `bearer ${this.token}` },
        retries: 3,
      }
    )
  }

  version = () => {
    const root = `${location.origin}/`
    return this._httpService.get(`${root}${this._config.get("versionJson")}`, {
      headers: {
        pragma: "no-cache",
        "cache-control": "no-store",
      },
    })
  }

  statistics = () => {
    return this._httpService.get(`${this.url}/v1/configuration/statistics`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  marttiUrls = () => {
    return this._httpService.get(`${this.url}/v1/configuration/martti`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }
}

module.exports = Config
