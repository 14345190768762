import { useCallback, useEffect, useState } from "react"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { addTextToBackground } from "utils/addTextToBackground"
import { logger } from "utils/logger"

/**
 * Overlays the background image with the provided text.
 * The URL of the background image comes from the config (player.virtualBackground.url).
 * The config controls whether this hook is enabled (player.virtualBackground.injectAgentId).
 * @param {string} displayName - Text to be displayed in a white box over top of the background image
 * @returns {string} - Base64-encoded data url of the image with the styled label applied to it
 */
export const usePlayerBackground = (displayName) => {
  const enableForegroundLabelInjection = useConfigFlag("player.virtualBackground.enableForegroundLabelInjection")
  const enableInjector = useConfigFlag("player.virtualBackground.injectAgentId")

  const templateUrl = useConfigFlag("player.virtualBackground.url")
  const [url, setUrl] = useState(templateUrl)

  const _setUrl = useCallback(async(_displayName) => {
    // If we are using the webRTC player's foreground label injection, we don't need to inject the label in the background
    if (enableForegroundLabelInjection) {
      return setUrl(templateUrl)
    }

    try {
      const dataUrl = await addTextToBackground(templateUrl, _displayName)
      setUrl(dataUrl)
    } catch (err) {
      logger.error("Error injecting agent ID into background template", err)
    }
  }, [templateUrl, enableForegroundLabelInjection])

  useEffect(() => {
    if (enableInjector && displayName) {
      _setUrl(displayName)
    }
  }, [_setUrl, displayName, enableInjector])

  return url
}
