import React from "react"
import cs from "classnames"

const c = 2 * Math.PI * 8

/**
 * Spinner component
 * 
 * requires a color to be set either through className prop or color prop
 *
 * @param {Number} value - percent complete (from 0 to 100)
 * @param {Boolean} hideValue - whether to display the numeric percent complete.  Defaults `false` when value provided
 * @param {Object} children - to be displayed in place of percent complete when provided
 * @param {String} className - class applied to parent element: (control size with width, spinner color with stroke, text color with fill)
 * @param {String} color - CSS color code for text and spinner (will over-ride tailwind colors when provided)
 * @param {Boolean} still - holds spinner stationary
 */
const Spinner = ({ value, hideValue, children, "data-testid": testId, className, color, still }) => {
  const percent = value === undefined ? 75 : value
  const displayValue = children ? children : !value && value !== 0 ? null : `${value}%`
  return (
    <div data-testid={testId} className={cs("relative", className)}>
      {!hideValue && displayValue && (
        <svg className="absolute flex justify-center items-center w-full h-full" viewBox="0 0 100 100">
          <text x={36} y={55} fontSize={13} fontWeight={1000} fill={color} strokeWidth={0} >
            {displayValue}
          </text>
        </svg>
      )}
      <svg className={cs({"animate-spin": !still})} viewBox={`0 0 20 20`}>
        <circle
          cx={10}
          cy={10}
          r={8}
          style={{
            fillOpacity: 0,
            stroke: color,
            strokeWidth: 1.5,
            strokeDasharray: `${(c * percent) / 100} ${(c * (100 - percent)) / 100}`,
          }}
        />
      </svg>
    </div>
  )
}

export default Spinner
