import React, { createContext } from "react"
import { useScripts as ScriptsData } from "hooks/useScripts"
import { createContextHook } from "utils/contextHelpers"

const ScriptsContext = createContext()

export const useScripts = createContextHook(
  ScriptsContext,
  "useScripts must be used within a ScriptsProvider"
)

export const ScriptsProvider = ({ children }) => {
  const value = ScriptsData()
  return <ScriptsContext.Provider value={value}>{children}</ScriptsContext.Provider>
}

export default ScriptsContext
