import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { getLoggerInstance } from "utils/logger"
import { dataService } from "utils/dataService"
import { getAppVersion } from "utils/appInfo"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { useAlertMessage } from "hooks/useAlertMessage"
import VersionNotification from "components/VersionNotification"
import { useAuth } from "contexts/Auth"

const logger = getLoggerInstance()

const localVersion = getAppVersion()

export const useUpdateNotification = () => {
  const enabled = useConfigFlag("automaticUpdates.enabled")
  const intervalMinutes = useConfigFlag("automaticUpdates.intervalMinutes", 1)
  const { showWarnToast, dismissToast } = useAlertMessage()
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const { SSEopen } = useAuth()
  const configSseVersion = useConfigFlag("sse.version")
  const [SSEversion, setSSEversion] = useState(null)

  useEffect(() => {
    if (!enabled) {
      return
    }
    const intervalTiming = intervalMinutes * 60_000
    const interval = setInterval(async () => {
      try {
        const versionObject = await dataService.Config.version()
        const { version } = versionObject.data
        logger.debug(`Successfully fetched remote version: ${version}`)
        if (localVersion !== version) {
          setShouldUpdate(true)
        }
      } catch (err) {
        logger.error("An error has ocurred while fetching the remote version/hash for comparison")
      }
    }, intervalTiming)

    return () => {
      clearInterval(interval)
    }
  }, [enabled, intervalMinutes])

  useEffect(() => {
    if (SSEopen && !SSEversion) {
      setSSEversion(configSseVersion)
    }
  }, [SSEopen, SSEversion, configSseVersion])

  useEffect(() => {
    if (!SSEversion) {
      return
    }
    if (configSseVersion !== SSEversion) {
      setShouldUpdate(true)
    }
  }, [SSEversion, configSseVersion])

  useEffect(() => {
    if (shouldUpdate) {
      let id = null
      const hideNotification = () => {
        setShouldUpdate(false)
        dismissToast(id)
      }
      id = showWarnToast(<VersionNotification snooze={hideNotification} />, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        location,
      })
    }
  }, [shouldUpdate, showWarnToast, dismissToast])

  return shouldUpdate
}
