import { useCallback, useEffect, useRef } from "react"
import { useAlertMessage } from "hooks/useAlertMessage"
import { dataService } from "utils/dataService"
import { config } from "utils/configService"
import { parseJWT } from "utils/helpers"
import { getLoggerInstance } from "utils/logger"
import { HttpErrors } from "@cloudbreakus/network-utilities"

const logger = getLoggerInstance()

export const audioTokenRefreshInterval = (tokenExpiryTimestamp) => {
  let tokenExpiryDuration = tokenExpiryTimestamp - Date.now()
  tokenExpiryDuration = typeof tokenExpiryDuration === "number" && tokenExpiryDuration > 0 ? tokenExpiryDuration : 0

  let percent = config.get("audioTokenRefresher.refreshEarlyPercentage")
  percent = typeof percent === "number" && percent >= 0 && percent <= 100 ? (100 - percent) / 100 : 0.9

  let minRefreshDuration = config.get("audioTokenRefresher.minimumIntervalDuration")
  minRefreshDuration =
    typeof minRefreshDuration === "number" && minRefreshDuration >= 30 ? minRefreshDuration * 1000 : 300_000

  return Math.max(tokenExpiryDuration * percent, minRefreshDuration)
}

const useAudioToken = (isAuthenticated) => {
  const { showCustomToast } = useAlertMessage()
  const audioConfigRef = useRef({ expiry: 0 })
  const audioTokenRefresher = useRef()

  const getAudioConfigOnce = useCallback(async () => {
    try {
      const res = await dataService.Config.audio()
      const _audioConfig = {
        token: res?.data?.token,
        expiry: (parseJWT(res?.data?.token)?.exp || 0) * 1000,
      }
      audioConfigRef.current = _audioConfig
      logger.debug("Audio config retrieved successfully", res?.data)
      return _audioConfig
    } catch (err) {
      logger.error("Fetching audio config", err)
      throw err instanceof HttpErrors.ThrottledNetworkError ? err : new Error("Error fetching audio token.")
    }
  }, [])

  const getAudioToken = useCallback(async () => {
    const audioConfig = audioConfigRef.current
    return config.get("audioTokenRefresher.everyCall") || audioConfig?.expiry <= Date.now()
      ? (await getAudioConfigOnce())?.token
      : audioConfig?.token
  }, [getAudioConfigOnce])

  useEffect(() => {
    // TODO: make reactive, the interval should probably be reactive as well

    if (!isAuthenticated || !config.get("audioTokenRefresher.enabled")) {
      return
    }

    const get = async () => {
      try {
        const token = await getAudioConfigOnce()
        audioTokenRefresher.current = setInterval(getAudioConfigOnce, audioTokenRefreshInterval(token?.expiry))
      } catch (err) {
        if (!(err instanceof HttpErrors.ThrottledNetworkError)) {
          showCustomToast({
            message: `Critical application error: ${err}.`,
            level: "error",
            onRetry: get,
          })
        }
      }
    }

    get()
    return () => {
      clearInterval(audioTokenRefresher.current)
    }
  }, [isAuthenticated, getAudioConfigOnce, showCustomToast])

  return {
    getAudioToken,
  }
}

export default useAudioToken
