import { useCallback, useRef, useState, useMemo } from "react"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import WebRTCClient from "@cloudbreakus/martti-webrtc-client"
import { VIDEO } from "constants/callTypes"
import { SUPERVISOR } from "constants/roles"

import { useProfile } from "contexts/Profile"
import { useQueues } from "contexts/Queues"
import { logger } from "utils/logger"
import { dataService } from "utils/dataService"
import { useStorage } from "@cloudbreakus/storage"
import { useAlertMessage } from "hooks/useAlertMessage"
import { usePureCallback } from "hooks/usePureCallback"
import useAudioToken from "hooks/useAudioToken/useAudioToken"
import useRoleValidation from "hooks/useRoleValidation"
import useStore from "store"
import { selectHasAVIssues } from "store/selectors/call"
import { usePlayerConfig } from "./usePlayerConfig"

const GENERIC_ERROR = "GENERIC_ERROR"
const NO_SUCH_VMR = WebRTCClient.Error.REMOTE_CONNECTION_ERROR
const VALIDATION_ERROR = WebRTCClient.Error.VALIDATION_ERROR
const LOAD_ERROR = WebRTCClient.Error.LOAD_ERROR
const NMS_SOCKET_ROOM_NOT_FOUND_ERROR = WebRTCClient.Error.NMS_SOCKET_ROOM_NOT_FOUND_ERROR
const NMS_SOCKET_PARTICIPANT_NOT_FOUND_ERROR = WebRTCClient.Error.NMS_SOCKET_PARTICIPANT_NOT_FOUND_ERROR
const NMS_SOCKET_BAD_ROOM_STATE_ERROR = WebRTCClient.Error.NMS_SOCKET_BAD_ROOM_STATE_ERROR
const NMS_SOCKET_UNAUTHORIZED_ERROR = WebRTCClient.Error.NMS_SOCKET_UNAUTHORIZED_ERROR
const NMS_SOCKET_INTERNAL_SERVER_ERROR = WebRTCClient.Error.NMS_SOCKET_INTERNAL_SERVER_ERROR

export const webRTCErrors = {
  GENERIC_ERROR,
  NO_SUCH_VMR,
  VALIDATION_ERROR,
  LOAD_ERROR,
  NMS_SOCKET_ROOM_NOT_FOUND_ERROR,
  NMS_SOCKET_PARTICIPANT_NOT_FOUND_ERROR,
  NMS_SOCKET_BAD_ROOM_STATE_ERROR,
  NMS_SOCKET_UNAUTHORIZED_ERROR,
  NMS_SOCKET_INTERNAL_SERVER_ERROR,
}

export const usePlayer = ({
  setDisconnected,
  setExpectingAnswer,
  setNmsParticipantId,
  abortAnswer,
  userData,
  isAuthenticated,
  answeredCall,
}) => {
  const { hasVideo } = useQueues()
  const { additionalUserData } = useProfile()
  const { getAudioToken } = useAudioToken(isAuthenticated)
  const { showInfoToast } = useAlertMessage()
  const isSupervisor = useRoleValidation({ role: SUPERVISOR })
  const [camId, setCamId] = useStorage("cameraDeviceId")
  const [camName, setCamName] = useStorage("cameraLabel")
  const [micId, setMicId] = useStorage("microphoneDeviceId")
  const [micName, setMicName] = useStorage("microphoneLabel")
  const [, setHasAVIssues] = useStore(selectHasAVIssues)
  const [reconnecting, setReconnecting] = useState(null)

  const cam = useMemo(
    () => ({
      deviceId: camId,
      niceLabel: camName,
    }),
    [camId, camName]
  )

  const mic = useMemo(
    () => ({
      deviceId: micId,
      niceLabel: micName,
    }),
    [micId, micName]
  )

  const transmitCallTelemetry = useConfigFlag("player.stats.transmit")
  const signalingServerUrl = useConfigFlag("player.signalingServerUrl")
  const { playerConfig } = usePlayerConfig()

  const webRTCConnection = useRef(null)
  const [error, setError] = useState(null)

  const setCam = useCallback(
    (c) => {
      setCamId(c.deviceId)
      setCamName(c.niceLabel)
      showInfoToast(`Default camera set to ${c.deviceId ? c.niceLabel : "the first available"}`)
    },
    [showInfoToast, setCamId, setCamName]
  )
  const setMic = useCallback(
    (m) => {
      setMicId(m.deviceId)
      setMicName(m.niceLabel)
      showInfoToast(`Default microphone set to ${m.deviceId ? m.niceLabel : "the first available"}`)
    },
    [showInfoToast, setMicId, setMicName]
  )

  const callEnd = useCallback(() => {
    setDisconnected(true)
  }, [setDisconnected])

  const stopWebRTC = useCallback(() => {
    webRTCConnection?.current?.dispose()
    webRTCConnection.current = null
  }, [])

  const holdParticipant = useCallback(
    async ({ participantId, participantName }) => {
      await webRTCConnection?.current?.holdParticipant({ id: participantId, name: participantName })
      await dataService.Participant.hold({ participantId, interactionId: answeredCall.interaction.id })
    },
    [answeredCall?.interaction?.id]
  )
  const unHoldParticipant = useCallback(
    async ({ participantId, participantName }) => {
      await webRTCConnection?.current?.unholdParticipant({ id: participantId, name: participantName })
      await dataService.Participant.unHold({ participantId, interactionId: answeredCall.interaction.id })
    },
    [answeredCall?.interaction?.id]
  )
  const endParticipant = useCallback(
    async ({ participantId }) => {
      await dataService.Participant.hangUp({ participantId, interactionId: answeredCall.interaction.id })
    },
    [answeredCall?.interaction?.id]
  )

  const callConnect = useCallback(() => {
    setDisconnected(false)
    setExpectingAnswer(false)
  }, [setDisconnected, setExpectingAnswer])

  const onCallStats = useCallback(
    (statsEvent) => {
      if (
        !transmitCallTelemetry ||
        answeredCall?.interaction?.mediaServer !== "NMS" ||
        statsEvent.participantMode === "LISTEN"
      ) {
        // We are only using telemetry data from NMS calls that are not listen-in, when the config flag is true
        return
      }

      const payload = { interactionId: answeredCall?.interaction?.id, userId: userData?.userId, statsEvent }
      dataService.Interactions.updateTelemetry(payload).catch((e) => {
        logger.error("Failed to update WebRTC telemetry", { message: e?.message, name: e?.name, payload })
      })
    },
    [answeredCall, userData, transmitCallTelemetry]
  )

  const callError = useCallback(
    (err, providerLeft) => {
      if (
        [NMS_SOCKET_ROOM_NOT_FOUND_ERROR, NMS_SOCKET_PARTICIPANT_NOT_FOUND_ERROR, NO_SUCH_VMR].includes(err?.name) ||
        providerLeft
      ) {
        const message = "Provider has left the interaction"
        setError({
          type: NO_SUCH_VMR,
          message,
        })
        abortAnswer(message)
      } else if (
        [NMS_SOCKET_BAD_ROOM_STATE_ERROR, NMS_SOCKET_UNAUTHORIZED_ERROR, NMS_SOCKET_INTERNAL_SERVER_ERROR].includes(
          err?.name
        )
      ) {
        setError({
          type: err?.name,
          message: `Something went wrong and the call ended unexpectedly`,
        })
      } else if (err?.name === LOAD_ERROR) {
        setError({
          type: err?.name,
          message: `Something went wrong and the call failed to load`,
        })
      } else {
        setError({
          type: GENERIC_ERROR,
          message: err?.name || `${err}`,
        })
      }
      callEnd()
    },
    [callEnd, abortAnswer]
  )

  const onMediaStreamRejected = useCallback(
    (err) => {
      setHasAVIssues(true)
      logger.warn("WebRTCClient: user stream rejected", err?.name, err?.message)
    },
    [setHasAVIssues]
  )

  const restartWebRTC = useCallback(async () => {
    if (answeredCall.interaction.mediaServer === "NMS") {
      setReconnecting(true)
      try {
        const { data } = await dataService.Interactions.reconnect({
          agentId: userData?.userId,
          taskId: answeredCall.taskId,
          interactionId: answeredCall.interaction.id,
          languageId: answeredCall.language.id,
          mode: answeredCall._action,
        })
        setNmsParticipantId(data.participantId)
        logger.debug("WebRTC player successfully restarted", data)
      } catch (err) {
        logger.error("Reconnecting to the call has failed", err)
        const providerLeft = err?.response?.status === 404
        const errorsToHandle = [
          {
            condition: /error performing add participant/i,
            message: "Reconnecting to the call has failed due to an issue adding you to the call",
          },
          {
            condition: /network error/i,
            message: "Reconnecting to the call has failed due to a network issue",
          },
          {
            condition: /timeout/i,
            message: "Reconnecting to the call has failed due to a prolonged wait",
          },
        ];
        const errorMessage = errorsToHandle.find(({ condition }) => condition.test(err?.message))?.message || "Reconnecting to the call has failed";
        callError({ ...err, name: errorMessage }, providerLeft)
        setReconnecting(false)
        return
      }
      setReconnecting(false)
    }
    setDisconnected(false)
  }, [setDisconnected, answeredCall, userData, callError, setNmsParticipantId])

  const startWebRTC = usePureCallback(async (playerRef) => {
    setDisconnected(false)
    const playerSettings = {
      ...playerConfig,
      // Enable overlays when remote participants mute their video only during NMS calls
      enableVideoMuteOverlays: answeredCall.interaction.mediaServer === "NMS",
      videoPreferences: {
        deviceId: cam.deviceId,
        constraint: hasVideo || isSupervisor ? null : WebRTCClient.VideoConstraintPreset.DISABLED,
      },
      audioPreferences: {
        deviceId: mic.deviceId,
      },
      sessionId: userData?.marttixAgentSessionId,
      interactionId: answeredCall?.interaction?.id,
    }
    logger.debug("attempting to start WebRTC", playerSettings)
    if (webRTCConnection.current) {
      return logger.debug("WebRTC player already started")
    }
    setError(null)

    const player = new WebRTCClient(playerRef.current, playerSettings)
    player.on(WebRTCClient.Event.CALL_START, callConnect)
    player.on(WebRTCClient.Event.CALL_END, callEnd)
    player.on(WebRTCClient.Event.CALL_ERROR, callError)
    player.on(WebRTCClient.Event.CALL_STATS, onCallStats)
    player.on(WebRTCClient.Event.LOCAL_MEDIA_STREAM_REJECTED, onMediaStreamRejected)

    webRTCConnection.current = player

    const displayName = additionalUserData?.interpreterNumber || userData?.userId
    const seedStartTimestamp = answeredCall?._action === "LISTEN" ? null : Date.parse(answeredCall?.acceptTime)

    try {
      if (answeredCall.interaction.mediaServer === "NMS" || answeredCall.interaction.channel === VIDEO) {
        const callSettings = {
          displayName,
          action: answeredCall._action,
          seedStartTimestamp,
          participantId: answeredCall.nmsParticipantId,
          signalingServerUrl,
        }
        logger.debug("starting NMS video call", callSettings)
        await player.nmsVideoCall(callSettings)
      } else {
        const audioToken = await getAudioToken()
        const callSettings = {
          displayName,
          from: answeredCall.audio.from,
          to: answeredCall.audio.to,
          token: audioToken,
          action: answeredCall._action,
          seedStartTimestamp,
        }
        logger.debug("starting Twilio audio call", callSettings)
        await player.twilioAudioCall(callSettings)
      }
    } catch (err) {
      logger?.error("WebRTC .call error", err)
      //TODO: does no such VMR even exist any more
      //TODO: dont allow presence changing while this is settling
      const providerLeft = err.name === VALIDATION_ERROR && err.message.includes("CallRequest.participantId")
      const errorsToHandle = [
        { condition: /error fetching audio token/i, message: "Unable to start the call due to an audio setup issue" },
      ];
      const errorMessage = errorsToHandle.find(({ condition }) => condition.test(err?.message))?.message || "Unable to start the call";
      callError({ ...err, name: errorMessage }, providerLeft)
    }
  })

  const sendDTMFTone = useCallback(
    (tones) => {
      webRTCConnection?.current?.sendDtmfTones?.(tones)
    },
    []
  )

  return {
    startWebRTC,
    stopWebRTC,
    restartWebRTC,
    webRTCerror: error,
    setCam,
    setMic,
    cam,
    mic,
    sendDTMFTone,
    endParticipant,
    holdParticipant,
    unHoldParticipant,
    reconnecting,
  }
}
