import React, { useState, useMemo, useEffect, useCallback } from "react"
import cs from "classnames"
import CollapsibleCard from "../CollapsibleCard"

import useStore from "store"
import { selectRole } from "store/selectors/auth"
import { SUPERVISOR } from "constants/roles"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"

const TABS = {
  ACCESS_CODE: "Access Code",
  CALL_SHEET: "Call Sheet",
  CASE_NOTES: "Case Notes",
  SCRIPTS: "Scripts",
  REPORT_IT_ISSUE: "Report IT Issue",
  SUPERVISOR_NOTES: "Supervisor Notes",
}

const CallForm = ({
  className,
  saved,
  requiresAccessCode,
  validAccessCode,
  refreshCallSheet,
  AccessCode,
  CallSheet,
  CaseNotes,
  SupervisorNotes,
  Scripts,
  ReportIT,
}) => {
  const supervisorNotesEnabled = useConfigFlag("ui.supervisorNotesEnabled")
  const [tab, openTab] = useState(TABS.CALL_SHEET)
  const role = useStore(selectRole)

  const showTab = useCallback(
    (name) => {
      if (name === TABS.ACCESS_CODE && !requiresAccessCode) {
        return false
      }
      if (name === TABS.CALL_SHEET && !validAccessCode) {
        return false
      }
      if (name === TABS.SUPERVISOR_NOTES && (role !== SUPERVISOR || !supervisorNotesEnabled)) {
        return false
      }
      return true
    },
    [requiresAccessCode, role, validAccessCode, supervisorNotesEnabled]
  )

  const tabs = useMemo(() => Object.values(TABS).filter(showTab), [showTab])

  const _openTab = useCallback(
    (name) => {
      if (name === TABS.CALL_SHEET) {
        refreshCallSheet()
      }
      openTab(name)
    },
    [refreshCallSheet]
  )

  useEffect(() => {
    if (validAccessCode) {
      openTab(TABS.CALL_SHEET)
    } else if (requiresAccessCode && !validAccessCode) {
      openTab(TABS.ACCESS_CODE)
    }
  }, [requiresAccessCode, validAccessCode])

  return (
    <CollapsibleCard
      title="Call Form"
      minSize="50%"
      minimize
      saved={saved}
      className={className}
      onSelectTab={_openTab}
      selectedTab={tab}
      tabs={tabs}
    >
      <AccessCode className={cs({ hidden: tab !== TABS.ACCESS_CODE }, "w-full")} />
      <CallSheet className={cs({ hidden: tab !== TABS.CALL_SHEET }, "w-full")} />
      <CaseNotes className={cs({ hidden: tab !== TABS.CASE_NOTES }, "w-full")} />
      <SupervisorNotes className={cs({ hidden: tab !== TABS.SUPERVISOR_NOTES }, "w-full")} />
      <Scripts className={cs({ hidden: tab !== TABS.SCRIPTS }, "w-full")} />
      <ReportIT className={cs({ hidden: tab !== TABS.REPORT_IT_ISSUE }, "w-full")} />
    </CollapsibleCard>
  )
}

export default CallForm
