const EventEmitter = require("eventemitter2")

export class CallManager extends EventEmitter {
  timeoutSeconds = 15
  _promises = [] // Array of objects with { resolve, timeout, action } props for each pending closeCall/answerCall request

  constructor({ configService } = {}) {
    super()
    this.configService = configService
  }

  _resolveAll(action) {
    const promises = [...this._promises]
    this._promises = []
    for (const promise of promises) {
      promise.resolve(promise.action === action)
      clearTimeout(promise?.timeout)
    }
  }

  /**
   * call this method when a ringing call is confirmed/detected
   */
  onCallRing() {
    this._resolveAll("ring")
  }

  /**
   * call this method when a call is confirmed to have started
   */
  onCallStart() {
    this._resolveAll("start")
  }

  /**
   * call this method when a call is confirmed to have ended
   */
  onCallEnd() {
    this._resolveAll("end")
  }

  setConfigService(config) {
    this.configService = config
  }

  /**
   * Closes a task with the given ID
   *
   * @param {string} taskId - ID of the task wishing to be closed
   * @returns {Promise<Boolean>} - resolves to:
   *  - true when a call task ends
   *  - false if instead of ending the task changes
   *  - the given "taskId" if the task ID is invalid
   */
  closeCall(taskId, nextPresence) {
    if (!taskId) {
      return new Promise((resolve) => resolve(taskId))
    }
    this.emit("closeCall", { taskId, nextPresence })
    return new Promise((resolve) => {
      this._promises.push({
        action: "end",
        resolve,
        timeout: this._waitForCallResponse("close"),
      })
    })
  }

  /**
   * Accepts a task with the given parameters
   *
   * @returns {Promise<Boolean>} - resolves to:
   *  - true when a call task is accepted
   *  - false if instead of being accepted the task changes in another way
   *  - null if a call is already in the process of being closed or answered
   */
  answerCall(...params) {
    if (this._promises.length) {
      return Promise.resolve(null)
    }
    this.emit("answerCall", ...params)
    return new Promise((resolve) => {
      this._promises.push({
        action: "start",
        resolve,
        timeout: this._waitForCallResponse("connect"),
      })
    })
  }

  /**
   * terminates the call manager
   */
  close() {
    this.removeAllListeners()
  }

  _waitForCallResponse(action) {
    const timeoutSeconds =
      this.configService?.get("callResponseWaitSeconds", this.timeoutSeconds) || this.timeoutSeconds
    return setTimeout(() => this._callDidNotRespond(timeoutSeconds, action), timeoutSeconds * 1000)
  }

  _callDidNotRespond(timeoutSeconds, action) {
    const warningMessage = [
      `The call task did not ${action} in the expected time (${timeoutSeconds} seconds).`,
      "It is recommended that you restart your application.",
    ]
    this.emit("warning", warningMessage.join("  "))
    this._resolveAll(false)
  }
}

export const callManager = new CallManager()
