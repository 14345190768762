import React from "react"
import cs from "classnames"
import Wrapper from "../Wrapper"
import * as StyleSheet from "../styles.js"

const CheckBox = ({ name, value, onChange = () => {}, label, theme, disabled, className, hideLabel, ...rest }) => {
  const styleSheet = StyleSheet[theme]?.(disabled) || StyleSheet["profile"](disabled)

  return (
    <Wrapper
      label={label}
      className={className}
      name={`checkbox-${name}`}
      styleSheet={styleSheet}
      hideLabel={hideLabel}
    >
      <label className="block" aria-label={label}>
        <div className={cs("flex", "flex-row")}>
          <input
            type="checkbox"
            value={value}
            checked={value}
            onChange={() => onChange(!value)}
            className={cs("form-checkbox", styleSheet.selectors)}
            disabled={disabled}
            {...rest}
          />
          <span className={cs("flex", styleSheet.label)}>{label}</span>
        </div>
      </label>
    </Wrapper>
  )
}

export default CheckBox
