import { reactLikeState } from "store/utils"

const callSlice = {
  answeredCall: null,
  ringingCall: null,
  error: null,
  hasAVIssues: false,
  expectingAnswer: false,
  dialHistory: [],
}

const createCallSlice = (set) => ({
  ...callSlice,
  setAnsweredCall: reactLikeState(set, ["answeredCall"]),
  setRingingCall: reactLikeState(set, ["ringingCall"]),
  setHasAVIssues: reactLikeState(set, ["hasAVIssues"]),
  setExpectingAnswer: reactLikeState(set, ["expectingAnswer"]),
  setDialHistory: reactLikeState(set, ["dialHistory"]),
})

export default createCallSlice
