const OPEN = "OPEN"
const CLOSE = "CLOSE" // this is the "request" to close a window
const CLOSED = "CLOSED" // this is the confirmation that a window was closed

const SIDE_WINDOW_OPENED = "SIDE_WINDOW_OPENED"
const SIDE_WINDOW_CLOSED = "SIDE_WINDOW_CLOSED"
const PORT_RECEIVED = "renderer-port-received"

const SCREENS = {
  CALL_SHEET: "call-support-window",
}

const WINDOW_MESSAGE_TYPES = {
  OPEN,
  CLOSE,
  CLOSED,
  PORT_RECEIVED,
}

const CLIENT_MESSAGE_TYPES = {
  SIDE_WINDOW_OPENED,
  SIDE_WINDOW_CLOSED,
}

const MESSAGES = {
  ...CLIENT_MESSAGE_TYPES,
  ...WINDOW_MESSAGE_TYPES,
  [SCREENS.CALL_SHEET]: {
    INITIAL_SETUP: "INITIAL_SETUP",
    CALL_SHEET_DATA: "CALL_SHEET_DATA",
  },
}

exports = module.exports = {
  WINDOW_MESSAGE_TYPES,
  CLIENT_MESSAGE_TYPES,
  MESSAGES,
  SCREENS,
}
