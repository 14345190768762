import React, { createContext } from "react"
import { useCallHistory as callHistoryData } from "hooks/useCallHistory"
import { createContextHook } from "utils/contextHelpers"

const CallHistoryContext = createContext()

export const useCallHistory = createContextHook(
  CallHistoryContext,
  "useCallHistory must be used within a CallHistoryProvider"
)

export const CallHistoryProvider = ({ children }) => {
  const value = callHistoryData()
  return <CallHistoryContext.Provider value={value}>{children}</CallHistoryContext.Provider>
}

export default CallHistoryContext
