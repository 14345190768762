import React from "react"
import cs from "classnames"

const Container = ({ name, children, className = [], ...rest }) => {
  return (
    <div
      {...rest}
      data-testid={`container-${name}`}
      className={cs("flex", className)}
    >
      {children}
    </div>
  )
}

export default Container
