import { useCallback, useState } from "react"
import { dataService } from "utils/dataService"
import { logger } from "utils/logger"
import { useAlertMessage } from "./useAlertMessage"
import useStore from "store"
import { selectAnsweredCallOnly } from "store/selectors/call"

const NOTE_CATEGORIES = {
  INTERPRETER_SERVICES: "Interpreter Services",
  IT: "IT",
  GENERAL: "General",
}

export const NOTE_SUBCATEGORIES = {
  COMMENT: "Comment",
  CONCERN: "Concern",
}

export const useInteractionNotes = () => {
  const { showCustomToast } = useAlertMessage()
  const [loading, setLoading] = useState(false)

  const executeCallSheetSetRequest = useCallback(
    async (payload, id) => {
      try {
        const interactionId = id || selectAnsweredCallOnly(useStore.getState())?.interaction?.id
        setLoading(true)
        await dataService.Interactions.CallSheet.set({
          interactionId,
          payload,
        })
        logger.info(`Interaction notes updated`, payload)
      } catch (err) {
        logger.error(`Interaction notes failed to updated`, err, payload)
        showCustomToast({
          message: `Case note failed to save.`,
          level: "error",
          closeable: true,
          onRetry: () => executeCallSheetSetRequest(payload, id),
        })
      } finally {
        setLoading(false)
      }
    },
    [showCustomToast]
  )

  const _submitNotesOrITIssue = useCallback(
    ({ category, subCategory = null, notes, id }) =>
      executeCallSheetSetRequest(
        {
          notes,
          noteCategory: category,
          noteSubCategory: subCategory,
        },
        id
      ),
    [executeCallSheetSetRequest]
  )

  const submitConcern = useCallback(
    (notes, { id } = {}) =>
      _submitNotesOrITIssue({
        category: NOTE_CATEGORIES.GENERAL,
        subCategory: NOTE_SUBCATEGORIES.CONCERN,
        notes,
        id,
      }),
    [_submitNotesOrITIssue]
  )

  const submitComment = useCallback(
    (notes, { id } = {}) =>
      _submitNotesOrITIssue({
        category: NOTE_CATEGORIES.GENERAL,
        subCategory: NOTE_SUBCATEGORIES.COMMENT,
        notes,
        id,
      }),
    [_submitNotesOrITIssue]
  )

  const submitItIssue = useCallback(
    (notes, { id } = {}) => _submitNotesOrITIssue({ category: NOTE_CATEGORIES.IT, notes, id }),
    [_submitNotesOrITIssue]
  )

  const submitSupervisorNote = useCallback(
    async (supervisorNotes, { id } = {}) => executeCallSheetSetRequest({ supervisorNotes }, id),
    [executeCallSheetSetRequest]
  )

  return {
    loading,
    submitConcern,
    submitComment,
    submitItIssue,
    submitSupervisorNote,
  }
}
