import { useEffect, useRef } from "react"
import { useCallSheetData } from "./Form/hooks/useCallSheetData"
import { mergeInteractionCallData } from "./utils/parseCallData"

export const useUpdateDetailsOnRing = ({ ringingCall, setRingingCall }) => {
  const { callSheetData } = useCallSheetData(ringingCall?.interaction?.id)
  const callSheetMergedRef = useRef(false)
  
  useEffect(() => {
    if (ringingCall && callSheetData && !callSheetMergedRef.current) {
      const newRingingCall = mergeInteractionCallData(callSheetData, ringingCall)
      setRingingCall(newRingingCall)
      callSheetMergedRef.current = true
    }
  }, [callSheetData, ringingCall, setRingingCall])

  useEffect(() => {
    if (!ringingCall) {
      callSheetMergedRef.current = false
    }
  }, [ringingCall])

  return
}
