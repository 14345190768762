export const THEMES = {
  bblue: "bblue",
  whiteBlue: "whiteBlue",
  morange: "morange",
  whiteOrange: "whiteOrange",
  slate: "slate",
  whiteSlate: "whiteSlate",
  navy: "navy",
  whiteNavy: "whiteNavy",
  success: "success",
  error: "error",
}

const colors = {
  [THEMES.bblue]: {
    base: "bg-[#146f9d] hover:bg-[#438cb1] focus:bg-[#438cb1] focus:outline-none",
    loading: "bg-[#a1c5d8]",
  },
  [THEMES.whiteBlue]: {
    base: "ring-2 ring-[#146f9d] focus:ring-2 bg-white hover:bg-[#d0e2eb] focus:ring-[#146f9d] focus:bg-[#d0e2eb] focus:hover:bg-[#d0e2eb] focus:outline-none",
    loading: "ring-2 ring-[#438cb1]",
    text: "text-[#146f9d]",
  },
  [THEMES.morange]: {
    base: "bg-[#dc6243] hover:bg-[#e38169] focus:bg-[#e38169] focus:outline-none",
    loading: "bg-[#f1c0b4]",
  },
  [THEMES.whiteOrange]: {
    base: "ring-2 ring-[#dc6243] focus:ring-2 bg-white hover:bg-[#f8e0d9] focus:ring-[#dc6243] focus:bg-[#f8e0d9] focus:hover:bg-[#f8e0d9] focus:outline-none",
    loading: "ring-2 ring-[#e38169]",
    text: "text-[#dc6243]",
  },
  [THEMES.slate]: {
    base: "bg-slate-500 hover:bg-slate-400 focus:bg-slate-400 focus:outline-none",
    loading: "bg-slate-200",
  },
  [THEMES.whiteSlate]: {
    base: "ring-2 ring-slate-500 focus:ring-2 bg-white hover:bg-slate-200 focus:ring-slate-500 focus:bg-slate-200 focus:hover:bg-slate-200 focus:outline-none",
    loading: "ring-2 ring-slate-400",
    text: "text-slate-500",
  },
  [THEMES.navy]: {
    base: "bg-[#06466C] hover:bg-[#146F9D] focus:bg-[#146F9D] focus:outline-none",
    loading: "bg-[#a1c5d8]",
  },
  [THEMES.whiteNavy]: {
    base: "ring-2 ring-[#06466C] focus:ring-2 bg-white hover:bg-[#d0e2eb] focus:ring-[#06466C] focus:bg-[#d0e2eb] focus:hover:bg-[#d0e2eb] focus:outline-none",
    loading: "ring-2 ring-[#146F9D]",
    text: "text-[#06466C]",
  },
  [THEMES.success]: {
    base: "bg-green-500 hover:bg-green-400 focus:bg-green-400 focus:outline-none",
    loading: "bg-green-200",
    disabled: "bg-green-500",
  },
  [THEMES.error]: {
    base: "bg-red-500 hover:bg-red-400 focus:bg-red-400 focus:outline-none",
    loading: "bg-red-200",
    disabled: "bg-gray-500",
  },
}

const base = (halfHeight, theme) => [
  "shadow-md rounded font-semibold",
  colors[theme]?.text || "text-white",
  halfHeight ? "py-1 mb-0" : "py-2",
]

export const stylesheet = ({ theme, halfHeight, loading, disabled }) => [
  ...base(halfHeight, theme),
  disabled ? colors[theme].disabled || "bg-gray-500" : loading ? colors[theme].loading : colors[theme].base,
]

export const icons = {
  [THEMES.whiteBlue]: "stroke-[#146f9d] fill-[#146f9d]",
  [THEMES.whiteOrange]: "stroke-[#dc6243] fill-[#dc6243]",
  [THEMES.whiteSlate]: "stroke-slate-500 fill-slate-500",
  [THEMES.whiteNavy]: "stroke-[#06466C] fill-[#06466C]",
}
