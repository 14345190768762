const EventEmitter = require("eventemitter2")
const { Cooler } = require("martti-agent-services")

/**
 * Services to be injected.
 * @typedef {Object} Services
 * @property {Object} logger - logger-like service, see the logger implementation
 * @property {Object} config - configuration-like service, see the config implementation
 * @property {Object} dataService - service for fetching stats from an async function. It is an object with a Queues object with a stats method
 */

/**
 * @class QueueStatsManager
 * @description Manages the queue stats for the queues in the system
 * @param {Services} services - services to be injected
 */
class QueueStatsManager extends EventEmitter {
  _fetching
  _queues = {}

  constructor({ config, logger, dataService }) {
    super()
    this._logger = logger
    this._dataService = dataService

    const threshold = config?.get("availableInterpreters.secondsBetweenFetches", 2) || 2
    this._cooler = new Cooler()
    this._cooler.threshold = threshold
    this._cooler.on("coolDown", (coolDown) => this.emit("coolDown", coolDown))
    config?.on("change.availableInterpreters.secondsBetweenFetches", (s) => this._cooler.threshold = s.value)
  }

  /**
   * Refreshes the statistics for the currently provided queues
   */
  refreshStats = () => this._fetchQueueList(this._queues)

  /**
   * Provides the desired queues and fetches their statistics
   * @param {Object} queues - the queues to fetch stats for //TODO: define the shape of the queues object
   */
  setQueueList = (queues) => this._fetchQueueList(queues)

  _fetchQueueList = async(queues) => {
    if (this._fetching || !queues || Object.keys(queues).length === 0 || this._cooler.coolDown) {
      return
    }
    this._cooler.tick()
    this._fetching = true
    this.emit("fetching", true)
    
    this._queues = queues
    await Promise.all(Object.values(queues).map((queue) => this._refreshStats(queue)))

    this._fetching = false
    this.emit("fetching", false)
  }

  _refreshStats = async (queue) => {
    this._queues[queue.id].loading = true
    this._queues[queue.id].totalAgents = "?"
    this._queues[queue.id].availableAgents = "?"
    this.emit("queueListProcessed", this._queues)
    try {
      const response = await this._dataService.Queues.stats({ queueId: queue.id })
      if (!this._queues[queue.id]) {
        return
      }
      this._queues[queue.id].totalAgents = response.data.totalAgents
      this._queues[queue.id].availableAgents = response.data.availableAgents
    } catch (err) {
      this._logger.error(`unable to update ${queue?.name} stat`, err)
    }
    this._queues[queue.id].loading = false
    this.emit("queueListProcessed", this._queues)
  }

  /**
   * Resets the manager to it's initial state
   */
  reset = () => {
    this._cooler.reset()
    this._fetching = false
    this._queues = {}
  }
}

export default QueueStatsManager
