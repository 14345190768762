import React, { createContext, memo, useEffect, useState, useCallback, useMemo } from "react"
import { useCall } from "contexts/Call"
import { useQueues } from "contexts/Queues"
import { useProfile } from "contexts/Profile"
import { dataService } from "utils/dataService"
import { createContextHook } from "utils/contextHelpers"
import { logger } from "utils/logger"
import { useAlertMessage } from "hooks/useAlertMessage"
import { useRoleQueues } from "contexts/Queues/useRoleQueues"

const CallHandlingDataContext = createContext()

const getSectionName = (isMcc, isMCCQueue, partnerName) => {
  if (isMCCQueue === isMcc) {
    return partnerName ?? "In-house"
  }
  return isMcc ? "Martti" : "Outsource"
}

const _CallHandlingDataProvider = ({ children }) => {
  const { answeredCall } = useCall()
  const { additionalUserData } = useProfile()
  const { queues } = useQueues()
  const { showSuccessToast, showErrorToast } = useAlertMessage()

  const [transferring, setTransferring] = useState(false)
  const [transferred, setTransferred] = useState(false)
  const [adding, setAdding] = useState(false)
  const { operatorQueueIds, mainOperatorQueueId } = useRoleQueues()

  const queuesList = useMemo(() => {
    const mccUser = additionalUserData?.microCallCenter
    const partner = additionalUserData?.partner?.clientName
    return Object.fromEntries(
      queues.all.map((queue) => [
        queue.id,
        {
          name: queue.name,
          timeoutQueue: queue.timeoutQueueId,
          section: getSectionName(mccUser, queue.microCallCenter, partner),
          priority: mccUser === queue.microCallCenter,
        },
      ])
    )
  }, [queues?.all, additionalUserData?.microCallCenter, additionalUserData?.partner?.clientName])

  const getRelatedQueues = useCallback(
    (id) => {
      const myQueue = queuesList[id]
      let queueId = myQueue ? id : mainOperatorQueueId
      if (!queueId) {
        return {}
      }
      if (operatorQueueIds.has(queueId)) {
        return { [queueId]: { id: queueId, name: queuesList[queueId]?.name } }
      }
      const words = myQueue.name
        ?.split(" ")
        .filter((word) => word !== "(audio)")
        .map((word) => word?.toLowerCase())

      const queueIds = [
        ...new Set([
          queueId, // my queue
          mainOperatorQueueId, // the main operator queue
          myQueue.timeoutQueue, // where my queue times-out to
          ...Object.entries(queuesList)
            .filter(([, q]) => q.timeoutQueue === queueId)
            .map(([id]) => id), // queues that time-out to my queue
          ...Object.entries(queuesList)
            .filter(([, q]) =>
              words.some((word) => q.name.split(" ").some((nameWord) => nameWord?.toLowerCase() === word))
            )
            .map(([id]) => id), // queues with similar names
        ]),
      ]
        .filter((q) => q)
        .slice(0, 5)

      return queueIds.reduce((o, id) => ({ ...o, [id]: { id, name: queuesList[id]?.name } }), {})
    },
    [queuesList, mainOperatorQueueId, operatorQueueIds]
  )

  const transfer = useCallback(
    async (queueId) => {
      const queueName = queuesList[queueId]?.name ? `${queuesList[queueId].name} queue` : `queue ID "${queueId}"`
      try {
        setTransferring(true)
        await dataService.Calls.transfer({
          queueId,
          taskId: answeredCall.taskId,
        })
        setTransferred(true)
        logger.debug(`Call "${answeredCall.taskId}" transferred to queue "${queueId}"`)
      } catch (err) {
        const reason = err?.response?.data?.message ? ` (${err?.response?.data?.message})` : ""
        showErrorToast(`Unable to transfer provider to ${queueName}${reason}`, { autoClose: true })
        logger.error(
          `Unable to transfer call "${answeredCall.taskId}" to queue "${queueId}":`,
          err?.response?.data?.message,
          err
        )
      } finally {
        setTransferring(false)
      }
    },
    [answeredCall?.taskId, queuesList, showErrorToast]
  )

  const addToCall = useCallback(
    async (queueId) => {
      const queueName = queuesList[queueId]?.name ? `${queuesList[queueId].name} queue` : `queue ID "${queueId}"`
      try {
        setAdding(true)
        await dataService.Calls.transfer({
          queueId,
          taskId: answeredCall.taskId,
        })
        showSuccessToast(`Request for ${queueName} interpreter successful`)
        logger.debug(`Call "${answeredCall.taskId}" transferred to ${queueName} "${queueId}"`)
      } catch (err) {
        const reason = err?.response?.data?.message ? ` (${err?.response?.data?.message})` : ""
        showErrorToast(`Unable to add ${queueName} interpreter to the call${reason}`, { autoClose: true })
        logger.error(
          `Unable to transfer call "${answeredCall.taskId}" to ${queueName} "${queueId}"`,
          err?.response?.data?.message,
          err
        )
      } finally {
        setAdding(false)
      }
    },
    [answeredCall?.taskId, showSuccessToast, showErrorToast, queuesList]
  )

  const addInterpreterToCall = useCallback(
    async (agentId, agentName, queueId) => {
      const agent = agentName ?? `with ID ${agentId}`
      try {
        setAdding(true)
        await dataService.Calls.transferToAgent({
          agentId,
          taskId: answeredCall.taskId,
          queueId,
        })
        logger.debug(`Successfully transferred call to agent ${agentId} - on queue ${queueId}`)
        showSuccessToast(`Successfully transferred call to agent ${agent}`)
      } catch (err) {
        logger.error(
          `An error has occurred while calling agent "${agentName}" with ID "${agentId}" on queue ${queueId}`,
          err
        )
        showErrorToast(`Unable to transfer call to ${agent}`, { autoClose: true })
      } finally {
        setAdding(false)
      }
    },
    [answeredCall, showErrorToast, showSuccessToast]
  )

  useEffect(() => {
    setTransferred(false)
  }, [answeredCall])

  return (
    <CallHandlingDataContext.Provider
      value={{
        operatorQueueIds,
        getRelatedQueues,
        queuesList,
        transfer,
        transferring,
        transferred,
        addToCall,
        addInterpreterToCall,
        adding,
      }}
    >
      {children}
    </CallHandlingDataContext.Provider>
  )
}

export const CallHandlingDataProvider = memo(_CallHandlingDataProvider)

export const useCallHandling = createContextHook(
  CallHandlingDataContext,
  "useCallHandling must be used within a CallHandlingDataContext"
)

export default CallHandlingDataContext
