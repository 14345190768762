const { DTO } = require("@cloudbreakus/network-utilities")

class CallParticipantDTO extends DTO {
  requiredFields = ["id", "onHold", "type"]
  optionalFields = ["agent", "name", "number", "startTime"]

  toPresentationalObject = (cp) => ({
    ...cp,
    label: cp?.number || cp?.name,
    userId: cp?.agent?.interpreterId || cp?.name,
  })
}

module.exports = CallParticipantDTO
