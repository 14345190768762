const { PUBLIC_MESSAGES } = require("../constants")

class Dispatcher {
  _eventTarget = null
  constructor(eventTarget) {
    this._eventTarget = eventTarget
  }

  start() {
    this._eventTarget.dispatchEvent(new Event(PUBLIC_MESSAGES.START))
  }
  write(message) {
    if (message.data && message.id) {
      this._eventTarget.dispatchEvent(
        new MessageEvent(PUBLIC_MESSAGES.MESSAGE, { data: message.data, lastEventId: message.id })
      )
      this._eventTarget.dispatchEvent(new MessageEvent(message.id, { data: message.data, lastEventId: message.id }))
    }
  }
  close() {
    this._eventTarget.dispatchEvent(new CloseEvent(PUBLIC_MESSAGES.CLOSE))
    this._eventTarget = null
  }
  abort(reason) {
    this._eventTarget.dispatchEvent(new CloseEvent(PUBLIC_MESSAGES.ABORT, { reason }))
    this._eventTarget = null
  }
}
const buildOutputStream = (eventTarget) => {
  return new WritableStream(new Dispatcher(eventTarget))
}

module.exports = {
  Dispatcher,
  buildOutputStream,
}
