"use strict";

var axios = require("axios");
var axiosRetry = require("axios-retry");
var initializeAxiosInstance = function initializeAxiosInstance(config) {
  var _config$get, _config$get2;
  var _instance = axios.create({
    timeout: (config === null || config === void 0 ? void 0 : (_config$get = config.get) === null || _config$get === void 0 ? void 0 : _config$get.call(config, "network.axios.timeout")) || 15000,
    withCredentials: true
  });
  axiosRetry(_instance, {
    retries: (config === null || config === void 0 ? void 0 : (_config$get2 = config.get) === null || _config$get2 === void 0 ? void 0 : _config$get2.call(config, "network.axios.retries")) || 0,
    // number of retries
    retryDelay: function retryDelay(retryCount) {
      var _config$get3;
      return retryCount * ((config === null || config === void 0 ? void 0 : (_config$get3 = config.get) === null || _config$get3 === void 0 ? void 0 : _config$get3.call(config, "network.axios.retryDelay")) || 2000);
    }
  });
  return _instance;
};
module.exports = initializeAxiosInstance;