/* istanbul ignore file */
const imageLoaded = (imageElement) => {
  return new Promise((res, rej) => {
    if (!imageElement) {
      return rej();
    }

    imageElement.addEventListener(
      'load',
      res,
      { once: true }
    );

    imageElement.addEventListener(
      'error',
      rej,
      { once: true }
    );
  });
};

const labelVerticalPosition = 0.85
const textFontFamily = 'sans-serif';
const textSizePx = 48;
const textColor = 'black';
const backgroundColor = 'white';
const paddingEm = 0.5;

const applyBoxShaddow = (context) => {
  context.shadowOffsetX = 1;
  context.shadowOffsetY = 1;
  context.shadowColor = 'black';
  context.shadowBlur = 10;
};

const renderLabelBox = (context, labelText) => {
  // Set the font and get the rendered width of the text
  context.font = `${textSizePx}px ${textFontFamily}`;
  const { width: textWidthPx } = context.measureText(labelText);

  // Set padding as proption of font size
  const paddingYPx = textSizePx * paddingEm;
  const paddingXPx = paddingYPx;

  // 85% of the way down the canvas
  const anchorPointY = context.canvas.height * labelVerticalPosition;
  const anchorPointX = 0;

  // Save the styling state to restore later
  context.save();

  context.fillStyle = backgroundColor;
  applyBoxShaddow(context);

  // Position the box at the anchor point, allowing for text padding
  const boxX = anchorPointX;
  const boxY = anchorPointY - paddingYPx;
  const boxWidth = textWidthPx + (2 * paddingXPx);
  const boxHeight = textSizePx + (2 * paddingYPx);
  context.fillRect(boxX, boxY, boxWidth, boxHeight);

  // Restore styling state prior to box shadow styles
  context.restore();

  // Render the text inside the box
  context.fillStyle = textColor;
  context.textBaseline = 'top';
  context.fillText(labelText, anchorPointX + paddingXPx, anchorPointY);
}

/**
 * Overlays a given background image with given text.
 * The text appears in black in the lower left third of the screen over a white banner.
 * @async
 * @param {string} backgroundImageUrl - URL of the base background image
 * @param {string} overlayText - Text to be displayed in a white box over top of the background image
 * @returns {Promise<string>} - Base64-encoded data url of the image with the styled label applied to it
 */
export const addTextToBackground = async (backgroundImageUrl, overlayText) => {
  const img = Object.assign(document.createElement('img'), { src: backgroundImageUrl, crossOrigin: "anonymous" });

  // TODO: Handle image load failure
  await imageLoaded(img);

  const canvas = document.createElement('canvas');
  canvas.height = img.naturalHeight;
  canvas.width = img.naturalWidth;

  const context = canvas.getContext('2d');
  context.drawImage(img, 0, 0);

  renderLabelBox(context, overlayText);
  
  return canvas.toDataURL();
};

export default addTextToBackground
