const { DTO } = require("@cloudbreakus/network-utilities")

class CallSheetModel extends DTO {
  allowAllFields = true
  acceptNulls = true

  toPresentationalObject(payload) {
    if (!payload.departmentId) {
      delete payload.departmentId
    }

    return payload
  }
}

module.exports = CallSheetModel
