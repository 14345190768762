import { useCallback, useEffect, useState } from "react"
import { dataService } from "utils/dataService"
import { getLoggerInstance } from "utils/logger"

const Logger = getLoggerInstance()

export const usePartnerAccessCode = (partnerId, validatedAccessCode) => {
  const [partnerAC, setPartnerAC] = useState(null)
  const [loading, setLoading] = useState(false)

  const getPartnerAccessCode = useCallback(async ({ accessCode, partnerId }) => {
    setLoading(true)
    try {
      const response = await dataService.Partners.getAccessCode({
        partnerId,
        accessCode,
      })
      const partnerAccessCodes = response?.data
      Logger.debug("Successfully retrieved partner's access code:", partnerAccessCodes)
      setPartnerAC(partnerAccessCodes?.[0])
    } catch (err) {
      Logger.error("An error occurred while fetching partner's access code", err)
    }
    setLoading(false)
  }, [])

  const getAccessCode = useCallback(async ({ accessCode }) => {
    setLoading(true)
    try {
      const response = await dataService.Interactions.AccessCode.get(accessCode)
      const accessCodeObject = response?.data
      Logger.debug("Successfully retrieved access code:", accessCodeObject)
      setPartnerAC(accessCodeObject)
    } catch (err) {
      Logger.error("An error occurred while fetching partner's access code", err)
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    if (!partnerId || validatedAccessCode) {
      return
    }
    getPartnerAccessCode({ partnerId })
  }, [getPartnerAccessCode, partnerId, validatedAccessCode])

  useEffect(() => {
    if (!partnerId && !validatedAccessCode && partnerAC) {
      setPartnerAC(null)
    }
  }, [partnerAC, partnerId, validatedAccessCode])

  return {
    accessCodeObject: partnerAC,
    loadingAccessCode: loading,
    getPartnerAccessCode,
    getAccessCode,
  }
}
