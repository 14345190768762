import React, { useEffect } from "react"
import { Button, THEMES } from "@cloudbreakus/ui-components"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAlerts } from "@cloudbreakus/ui-components"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"

const toastConfig = {
  autoClose: false,
  closeButton: true,
  position: toast.POSITION.TOP_CENTER,
  closeOnClick: false,
  draggable: false,
  className: "w-[450px] right-[85px] !cursor-default",
  bodyClassName: "!p-0 !m-0",
}

const MFA_NOTIFICATION_TOAST_ID = "MFA_NOTIFICATION_TOAST"

export function MfaNotification() {
  const navigate = useNavigate()
  const { dismissToast } = useAlerts()

  return (
    <div className="flex flex-row pl-2 gap-4">
      <div className="text-sm">
        <span>
          Multi-Factor Authentication (MFA) is not enabled.
          <br />
          Please secure your account by enabling it.
        </span>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Button
          className="w-20 text-sm"
          halfHeight
          onClick={() => {
            navigate("/settings/my-info")
            dismissToast(MFA_NOTIFICATION_TOAST_ID)
          }}
          theme={THEMES.button.bblue}
          type="button"
        >
          Enable
        </Button>
      </div>
    </div>
  )
}

export const useMFANotification = ({ hasOptedInMFA, isAuthenticated }) => {
  const { showToast } = useAlerts()
  const showMfaEnableButton = useConfigFlag("mfa.showEnableButton")

  useEffect(() => {
    if (showMfaEnableButton && isAuthenticated && !hasOptedInMFA) {
      showToast(<MfaNotification />, null, {
        ...toastConfig,
        toastId: MFA_NOTIFICATION_TOAST_ID,
      })
    }
  }, [hasOptedInMFA, isAuthenticated, showMfaEnableButton, showToast])

  return
}
