const FetchEventStream = require("../../services/FetchEventStream")
const { serverEventNamesV2 } = require("../constants")
const { clientToServer: translate } = require("./translate")

const initialEvents = [
  serverEventNamesV2.AGENT,
  serverEventNamesV2.AGENT_TASKS,
  serverEventNamesV2.AGENT_PRESENCE,
  serverEventNamesV2.RESERVATION_UPDATED,
]

class ServerConnection {
  _eventTarget = null
  _channelHash = null
  _serverSubscriptions = null // { AGENT_PRESENCE: {key: "us3r1d"}, INTERACTION_PARTICIPANTS: {key: "int3r4cti0n1d"}}

  _reconnecting = false
  _missedKeepAlives = 0
  _openAttempts = 0
  _initialConfigFetched = false

  _onMessage = null
  _onConnect = null
  _onDisconnect = null
  _onClose = null

  constructor({ logger, dataService, authService, configService, onMessage, onConnect, onDisconnect, onClose } = {}) {
    this._logger = logger
    this._auth = authService
    this._dataService = dataService
    this._config = configService
    this._onMessage = onMessage
    this._onConnect = onConnect
    this._onDisconnect = onDisconnect
    this._onClose = onClose
  }

  _fetchInitialConfig = async () => {
    try {
      const { data } = await this._dataService().Config.marttiUrls()
      this._logger?.()?.debug?.("Martti URL's fetched", data)
      const { marttiSseUrl, marttiNotificationUrl } = data
      this._config().set("sse.v2.url", marttiSseUrl)
      this._config().set("sse.v2.notificationUrl", marttiNotificationUrl)
      this._initialConfigFetched = true
    } catch (err) {
      this._logger?.()?.error?.("An error has occurred while attempting to fetch SSEv2 required config", err)
      this._initialConfigFetched = false
    }
  }

  _onDisconnectFromServer = () => this.close({ closedByConsumer: false })

  async open() {
    if (!this._initialConfigFetched) {
      await this._fetchInitialConfig()
    }
    this._eventTarget = new FetchEventStream({
      config: this._config,
      logger: this._logger,
      auth: this._auth,
    })

    this._startOpenTimer()
    this._eventTarget.addEventListener("open", this._onOpen.bind(this))
    this._eventTarget.addEventListener("error", this._onError.bind(this))
    this._eventTarget.addEventListener("message", this._onMessage?.bind(this))
    this._eventTarget.addEventListener(
      FetchEventStream.PUBLIC_MESSAGES.CHANNEL_DISCONNECTED,
      this._onDisconnectFromServer.bind(this)
    )

    this._eventTarget.initialize(`${this._config().get("sse.v2.url")}/async/event-source`)

    this._startKeepAliveInterval()
  }

  _startOpenTimer() {
    this._connectionStartTime = Date.now()
    const connectionTimeoutSeconds = this._config().get("sse.v2.connection.timeoutSeconds")
    const connectionRetries = this._config().get("sse.v2.connection.retries")
    this._openAttempts += 1
    this._openTimer = setTimeout(async () => {
      if (this._openAttempts >= connectionRetries) { 
        this._logger?.()?.error?.(`SSEv2: Connection not established after ${connectionRetries} attempts`)
        this.close()
        return
      }
      this._logger?.()?.warn?.(`SSEv2: no NEW_EVENT_SOURCE message received in ${connectionTimeoutSeconds}s, attempting to reconnect...`)
      this._cleanup()
      await this.open()
    }, connectionTimeoutSeconds * 1000)
  }

  _stopOpenTimer() {
    clearTimeout(this._openTimer)
  }

  async onOpen(payload) {
    const connectionTime = Date.now() - this._connectionStartTime
    this._logger?.()?.debug?.("SSEv2: Successfully received NEW_EVENT_SOURCE message", {...payload, connectionTime})
    this._stopOpenTimer()
    this._reconnecting = false
    this._openAttempts = 0
    this._channelHash = payload.emitterHash
    this._initializeSubscriptions()
    await this._subscribeToServer()
    this._onConnect?.()
  }

  async unsubscribe(clientEventName) {
    const serverEventName = translate.eventNames[clientEventName] || clientEventName
    if (!this._serverSubscriptions?.[serverEventName]) {
      return
    }
    const { key } = this._serverSubscriptions[serverEventName]
    try {
      await this._unsubscribe({ [serverEventName]: { key } })
    } catch (err) {
      //TODO: Handle what happens when you try to unsubscribe but the event never shows up
      this._logger?.()?.error?.(`SSEv2: Error while subscribing to ${clientEventName}`, err)
    }
  }

  onUnsubscribe(payload) {
    const eventId = payload.notification
    delete this._serverSubscriptions[eventId]
    this._logger?.()?.debug?.(`SSEv2: successfully unsubscribed from ${eventId} events`)
  }

  async subscribe(clientEventName, eventParameter) {
    const serverEventName = translate.eventNames[clientEventName] || clientEventName
    try {
      await this._subscribe({ [serverEventName]: { key: eventParameter } })
    } catch (err) {
      //TODO: Handle what happens when you try to subscribe but the event never shows up
      this._logger?.()?.error?.(`SSEv2: Error while subscribing to ${clientEventName} with key ${eventParameter}`, err)
    }
  }

  onSubscribe(payload) {
    const eventId = payload.notification
    const key = payload.key
    this._serverSubscriptions[eventId] = { key }
    this._logger?.()?.debug?.(`SSEv2: successfully subscribed to ${eventId} events with key of ${key}`)
  }

  _cleanup() {
    clearInterval(this?._keepAliveInterval)
    this._keepAliveInterval = null
    this._eventTarget?.close()
    this._eventTarget = null
  }

  async close() {
    this._cleanup()
    this._openAttempts = 0
    try {
      await this._unsubscribe(this._serverSubscriptions)
      this._serverSubscriptions = null
    } catch {
      /* no-op */
    }
    try {
      this._onClose?.()
    } catch {
      /* no-op */
    }
  }

  _buildSubscriptionPayload(subscriptions) {
    return {
      emitterHash: this._channelHash,
      subscriptions: Object.entries(subscriptions).map(([notification, value]) => {
        return {
          notification,
          keys: [value.key],
        }
      }),
    }
  }

  _subscribe(subscriptions) {
    return this._dataService().SSE.subscribe(this._buildSubscriptionPayload(subscriptions))
  }

  _unsubscribe(subscriptions) {
    return this._dataService().SSE.unsubscribe(this._buildSubscriptionPayload(subscriptions))
  }

  async _subscribeToServer() {
    return this._subscribe(this._serverSubscriptions)
  }

  _initializeSubscriptions() {
    if (this._serverSubscriptions) {
      return
    }
    this._serverSubscriptions = initialEvents.reduce((final, event) => {
      return {
        ...final,
        [event]: {
          key: this._auth().user.userId,
        },
      }
    }, {})
  }

  _startKeepAliveInterval() {
    if (this._reconnecting) {
      this._onConnect?.()
      this._reconnecting = false
    }
    this._missedKeepAlives = 0
    clearInterval(this?._keepAliveInterval)
    if (!this._config().get("sse.v2.keepAlive.trackingEnabled")) {
      return
    }
    this._keepAliveInterval = setInterval(
      this._onKeepAliveMiss.bind(this),
      this._config().get("sse.v2.keepAlive.intervalSeconds") * 1000
    )
  }

  _onKeepAliveMiss() {
    this._missedKeepAlives += 1
    if (this._missedKeepAlives > this._config().get("sse.v2.keepAlive.allowedMisses")) {
      this.close()
      this._logger?.()?.error?.(
        `SSEv2: Too many keep-alive's (${this._config().get("sse.v2.keepAlive.allowedMisses")}) missed `
      )
    }
  }

  onKeepAlive() {
    this._startKeepAliveInterval()
  }

  _onOpen(event) {
    this._logger?.()?.debug?.("SSEv2: Successfully Opened", event)
  }

  _onError(error) {
    if (!this._eventTarget) {
      return
    }
    this._reconnecting = true
    this._onDisconnect?.()
    this._logger?.()?.error?.("SSEv2: An error has occurred on the SSE", {
      detail: error.detail,
      message: error.message,
      error,
    })
  }
}

module.exports = ServerConnection
