const { buildUrl, DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

class Agents extends DataServiceEndPoint {
  static key = "Agents"
  get = async ({
    page,
    limit,
    orderBy,
    orderType,
    partnerId,
    presenceGroups,
    search,
    activeQueueIds,
    roles,
    statuses = "Active",
  }) => {
    const parameters = buildUrl({
      page,
      limit,
      orderBy,
      orderType,
      partnerId,
      presenceGroups,
      search,
      activeQueueIds,
      roles,
      statuses,
    })
    return this._httpService.get(`${this.url}/v1/agents?${parameters}`, {
      headers: { Authorization: `Bearer ${this.token}` },
    })
  }
}

module.exports = Agents
