const { SSEv2 } = require("./SSEv2")

class SSEManager {
  _logger = null
  _config = null
  _dataService = null
  _v2 = null

  constructor({ logger, config, dataService, authService }) {
    this._logger = logger
    this._config = config
    this._dataService = dataService
    this._authService = authService
  }

  get v2() {
    if (!this._v2) {
      this._v2 = new SSEv2({
        configService: this._config,
        logger: this._logger,
        dataService: this._dataService,
        authService: this._authService,
      })
    }
    return this._v2
  }

  setLogger(logger) {
    this.v2?.setLogger(logger)
  }

  setConfig(config) {
    this.v2?.setConfigService(config)
  }

  setDataService(dataService) {
    this.v2?.setDataService(dataService)
  }

  _selectSSE() {
    return this.v2
  }

  open() {
    if (this._config.get("sse.version") === 2 && this.v2) {
      this.v2.open()
    }
  }

  async close(...params) {
    await this.v2?.close(...params)
    this._v2 = null
  }

  on(...params) {
    this._selectSSE(params[0])?.on(...params)
  }

  off(...params) {
    this._selectSSE(params[0])?.off(...params)
  }

  subscribe(...params) {
    this._selectSSE(params[0])?.subscribe(...params)
  }

  unsubscribe(...params) {
    this._selectSSE(params[0])?.unsubscribe(...params)
  }

  reconnect(...params) {
    this.v2?.reconnect(...params)
  }

  abort(...params) {
    this.v2?.abort(...params)
  }
}

module.exports = SSEManager
