import { useCallback, useEffect, useState } from "react"
import { dataService } from "utils/dataService"
import { logger } from "utils/logger"

export const useHospitalData = (hospitalId) => {
  const [hospital, setHospital] = useState(null)

  useEffect(() => {
    const getPartners = async () => {
      try {
        const response = await dataService.Partners.get({ hospitalId })
        const hospital = response?.data
        logger.debug("Successfully retrieved call hospital data:", hospital, {departments: hospital?.departments})
        setHospital(hospital)
      } catch (err) {
        logger.error("An error occurred while fetching hospital data", err)
      }
    }
    if (hospitalId) {
      getPartners()
    } else {
      setHospital(null)
    }
  }, [hospitalId])

  const getDepartments = useCallback(() => {
    if (hospital?.departments) {
      return hospital.departments
    }
    return null
  }, [hospital])

  return {
    hospital,
    getDepartments,
  }
}
