import React, { useEffect, useMemo } from "react"
import cs from "classnames"
import Icon from "@cloudbreakus/cloudbreak-react-icon"
import Select from "../Inputs/Select"

const totalPages = (totalResults, limit) => Math.floor(totalResults / limit) + (totalResults % limit ? 1 : 0)
const defaultPaginationOptions = [5, 10, 20, 50]

/**
 * @typedef {Object} Arguments
 * @property {String | Array<String | Object> | Object} className - classnames package valid value
 * @property {String} data-testid - used in testing for targeting sub-components (ie. name-results-per-page)
 * @property {String} theme - the key for the style the component will use
 * @property {Array<number>} options - array containing the different number of results per page
 * @property {number} totalResults - total number of results (including those not in this page)
 * @property {number} results - number results in the current page
 * @property {number} page (required) - number corresponding to the selected page
 * @property {function(number): void} onPage - this function selects the given page number
 * @property {function(number): void} onLimit - this function selects the given number of results per page
 * @property {number} limit - the currently selected number of results per page
 */

/**
 * Paginator Component for navigating pagination options
 *
 * @param {Arguments} arguments Object (described in {@link Arguments})
 */
const Paginator = ({
  className,
  "data-testid": testId,
  theme,
  options = defaultPaginationOptions,
  results,
  totalResults,
  page,
  onPage,
  limit,
  onLimit,
}) => {
  const pages = useMemo(() => totalPages(totalResults, limit), [limit, totalResults])
  const shownPages = useMemo(() => {
    const allPages = [...Array(pages || 1).keys()]
    if (pages < 25) {
      return allPages
    }
    if (page > pages - 7 || page < 8) {
      return [...allPages.slice(0, 10), "...", ...allPages.slice(pages - 10)]
    }
    if (page > pages - 10) {
      return [...allPages.slice(0, 8), "...", ...allPages.slice(pages - 12)]
    }
    if (page < 11) {
      return [...allPages.slice(0, 12), "...", ...allPages.slice(pages - 8)]
    }
    return [...allPages.slice(0, 6), "...", ...allPages.slice(page - 4, page + 3), "...", ...allPages.slice(pages - 6)]
  }, [pages, page])

  const _onLimit = (_limit) => {
    const expectedPages = totalPages(totalResults, _limit)
    if (page > expectedPages) {
      onPage(expectedPages)
    }
    onLimit(_limit)
  }

  const _onPage = (target, nextPage) => {
    target?.blur()
    const _nextPage = Math.max(1, Math.min(nextPage, pages))
    onPage(_nextPage)
  }

  useEffect(() => {
    if (pages && page > pages && totalResults) {
      onPage(pages)
    }
  }, [page, pages, onPage, totalResults])

  return totalResults ? (
    <div className={cs("flex flex-col relative xl:order-last", className)} data-testid={testId}>
      <div className="flex flex-row items-end w-full">
        <div className="flex items-center">
          <div className="text-[#667085] h-fit pr-2">Showing:</div>
          <div data-testid="select-results-per-page">
            {options.map((option) => (
              <button
                key={`showing-option-${option}`}
                className={cs(
                  "w-8 h-8 rounded-md border-transparent",
                  `${option}` === `${limit}`
                    ? "bg-[#EFF9FF] text-[#146f9d] cursor-default"
                    : "border-[1px] hover:border-gray-300 focus:border-gray-300 text-[#667085]"
                )}
                onClick={() => _onLimit(`${option}`)}
              >
                {option}
              </button>
            ))}
          </div>
        </div>
        <div className="flex-grow" />
        <div className="h-7 pr-2 text-[#667085]">
          Results: {(page - 1) * limit + 1} -{" "}
          {Math.min((page - 1) * limit + (page === pages ? results : limit), totalResults)} of {totalResults}
        </div>
      </div>
      <div className="flex flex-row items-end w-full xl:w-4/6 xl:absolute xl:order-first bottom-0 inset-x-0 mx-auto">
        <div className="flex flex-grow place-content-center ">
          <Icon
            icon="chevronUp"
            data-testid="previous-page"
            className={cs(
              "rotate-[270deg] w-8 h-8 p-1 rounded-md border-transparent mr-3",
              page === 1
                ? "stroke-gray-200"
                : "border-[1px] hover:border-gray-300 focus:border-gray-300 stroke-slate-500"
            )}
            onClick={(e) => _onPage(e?.currentTarget, page - 1)}
            disabled={page === 1}
            aria-label="previous page icon"
          />
          {shownPages.map((p, i) => (
            <button
              key={`${p}${i}`}
              className={cs(
                "w-8 h-8 rounded-md border-transparent ",
                p === "..."
                  ? "text-[#667085]"
                  : p + 1 === page
                  ? "bg-[#EFF9FF] text-[#146f9d] cursor-default"
                  : "border-[1px] hover:border-gray-300 focus:border-gray-300 text-[#667085]"
              )}
              disabled={p === "..."}
              onClick={() => onPage(p + 1)}
            >
              {p === "..." ? "..." : p + 1}
            </button>
          ))}
          <Icon
            icon="chevronUp"
            data-testid="next-page"
            className={cs(
              "rotate-[90deg] w-8 h-8 p-1 rounded-md border border-transparent ml-3",
              page >= pages
                ? "stroke-gray-200"
                : "border-[1px] hover:border-gray-300 focus:border-gray-300 stroke-slate-500"
            )}
            onClick={(e) => _onPage(e?.currentTarget, page + 1)}
            disabled={page >= pages}
            aria-label="next page button"
          />
        </div>
      </div>
    </div>
  ) : null
}

export default Paginator
