import React, { createContext, useCallback, useEffect, useState } from "react"
import { useAuth } from "contexts/Auth"
import { SSE } from "utils/SSE"
import { logger } from "utils/logger"
import { dataService } from "utils/dataService"
import { useAlertMessage } from "hooks/useAlertMessage"
import { createContextHook } from "utils/contextHelpers"
import { useMFANotification } from "./useMfaNotification"

const ProfileContext = createContext()

export const useProfile = createContextHook(ProfileContext, "useProfile must be used within a ProfileProvider")

export const ProfileProvider = ({ children }) => {
  const { userData, SSEopen, isAuthenticated, hasOptedInMFA } = useAuth()
  const [profile, setProfile] = useState()
  const [additionalUserData, setAdditionalUserData] = useState()
  const [error, setError] = useState()
  const { showErrorToast, showCustomToast } = useAlertMessage()

  const getProfile = useCallback(async (agentId) => {
    try {
      const response = await dataService.Profile.get({ agentId })
      const data = response?.data
      const { firstName, lastName, phoneNumber, email, role, ...rest } = data
      logger.debug(`Successfully retrieved user profile for agent "${agentId}"`, data)
      setProfile({
        firstName,
        lastName,
        phoneNumber,
        email,
        role,
      })
      setAdditionalUserData(rest)
    } catch (err) {
      logger.error(`An error occurred while fetching profile for agent "${agentId}"`, err)
      setError(err)
      showCustomToast({
        message: `Critical application error: Unable to fetch user profile data.`,
        level: "error",
        onRetry: () => getProfile(agentId),
      })
    }
  }, [showCustomToast])

  const updateProfile = useCallback(
    async (payload) => {
      try {
        const { userId } = userData
        const data = {
          agentId: userId,
          data: {
            ...profile,
            ...additionalUserData,
            ...payload,
          },
        }
        await dataService.Profile.update(data)
        logger.debug("Successfully updated user profile with payload:", data)
        setProfile((data) => ({
          ...data,
          ...payload,
        }))
      } catch (err) {
        showErrorToast("Unable to save user profile, please try again.")
        logger.error("An error occurred while updating the user's profile data", err)
        setError(err)
      }
  }, [additionalUserData, profile, userData, showErrorToast])

  useEffect(() => {
    if (!SSEopen || !userData?.userId) {
      return
    }
    const onConnect = (connected) => (connected ? getProfile(userData.userId) : null)
    SSE.on("profile", getProfile)
    SSE.on("connect", onConnect)

    return () => {
      SSE.off("profile", getProfile)
      SSE.off("connect", onConnect)
    }
  }, [SSEopen, getProfile, userData?.userId])

  useEffect(() => {
    if (additionalUserData?.name) {
      logger.addAttribute("User Name", additionalUserData.name)
    }
  }, [additionalUserData])


  useMFANotification({
    hasOptedInMFA: additionalUserData?.hasMfaEnabled || hasOptedInMFA,
    isAuthenticated: Boolean(isAuthenticated && additionalUserData),
  })

  return (
    <ProfileContext.Provider
      value={{
        profile,
        additionalUserData,
        updateProfile,
        error,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}

export default ProfileContext
