import React from "react"
import cs from "classnames"

const CallSheetFormSection = ({ title, children, className }) => {
  return (
    <div className={cs("py-2", "flex", "flex-col", className)}>
      <span className={cs("w-full", "px-2", "text-left", "text-gray-600", "flex", "font-semibold")}>{title}</span>
      <div className={cs("w-full", "inline-flex", "flex-row", "flex-wrap")}>{children}</div>
    </div>
  )
}

export default CallSheetFormSection
