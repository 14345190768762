const { DTO } = require("@cloudbreakus/network-utilities")

class AgentUpdateModel extends DTO {
  requiredFields = ["phoneNumber"]
  optionalFields = [
    "firstName",
    "lastName",
    "fullName",
    "email",
    "profilePhotoDocument",
    "status",
    "role",
    "available",
    "external",
    "allowQueueActivation",
    "unregistered",
    "birthDate",
    "createTime",
    "queues",
    "aslLicensureStates",
  ]

  toPresentationalObject({ phoneNumber }) {
    return {
      phoneNumber,
    }
  }

  acceptNulls = true
}

module.exports = AgentUpdateModel
