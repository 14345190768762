import { useValidation, VALIDATION_FAILURES } from "hooks/useValidation"
import { useCallback, useEffect, useRef, useState } from "react"

export const useCallsheetInputValidation = ({ maxLength, value, validation, onKeyDown, onPaste }) => {
  const [error, setError] = useState(null)
  const lastValidatedValue = useRef(null)

  const handleValidationFailure = useCallback(
    (reason) => {
      if (reason === VALIDATION_FAILURES.LENGTH) {
        setError(`Maximum length is ${maxLength} characters`)
      } else if (reason === VALIDATION_FAILURES.REQUIRED) {
        setError("This field is required")
      } else {
        setError(null)
      }
    },
    [maxLength]
  )

  useEffect(() => {
    if (validation?.onChange && lastValidatedValue.current !== value) {
      lastValidatedValue.current = value
      handleValidationFailure(validation.onChange?.({ target: { value } }))
    }
  }, [handleValidationFailure, validation, value])

  const { onKeyDownWithValidation, onPasteWithValidation } = useValidation(validation, handleValidationFailure)

  const onKeyDownValidated = onKeyDownWithValidation(onKeyDown)
  const onPasteValidated = onPasteWithValidation(onPaste)

  return {
    error,
    onKeyDownValidated,
    onPasteValidated,
  }
}
