export const INTERPRETER = "INTERPRETER"
export const OPERATOR = "OPERATOR"
export const SUPERVISOR = "SUPERVISOR"

export const ROLE_WEIGHTS = {
  INTERPRETER: 0,
  OPERATOR: 1,
  SUPERVISOR: 2,
}

export default { INTERPRETER, OPERATOR, SUPERVISOR, weights: ROLE_WEIGHTS }
