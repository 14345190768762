import { useEffect, useCallback, useState } from "react"
import useStore from "store"
import shallow from "zustand/shallow"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { useAuth } from "contexts/Auth"
import { SSE } from "utils/SSE"
import { logger } from "utils/logger"
import { dataService } from "utils/dataService"
import { selectRingingCall, selectExpectingAnswer } from "store/selectors/call"
import { ACD_ASSIGNED, RECONNECTING } from "../presences"

const secondsSince = (t) => Math.abs(Date.parse(t) - Date.parse(new Date())) / 1000

export const usePresenceRefresh = ({assertPresence, setExpectingPresence, existingPresenceName, since}) => {
  const [ringingCall] = useStore(selectRingingCall, shallow)
  const [expectingAnswer] = useStore(selectExpectingAnswer, shallow)
  const { isAuthenticated, userData, isTokenDead, SSEopen } = useAuth()
  const enabled = useConfigFlag("presence.autoRefresh.enable")
  const fetchIntervalSeconds = useConfigFlag("presence.autoRefresh.intervalSeconds")
  const minimumDelaySeconds = useConfigFlag("presence.autoRefresh.minimumDelaySeconds")
  const [fetchedPresence, setFetchedPresence] = useState(null)
  const [SSEhasDied, setSSEhasDied] = useState(false)

  const fetchPresence = useCallback(async () => {
    try {
      const { data } = await dataService.Presence.get(userData.userId)
      setFetchedPresence({name: data.presence, since: data.startTime})
    } catch (err) {
      logger.error("Presence refresher: fetch/parse error", err)
    }
  }, [userData?.userId])

  useEffect(() => {
    if (!fetchedPresence || SSEhasDied || !isAuthenticated) {
      return
    }
    const fetchedName = fetchedPresence.name
    setFetchedPresence(null)
    const secondsSinceLastPresenceChange = secondsSince(since)
    if (secondsSinceLastPresenceChange < minimumDelaySeconds) {
      logger.debug(`Presence refresher: ignoring ${fetchedName} since it changed too recently (${secondsSinceLastPresenceChange}s)`)
      return
    }
    setExpectingPresence(false)
    if (fetchedName === existingPresenceName) {
      logger.debug(`Presence refresher: ignoring ${fetchedName} since it matches the pre-existing presence`)
      return
    }
    const callAssigned = ringingCall || expectingAnswer
    if (existingPresenceName === ACD_ASSIGNED && callAssigned) {
      logger.debug(`Presence refresher: ignoring presence of ${fetchedName} since current presence is ${ACD_ASSIGNED} while call is ringing`)
      return
    }
    if (existingPresenceName === RECONNECTING) {
      logger.debug(`Presence refresher: ignoring presence of ${fetchedName} since current presence is ${RECONNECTING}`)
      return
    }
    assertPresence({
      presenceName: fetchedPresence.name,
      startTime: fetchedPresence.since,
    })
    logger.warn(`Presence refresher: Fetched presence of ${fetchedPresence?.name} differs from pre-existing value of ${existingPresenceName}`)
  }, [
    fetchedPresence,
    existingPresenceName,
    since,
    assertPresence,
    setExpectingPresence,
    ringingCall,
    expectingAnswer,
    minimumDelaySeconds,
    SSEhasDied,
    isAuthenticated,
  ])

  useEffect(() => {
    if (!enabled || SSEhasDied || isTokenDead || !userData?.userId) {
      return
    }

    const interval = setInterval(fetchPresence, fetchIntervalSeconds * 1_000)
    return () => clearInterval(interval)
  }, [enabled, fetchIntervalSeconds, fetchPresence, SSEhasDied, isTokenDead, userData?.userId])

  useEffect(() => {
    if (!SSEopen) {
      return
    }
    SSE.on("logout", setSSEhasDied)

    return () => {
      SSE.off("logout", setSSEhasDied)
    }
  }, [SSEopen])

  return null
}
