import React from "react"
import { Input, Select, Calendar, CheckBox } from "components/Inputs"
import { useCallsheetInputValidation } from "../hooks/useCallsheetInputValidation"

const WrappedInput = ({ name, type = "input", options = [], validation, maxLength, className = "w-1/2", ...rest }) => {
  const sharedProps = {
    className,
    name: `call-sheet-${name}`,
    theme: "callSheet",
    id: name,
    maxLength,
    ...rest,
  }

  const { error, onKeyDownValidated, onPasteValidated } = useCallsheetInputValidation({
    maxLength,
    validation,
    value: rest?.value,
    onKeyDown: rest?.onKeyDown,
    onPaste: rest?.onPaste,
  })

  return type === "select" ? (
    <Select {...sharedProps} options={options} error={error} />
  ) : type === "calendar" ? (
    <Calendar {...sharedProps} error={error} />
  ) : type === "checkbox" ? (
    <CheckBox {...sharedProps} error={error} />
  ) : (
    <Input
      {...sharedProps}
      type="text"
      label={`${rest?.label} ${maxLength ? `(${rest?.value ? rest?.value?.length : 0}/${maxLength})` : ""}`}
      error={error}
      onKeyDown={onKeyDownValidated}
      onPaste={onPasteValidated}
    />
  )
}

export default WrappedInput
