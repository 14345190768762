import { dataService } from "utils/dataService"
import { renameQueues } from "utils/helpers"

const agentsSlice = {
  agents: [],
}

const createAgentsSlice = (set) => ({
  ...agentsSlice,
  getAgents: async (params) => {
    const { data } = await dataService.Agents.get(params)
    const results = data?.results?.map(agent => {
      const queues = agent?.activeQueues?.map(renameQueues)
      return {
        ...agent,
        activeQueues: queues
      }
    })
    const agents = {
      ...data,
      results
    }
    set({ agents })
    return agents
  },
})

export default createAgentsSlice
