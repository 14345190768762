import { useCallback, useEffect, useMemo, useState } from "react"
import { SSE } from "utils/SSE"
import { logger } from "utils/logger"
import { dataService } from "utils/dataService"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { selectParticipants } from "store/selectors/participants"
import { transformParticipants } from "martti-agent-services"
import useStore from "store"
import { useInterval } from 'usehooks-ts'

export const useCallParticipants = ({ interactionId, webRTCerror }) => {
  const callScreen = useConfigFlag("participantManagement.callScreen")
  const callHandling = useConfigFlag("participantManagement.callHandling")
  const emptyParticipantsFetches = useConfigFlag("participantManagement.fetchIntervalRepetitions", 5)
  const emptyParticipantsRefreshSeconds = useConfigFlag("participantManagement.fetchIntervalSeconds", 1)
  const enableRefresh = !!emptyParticipantsRefreshSeconds

  const enabled = useMemo(() => callScreen || callHandling, [callScreen, callHandling])
  const [participants, _setParticipants] = useStore(selectParticipants)
  const [emptyParticipants, setEmptyParticipants] = useState(true)
  const [refreshing, setRefreshing] = useState(false)
  const [fetchAttempts, setFetchAttempts] = useState(0)

  const alreadyFetched = useMemo(
    () => !emptyParticipants || fetchAttempts >= emptyParticipantsFetches,
    [emptyParticipants, fetchAttempts, emptyParticipantsFetches]
  )
  const fetching = useMemo(
    //TODO: improve readability: fetching becomes falsy sooner than reading this would imply which results in a button being clickable
    () => (emptyParticipants && fetchAttempts < emptyParticipantsFetches) || refreshing,
    [emptyParticipants, fetchAttempts, refreshing, emptyParticipantsFetches]
  )

  const setParticipants = useCallback(
    (newParticipants) =>
      // Creates a new array everytime even if newParticipants is falsy, have a look if you depend on participants for effects
      _setParticipants((existingParticipants) => {
        const existingStartTimes = existingParticipants.reduce(
          (accumulator, { id, startTime }) => ({ ...accumulator, [id]: startTime }),
          {}
        )
        let participants = []
        newParticipants?.forEach((newParticipant) => {
          let participant = { ...newParticipant }
          if (existingStartTimes[newParticipant.id]) {
            participant.startTime = existingStartTimes[newParticipant.id]
          }
          participants.push(participant)
        })
        return participants.sort((a, b) => Date.parse(a?.startTime) - Date.parse(b?.startTime))
      }),
    [_setParticipants]
  )

  const fetchParticipants = useCallback(
    async (connected) => {
      setRefreshing(true)
      if (connected && interactionId) {
        try {
          const _fetchedParticipants = await dataService.Interactions.Participants.get({ interactionId })
          const fetchedParticipants = _fetchedParticipants.map(transformParticipants)
          logger.debug("Call participants retrieved (processed / raw)", _fetchedParticipants, fetchedParticipants)
          setEmptyParticipants(!fetchedParticipants.length)
          setParticipants(fetchedParticipants)
        } catch (err) {
          logger.error("Call participants retrieval failed", err)
        }
      }
      setRefreshing(false)
      setFetchAttempts((attempts) => attempts + 1)
    },
    [interactionId, setParticipants]
  )

  const preProcessParticipants = useCallback(
    (participants) => {
      setEmptyParticipants(!participants)
      return setParticipants(participants)
    },
    [setParticipants]
  )

  const shouldFetchParticipants = Boolean(
    interactionId && enabled && enableRefresh && emptyParticipants && !alreadyFetched && !webRTCerror
  )
  useInterval(
    () => {
      if (refreshing) {
        return
      }
      fetchParticipants(true)
    },
    shouldFetchParticipants ? emptyParticipantsRefreshSeconds * 1_000 : null
  )

  useEffect(() => {
    if (!interactionId || !enabled) {
      setEmptyParticipants(true)
      setParticipants([])
      return
    }

    SSE.on("call-participants", preProcessParticipants)
    SSE.on("connect", fetchParticipants)
    SSE.subscribe("call-participants", interactionId)
    return () => {
      SSE.off("call-participants", setParticipants)
      SSE.off("connect", fetchParticipants)
      SSE.unsubscribe("call-participants")
    }
  }, [interactionId, enabled, fetchParticipants, setParticipants, preProcessParticipants])

  return { participants, fetch: () => fetchParticipants(true), fetching, refreshing }
}
