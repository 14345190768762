import React, { useMemo, useState, useCallback, useEffect, useRef, memo } from "react"
import Autosuggest from "react-autosuggest"
import cs from "classnames"
import { useEventWrapper } from "../useEventWrapper"
import * as StyleSheet from "../styles"
import Wrapper from "../Wrapper"
import "./AutoComplete.css"
import Icon from "@cloudbreakus/cloudbreak-react-icon"

/*
For detailed API usage, see https://react-autosuggest.js.org/
*/

const usePrevious = (value) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export const AutoCompleteOption = ({ value, text, isHighlighted, multiSection }) => (
  <div
    key={value}
    data-testid={`autocomplete-option-${value}`}
    className={cs(
      "text-left cursor-pointer hover:bg-gray-100",
      { "bg-gray-100": isHighlighted },
      { "pl-4": multiSection }
    )}
  >
    {text}
  </div>
)

export const SectionTitle = ({ title, firstTitle }) => (
  <div className={cs("text-left text-xs border-dashed", { "border-t-2": firstTitle !== title })}>{title}</div>
)

export const getSectionSuggestions = (section) => section?.options

/**
 * @param {SectionOptions} options
 * @returns {Array<SectionList>}
 */
export const sectionize = (options) => {
  // Reduces our section structures [key]: { section, name } to [section]: [{ key, name}, ...]
  const _options = Object.entries(options)
    .sort(([, a]) => (a.priority ? -1 : 0))
    .reduce(
      (opts, [key, { name, section }]) => ({
        ...opts,
        [section]: [...(opts[section] || []), { value: key, text: name }],
      }),
      {}
    )
  return Object.entries(_options).map(([section, subOptions]) => ({ section, options: subOptions }))
}

export const findOptByValue = (opts, value) =>
  opts.find((opt) => opt?.value === value) ||
  opts
    .reduce((accumulator, { options }) => [...accumulator, ...(options || [])], [])
    ?.find((option) => option?.value === value)

export const defaultFilter = (options, value, multiSection) => {
  const inputValue = value?.trim().toLowerCase()
  if (multiSection) {
    return options
      .map(({ section, options }) => ({
        section,
        options: options.filter(({ text }) => text?.toLowerCase().indexOf(inputValue) > -1),
      }))
      .filter((section) => section.options.length)
  }
  return inputValue?.length > 0 ? options.filter((opt) => opt?.text?.toLowerCase().indexOf(inputValue) > -1) : options
}

const AutoComplete = ({
  name,
  label = "",
  value,
  options = [],
  onChange = () => null,
  onRefresh,
  refreshing,
  refreshLabel,
  disabled = false,
  className,
  theme = "",
  filters = null,
}) => {
  const [text, setText] = useState("")
  const [selected, setSelected] = useState(value)
  const [settledOptions, setSettledOptions] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  const previousValue = usePrevious(value)

  const styleSheet = StyleSheet[theme]?.(disabled) || StyleSheet["callSheet"](disabled)

  const multiSection = useMemo(
    () => options && new Set(Object.entries(options).map(([, { section }]) => section)).size > 1,
    [options]
  )

  const optionsMapped = useMemo(() => {
    if (!options) {
      return []
    }
    if (multiSection) {
      return sectionize(options)
    }
    return Array.isArray(options)
      ? options?.map((option) =>
          typeof option === "object" ? { value: option?.value, text: option?.text } : { value: option, text: option }
        )
      : Object.entries(options).map(([key, val]) => ({
          value: key,
          text: typeof val === "object" ? val.text || val.name : val,
        }))
  }, [options, multiSection])

  const onText = useEventWrapper(setText)

  const onSelect = useCallback(
    (_, { suggestion }, silent = false) => {
      setSelected(suggestion.value)
      setText(suggestion.text)
      if (!silent) {
        onChange(suggestion.value)
      }
    },
    [onChange]
  )

  useEffect(() => {
    if (optionsMapped?.length > 0 && !settledOptions) {
      const alreadySelectedValue = findOptByValue(optionsMapped, value)
      if (alreadySelectedValue) {
        setSelected(alreadySelectedValue.value)
        setText(alreadySelectedValue.text)
      }
      setSuggestions(optionsMapped)
      setSettledOptions(true)
    }
  }, [optionsMapped, settledOptions, value])

  const transitioning = useMemo(
    () => suggestions?.length && !!suggestions?.[0]?.section !== !!optionsMapped?.[0]?.section,
    [suggestions, optionsMapped]
  )

  useEffect(() => {
    if (transitioning) {
      setSettledOptions(false)
    }
  }, [transitioning])

  useEffect(() => {
    if (value !== previousValue && value !== selected) {
      const opt = findOptByValue(optionsMapped, value)
      if (opt) {
        onSelect(null, { suggestion: opt }, true)
      }
    }
  }, [onSelect, optionsMapped, previousValue, selected, value])

  const onOptionsFetch = useCallback(
    ({ value, reason }) => {
      if (reason === "input-focused") {
        return
      }
      setSuggestions(
        filters ? filters(optionsMapped, value, multiSection) : defaultFilter(optionsMapped, value, multiSection)
      )
    },
    [filters, optionsMapped, multiSection]
  )

  const onClear = useCallback(() => {
    setSuggestions(optionsMapped)
  }, [optionsMapped])

  const getSuggestionValue = useCallback((s) => {
    return s.text
  }, [])

  return (
    <Wrapper
      label={label}
      className={cs(className)}
      name={`autocomplete-${name}`}
      styleSheet={styleSheet}
      onRefresh={onRefresh}
      refreshing={refreshing}
      refreshLabel={refreshLabel}
    >
      {!transitioning && (
        <>
          <Autosuggest
            id={`autosuggest-${name}`}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onOptionsFetch}
            onSuggestionsClearRequested={onClear}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={({ value, text }, { isHighlighted }) => (
              <AutoCompleteOption value={value} text={text} isHighlighted={isHighlighted} multiSection={multiSection} />
            )}
            multiSection={multiSection}
            renderSectionTitle={({ section }) => (
              <SectionTitle title={section} firstTitle={suggestions?.[0]?.section} />
            )}
            getSectionSuggestions={getSectionSuggestions}
            inputProps={{
              className: cs(styleSheet.input, "form-input"),
              disabled,
              value: text ?? "",
              onChange: onText,
              onFocus: (evt) => {
                evt?.target?.select()
              },
              onClick: (evt) => {
                evt?.target?.blur()
                evt?.target?.focus()
              },
              "data-testid": `autocomplete-input-${name}`,
            }}
            onSuggestionSelected={onSelect}
            shouldRenderSuggestions={() => true}
            //TODO: consider focusInputOnSuggestionClick={false} when on mobile
          />
          <div className="relative pointer-events-none">
            <div className="absolute right-2 -top-[23px] flex flex-row">
              <Icon icon="chevronUp" size={16} className="rotate-180 stroke-icon" />
            </div>
          </div>
        </>
      )}
    </Wrapper>
  )
}

export default memo(AutoComplete)

/**
 * A section in a multisection autocomplete row
 * @typedef {Object} SectionDefinition
 * @property {string} name - Display name of the row.
 * @property {string} section - Name of the section it belongs to.
 * @property {boolean} priority - Whether or not it should go first on the list. Make it true for the first section only
 */

/**
 * AutoWhatever section definition
 * @typedef {Object} SectionList
 * @property {string} section - Name of the section it belongs to.
 * @property {Object} options - Autowhatever actual options, just value and text
 */

/**
 * @typedef {Object.<string, SectionDefinition>} SectionOptions
 */
