import { reactLikeState } from "store/utils"

const emptyQueues = {
  available: [],
  active: [],
  all: [],
  supervisor: [],
}

const queueSlice = {
  queues: emptyQueues,
  error: null,
}

const createQueueSlice = (set) => {
  const setQueues = reactLikeState(set, ["queues"])
  return {
    ...queueSlice,
    setQueues,
    setError: reactLikeState(set, ["error"]),
    resetQueues: () => setQueues(emptyQueues),
  }
}

export default createQueueSlice
