import { useCallback, useEffect, useState } from "react"
import { dataService } from "utils/dataService"
import { getLoggerInstance } from "utils/logger"

const Logger = getLoggerInstance()

export const useMarttiData = (unitId) => {
  const [marttiData, setMarttiData] = useState(null)

  useEffect(() => {
    const getMarttiData = async () => {
      try {
        const response = await dataService.Martti.get({
          marttiId: unitId,
        })
        const martti = response?.data
        Logger.debug("Successfully retrieved call martti data:", martti)
        setMarttiData(martti)
      } catch (err) {
        Logger.error("An error occurred while fetching martti data", err)
      }
    }
    if (unitId) {
      getMarttiData()
    } else {
      setMarttiData(null)
    }
  }, [unitId])
  
  const getMartti = useCallback(() => {
    if (marttiData) {
      return marttiData
    }
    return null
  }, [marttiData])

  return {
    martti: marttiData,
    getMartti,
  }
}
