import React, { useEffect } from "react"
import { inAppMessaging } from "utils/messaging"
import useInAppMessaging from "hooks/useInAppMessaging"
import { logger } from "utils/logger"
import { onInitialSetup } from "./utils"
import CallFormContainer from "./components/CallForm"
import cs from "classnames"

const screenKey = inAppMessaging.screens.CALL_SHEET

const onCallSheetData = (event) => {
  logger.warn("DATA", event)
}

const CallSupportWindow = () => {
  const { send, onEvent } = useInAppMessaging(true, screenKey)

  useEffect(() => {
    const initialUnsubscribe = onEvent(inAppMessaging.messages[screenKey].INITIAL_SETUP, onInitialSetup)
    const callSheetDataUnsubscribe = onEvent(inAppMessaging.messages[screenKey].CALL_SHEET_DATA, onCallSheetData)
    return () => {
      send(inAppMessaging.messages.CLOSED, {
        screenKey,
      })
      initialUnsubscribe()
      callSheetDataUnsubscribe()
    }
  }, [onEvent, send])

  return (
    <div className={cs("p-4 bg-bblue-50", "min-h-screen", "max-w-screen")}>
      <p>Call Support window</p>
      <CallFormContainer />
    </div>
  )
}

export default CallSupportWindow
