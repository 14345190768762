import { reactLikeState } from "store/utils"

const callParticipantSlice = {
  participants: [],
  error: null,
}

const createParticipantSlice = (set) => {
  const setParticipants = reactLikeState(set, ["participants"])
  return {
    ...callParticipantSlice,
    setParticipants,
  }
}

export default createParticipantSlice
