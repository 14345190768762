export const profile = (disabled, error) => ({
  root: ["w-1/2"],
  label: [error ? "text-red-500" : "text-gray-500", "font-semibold"],
  input: [
    "block",
    "mt-0",
    "w-full",
    "focus:ring-0",
    "border-0 border-b-2",
    error ? "focus:border-red-700" : "focus:border-gray-700",
    error ? "border-red-200" : "border-gray-200",
    disabled ? "bg-gray-100 text-gray-400" : "bg-white",
  ],
  selectors: [
    `
    mr-2
    h-6
    w-6
    rounded
    focus:border-[#72a9c4] focus:ring focus:ring-offset-0 focus:ring-[#a1c5d8] focus:ring-opacity-50
    border-gray-400
    text-[#146f9d]
  `,
    disabled ? "bg-gray-100 text-gray-400" : "text-[#146f9d]",
  ],
})

export const callSheet = (disabled, error) => ({
  root: ["p-2", "pb-0"],
  label: [error ? "text-red-500" : "text-gray-700", "font-normal"],
  input: [
    "block",
    "mt-1",
    "px-2",
    "h-8",
    "w-full",
    "rounded",
    "focus:ring focus:ring-offset-0  focus:ring-opacity-50",
    error ? "focus:border-red-300 focus:ring-red-200" : "focus:border-[#72a9c4] focus:ring-[#a1c5d8]",
    error ? "border-[2px] border-red-400" : "border-[1px] border-gray-400",
    disabled ? "bg-gray-100 text-gray-400" : "bg-white",
  ],
  selectors: [
    `
    mr-2
    h-6
    w-6
    rounded
    focus:border-[#72a9c4] focus:ring focus:ring-offset-0 focus:ring-[#a1c5d8] focus:ring-opacity-50
    border-gray-400
  `,
    disabled ? "bg-gray-100 text-gray-400" : "text-[#146f9d]",
  ],
})

export const agents = (disabled, error, value) => ({
  root: [
    "[&_.searchBox]:text-ellipsis [&_.searchBox]:w-full [&_.searchBox]:pr-8 [&_.searchBox]:cursor-pointer",
    "[&_.searchWrapper]:border-gray-400 [&_.searchWrapper]:cursor-pointer relative",
    "[&_.searchWrapper]:focus-within:ring [&_.searchWrapper]:focus-within:ring-offset-0 [&_.searchWrapper]:focus-within:ring-opacity-50 [&_.searchWrapper]:focus-within:border-[#72a9c4] [&_.searchWrapper]:focus-within:ring-[#a1c5d8]",
  ],
  label: [error ? "text-red-500" : "text-gray-700", "font-normal"],
  input: [
    "block",
    "px-2",
    "h-[39px]",
    "w-full",
    "rounded",
    { italic: !value },
    "focus:ring focus:ring-offset-0  focus:ring-opacity-50",
    error ? "focus:border-red-300 focus:ring-red-200" : "focus:border-[#72a9c4] focus:ring-[#a1c5d8]",
    error ? "border-[2px] border-red-400" : "border-[1px] border-gray-400",
    disabled ? "bg-gray-100 text-gray-400" : "bg-white",
  ],
  selectors: [
    `
    mr-2
    h-6
    w-6
    rounded
    focus:border-[#72a9c4] focus:ring focus:ring-offset-0 focus:ring-[#a1c5d8] focus:ring-opacity-50
    border-gray-400
  `,
    disabled ? "bg-gray-100 text-gray-400" : "text-[#146f9d]",
  ],
})

export const currentCalls = callSheet
