import React from "react"
import cs from "classnames"

const CardHeader = ({ className, tabs, selected, onSelectTab }) => {
  return (
    <div className={cs("flex", "flex-row", "border-b-[1px]", "border-gray-300", "bg-white", className)}>
      {tabs &&
        tabs.map((tab, index) => {
          const key = typeof tab === "object" ? tab.key : tab
          return (
            <button
              key={index}
              data-testid={`button-card-header-${key}`}
              className={cs(
                "shadow-md rounded",
                "font-bold",
                "py-2",
                "px-4",
                "w-fit",
                "h-full",
                "flex",
                "items-center",
                "justify-center",
                "rounded-none",
                "shadow-none",
                "uppercase",
                "text-sm",
                "hover:bg-gray-100 hover:text-black focus:outline-none focus:bg-gray-100 focus:text-black",
                selected === key ? "border-b-4 border-bblue-500 text-black" : "text-gray-400"
              )}
              onClick={() => onSelectTab(key)}
            >
              <span>{typeof tab === "object" ? tab.title : tab}</span>
            </button>
          )
        })}
    </div>
  )
}

export default CardHeader
