import create from "zustand"
import createAuthSlice from "./slices/AuthSlice"
import createCallSlice from "./slices/CallSlice"
import createCurrentCallsSlice from "./slices/CurrentCallsSlice"
import createAgentsSlice from "./slices/AgentsSlice"
import { createOutsourceSlice } from "./slices/OutsourceSlice"
import createQueueSlice from "./slices/QueueSlice"
import createParticipantSlice from "./slices/CallParticipantsSlice"
import createAccessCodeSlice from "./slices/AccessCodeSlice"

// For persistence https://github.com/pmndrs/zustand#persist-middleware

// Slice pattern https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#slices-pattern
// In order to solve the namespacing issue as mentioned here https://github.com/pmndrs/zustand/issues/178 - this is a proposal https://github.com/dhmk083/dhmk-zustand-lens
// We could also implement it manually ourselves, fairly trivial

const useStore = create((set, get) => ({
  ...createOutsourceSlice(set, get),
  ...createCallSlice(set, get),
  ...createAuthSlice(set, get),
  ...createAgentsSlice(set, get),
  ...createCurrentCallsSlice(set, get),
  ...createQueueSlice(set, get),
  ...createParticipantSlice(set, get),
  ...createAccessCodeSlice(set, get),
}))

export default useStore
