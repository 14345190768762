import { getLoggerInstance } from "./utils/logger"

export function register() {
  if ("serviceWorker" in navigator) {
    const swUrl = `${process.env.PUBLIC_URL}/custom-service-worker.js`
    window.addEventListener("load", () => {
      const logger = getLoggerInstance()
      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          logger.debug(`ServiceWorker registration successful with scope: ${registration.scope}`)
        })
        .catch((err) => {
          logger.error(err)
        })
    })
  }
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister()
      })
      .catch((error) => {
        const logger = getLoggerInstance()
        logger.error(error)
      })
  }
}
