"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var buildUrl = function buildUrl(parameters) {
  return Object.entries(parameters).filter(function (v) {
    return v[1];
  }).map(function (_ref) {
    var _ref2 = (0, _slicedToArray2["default"])(_ref, 2),
      key = _ref2[0],
      val = _ref2[1];
    return Array.isArray(val) ? val.map(function (subval) {
      return "".concat(key, "=").concat(subval);
    }).join("&") : "".concat(key, "=").concat(val);
  }).join("&");
};
module.exports = buildUrl;