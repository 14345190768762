//TODO multi-call: remove this file when multi-call is resolved
const dayjs = require("dayjs")

const IGNORE = "IGNORE"

class CallCache {
  lastDetectedTaskId = ""
  reviewedCalls = {}

  pickRightTasks(_tasks, filteringEnabled, assumeSingleActiveTask) {
    let tasks = _tasks.sort((a, b) => dayjs(a.interactionCreateTime).diff(dayjs(b.interactionCreateTime)))
    tasks = filteringEnabled ? tasks.filter((task) => this.reviewedCalls[task.id] !== IGNORE) : _tasks
    const activeTask = tasks.find((task) => task.status === "ACTIVE")

    if (activeTask) {
      if (this.lastDetectedTaskId === activeTask.id) {
        return { ignore: true }
      }
      this.lastDetectedTaskId = activeTask.id
      return [activeTask]
    }

    // this assumes you only get 1 active task while in a call
    if (assumeSingleActiveTask && this.lastDetectedTaskId && !tasks.find((task) => task.id === this.lastDetectedTaskId)) {
      this.lastDetectedTaskId = ""
      return []
    }

    return tasks.filter((task) => task.status === "ASSIGNED")
  }

  ignoreTask(taskId) {
    this.reviewedCalls[taskId] = IGNORE
  }

  clearCall(callId) {
    delete this.reviewedCalls[callId]
  }
}

const instance = new CallCache()

exports = module.exports = instance
