const { clientEventNames, serverEventNamesV2 } = require("../../constants")
const { serverToClient: translate } = require("../translate")
const CallCache = require("../CallCache")
const { RESERVATION_STATUS, TASK_STATUS } = require("../callConstants")

const noop = () => [null, null]
class MessageProcessor {
  _logger = null
  _config = null
  _dataService = null
  _callCache = CallCache

  onCallRing = this._onCallRing.bind(this)
  onCallAnswer = this._onCallAnswer.bind(this)
  onCallEnd = this._onCallEnd.bind(this)

  constructor({ configService, logger, dataService, processing } = {}) {
    this._config = configService
    this._logger = logger
    this._dataService = dataService
    this._processingManager = processing
  }

  setLogger(logger) {
    this._logger = logger
  }

  setConfig(config) {
    this._config = config
  }

  setDataService(dataService) {
    this._dataService = dataService
  }

  getTaskEvent(status) {
    const taskHandlers = {
      [TASK_STATUS.RINGING]:
        this._config?.().get("sse.events.ringing") !== "reservation"
          ? this._onCallRing.bind(this)
          : this._onIgnoredMessage.bind(this),
      [TASK_STATUS.ANSWERED]:
        this._config?.().get("sse.events.answered") !== "reservation"
          ? this._onCallAnswer.bind(this)
          : this._onIgnoredMessage.bind(this),
      undefined:
        this._config?.().get("sse.events.end") !== "reservation"
          ? this._onCallEnd.bind(this)
          : this._onIgnoredMessage.bind(this),
    }

    return taskHandlers[status]
  }

  async process(eventId, serverPayload) {
    const processingFunction = this._selectProcessingFunction(eventId, serverPayload)
    return processingFunction(serverPayload, eventId)
  }

  _selectProcessingFunction(eventId, serverPayload) {
    const isMulticallEnabled = this._config?.().get("multitask.enabled")
    if (eventId === serverEventNamesV2.AGENT_TASKS && isMulticallEnabled) {
      //TODO multi-call: remove this if block when multi-call is resolved
      const actualTasks = this._callCache.pickRightTasks(
        serverPayload.tasks,
        this._config?.().get("multitask.filteringEnabled"),
        this._config?.().get("multitask.assumeSingleActiveTask")
      )
      this._logger?.().debug("SSEv2: AGENT_TASKS payload modified", actualTasks)
      serverPayload.tasks = actualTasks
      if (actualTasks?.ignore) {
        return () => [null, null]
      }
      return this._selectCallProcessingFunction(serverPayload)
    }
    if (eventId === serverEventNamesV2.AGENT) {
      return this._onProfileChange.bind(this)
    }
    if (eventId === serverEventNamesV2.RESERVATION_UPDATED) {
      return this._onCallReservation(serverPayload)
    }
    if (eventId === serverEventNamesV2.AGENT_TASKS) {
      return this.getTaskEvent(serverPayload.tasks[0]?.status)
    }

    return this._genericChange.bind(this)
  }

  //TODO multi-call: remove when multi-call is resolved
  _selectCallProcessingFunction(serverPayload) {
    if (serverPayload?.tasks?.length > 1) {
      const areReservationsBeingUsed = this._config?.()?.get("sse.events.ringing") === "reservation"
      const answeredTasks = serverPayload.tasks.filter((task) => task.status === TASK_STATUS.ANSWERED)
      if (answeredTasks.length) {
        return this._onCallAnswer.bind(this)
      }
      return areReservationsBeingUsed ? noop : this._onMultiCall.bind(this)
    }
    return this.getTaskEvent(serverPayload.tasks[0]?.status)
  }

  //TODO multi-call: remove when multi-call is resolved
  _onMultiCall(serverPayload) {
    const clientPayload = serverPayload.tasks.map(translate.task)
    return [clientEventNames.MULTI_CALL, clientPayload]
  }

  async _onCallRing(serverPayload) {
    const eventId =
      this._config?.()?.get("sse.events.ringing") === "reservation"
        ? serverEventNamesV2.RESERVATION_UPDATED
        : serverEventNamesV2.AGENT_TASKS
    const clientPayload = await this._processingManager.process(eventId, serverPayload)
    return [clientEventNames.RINGING, clientPayload]
  }

  async _onCallAnswer(serverPayload) {
    const eventId =
      this._config?.()?.get("sse.events.answered") === "reservation"
        ? serverEventNamesV2.RESERVATION_UPDATED
        : serverEventNamesV2.AGENT_TASKS
    const clientPayload = await this._processingManager.process(eventId, serverPayload)
    return [clientEventNames.ANSWERED, clientPayload]
  }

  _onCallReservation(serverPayload) {
    const clientEvent = {
      [RESERVATION_STATUS.RINGING]:
        this._config?.()?.get("sse.events.ringing") === "reservation"
          ? this._onCallRing.bind(this)
          : this._onIgnoredMessage.bind(this),
      [RESERVATION_STATUS.ACCEPTED]:
        this._config?.()?.get("sse.events.answered") === "reservation"
          ? this._onCallAnswer.bind(this)
          : this._onIgnoredMessage.bind(this),
      [RESERVATION_STATUS.COMPLETED]:
        this._config?.()?.get("sse.events.end") === "reservation"
          ? this._onCallEnd.bind(this)
          : this._onIgnoredMessage.bind(this),
      [RESERVATION_STATUS.TIMED_OUT]:
        this._config?.()?.get("sse.events.end") === "reservation"
          ? this._onCallEnd.bind(this)
          : this._onIgnoredMessage.bind(this),
      [RESERVATION_STATUS.CANCELLED]:
        this._config?.()?.get("sse.events.end") === "reservation"
          ? this._onCallEnd.bind(this)
          : this._onIgnoredMessage.bind(this),
    }[serverPayload.status]
    return clientEvent || this._onUnknownMessage.bind(this)
  }

  _onCallEnd() {
    return [clientEventNames.END_CALL, null]
  }

  _onProfileChange(serverPayload) {
    return [clientEventNames.PROFILE, serverPayload.id]
  }

  async _genericChange(serverPayload, eventId) {
    const clientPayload = await this._processingManager.process(eventId, serverPayload)
    return [translate.eventNames[eventId], clientPayload]
  }

  _onIgnoredMessage(serverPayload, eventId) {
    this._logger?.().debug(`SSEv2: Ignoring ${eventId} message, payload disregarded`, serverPayload)
    return [null, serverPayload]
  }

  _onUnknownMessage(serverPayload, eventId) {
    this._logger?.().warn(`SSEv2: Message ${eventId} unknown, payload disregarded`, serverPayload)
    return [null, serverPayload]
  }
}

module.exports = MessageProcessor
