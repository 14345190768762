const { useCallback } = require("react")

export const VALIDATION_FAILURES = {
  LENGTH: "lengthExceeded",
  REQUIRED: "requiredField",
}

const useEventValidation = (mainValidatorFunction, eventValidator) => {
  const onEventWithValidation = useCallback(
    (fn) => {
      return (evt) => {
        mainValidatorFunction(eventValidator, evt)
        fn?.(evt)
      }
    },
    [mainValidatorFunction, eventValidator]
  )

  return onEventWithValidation
}

/** 
  useValidation {Hook}

  Turns an event into a function that both fires the event & runs some validation callback function

  validation {Object}: of the following form: {
    onKeyDown {Function}: returns a truthy string with the error reason or empty string if no error 
    onPaste {Function}: returns a truthy string with the error reason or empty string if no error 
  } 
  processValidationCallback {Function}: a function that runs with every event (ie. onKeyDown)
*/
export const useValidation = (validation, processValidationCallback) => {
  const validate = useCallback(
    (validatorFn, evt) => {
      const failureReason = validatorFn ? validatorFn(evt) : null
      processValidationCallback(failureReason, evt)
      return failureReason
    },
    [processValidationCallback]
  )

  const onKeyDownWithValidation = useEventValidation(validate, validation?.onKeyDown)

  const onPasteWithValidation = useEventValidation(validate, validation?.onPaste)

  return {
    onKeyDownWithValidation,
    onPasteWithValidation,
  }
}
