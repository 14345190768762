import React, { createContext, memo, useMemo, useState } from "react"
import { useLanguages } from "contexts/Languages"
import { createContextHook } from "utils/contextHelpers"
import { useCallSegments } from "./useCallSegments"
export { OVER_PHONE, OUTSOURCE } from "./useCallSegments"

const CallSegmentsDataContext = createContext()

const _CallSegmentsDataProvider = ({ children }) => {
  const { callSegments } = useCallSegments()
  const { languages } = useLanguages()
  const [saved, setSaved] = useState(true)

  const languageOptions = useMemo(() => {
    return languages
      ?.map?.((lang) => ({
        text: lang.name,
        value: lang.id,
      }))
      .sort((a, b) => a.text > b.text)
  }, [languages])

  return (
    <CallSegmentsDataContext.Provider
      value={{
        callSegments,
        languageOptions,
        setSaved,
        saved,
      }}
    >
      {children}
    </CallSegmentsDataContext.Provider>
  )
}

export const CallSegmentsDataProvider = memo(_CallSegmentsDataProvider)

export const useCallSegmentsData = createContextHook(
  CallSegmentsDataContext,
  "useCallSegmentsData must be used within a CallSegmentsDataContext"
)

export default CallSegmentsDataContext
