import { DTO } from "martti-agent-services"

const accessCodeFields = [
  "showPatientFirstName",
  "showPatientLastName",
  "showPatientMRN",
  "showPatientDOB",
  "showProviderFirstName",
  "showProviderLastName",
  "showPhoneNumberAccessCode",
  "showBCHOaklandHospitalConfirmation",
  "showLocation",
  "showMemberIDNumber",
  "showNYUAccessCode",
  "showSentaraAccessCode",
  "showVisitID",
  "showCallerFirstName",
  "showCallerLastName",
  "skipAudioCallAccessCodeValidation",
]

class AccessCodeDTO extends DTO {
  optionalFields = accessCodeFields
  allowAllFields = true
  acceptNulls = true

  toPresentationalObject(data) {
    const accessCode = {}
    for (const key of accessCodeFields) {
      accessCode[key] = data[key] ?? false
    }

    return accessCode
  }
}

export default AccessCodeDTO
