const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")
const OutsourceCallModel = require("./OutsourceCallModel")

class Outsource extends DataServiceEndPoint {
  static key = "Outsource"
  _outsourceCall = ({ interactionId, payload }) => {
    return this._httpService.post(`${this.url}/v1/interactions/${interactionId}/outsource`, payload, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  getOutsourcedLanguages = () => {
    return this._httpService.get(`${this.url}/v1/outsource/languages`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  getPartnersByLanguage = ({ languageId }) => {
    return this._httpService.get(`${this.url}/v1/outsource/languages/${languageId}/partners`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  outsourceCall = ({ interactionId, payload }) => {
    const transferObject = new OutsourceCallModel(payload)

    return this._outsourceCall({ interactionId, payload: transferObject.present() })
  }
}

module.exports = Outsource
