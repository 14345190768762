"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var HUMAN_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
var SHORT_HUMAN_DATE_FORMAT = "YYYY-MM-DD";
var CALL_HISTORY_DATE_FORMAT = "D-MMM-YY\nh:mm:ss A";
var PHONE_TYPES = {
  E164Validator: "E164Validator"
};
var TIME_TYPES = {
  DATE: "DATE",
  TIMESTAMP: "TIMESTAMP",
  DURATION: "DURATION"
};
var HANDLERS = _objectSpread(_objectSpread({}, PHONE_TYPES), TIME_TYPES);
module.exports = {
  HANDLERS: HANDLERS,
  HUMAN_DATE_FORMAT: HUMAN_DATE_FORMAT,
  SHORT_HUMAN_DATE_FORMAT: SHORT_HUMAN_DATE_FORMAT,
  CALL_HISTORY_DATE_FORMAT: CALL_HISTORY_DATE_FORMAT,
  DATE_FORMATS: {
    "short": SHORT_HUMAN_DATE_FORMAT,
    "long": HUMAN_DATE_FORMAT,
    recentCalls: CALL_HISTORY_DATE_FORMAT
  }
};