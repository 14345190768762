import AccessCodeDTO from "store/dto/accessCode"

const initialState = {
  showPatientFirstName: false,
  showPatientLastName: false,
  showPatientMRN: false,
  showPatientDOB: false,
  showProviderFirstName: false,
  showProviderLastName: false,
  showPhoneNumberAccessCode: false,
  showBCHOaklandHospitalConfirmation: false,
  showLocation: false,
  showMemberIDNumber: false,
  showNYUAccessCode: false,
  showSentaraAccessCode: false,
  showVisitID: false,
  showCallerFirstName: false,
  showCallerLastName: false,
  skipAudioCallAccessCodeValidation: false,
}

const createAccessCodeSlice = (set) => {
  const setAccessCode = (accessCodeObject) =>
    set(() => ({
      accessCodeDefinition: new AccessCodeDTO(accessCodeObject).present(),
    }))

  return {
    accessCodeDefinition: initialState,
    setAccessCode,
  }
}

export default createAccessCodeSlice
