import React, { useState } from "react"
import cs from "classnames"
import Icon from "@cloudbreakus/cloudbreak-react-icon"
import Wrapper from "../Wrapper"
import * as StyleSheet from "../styles.js"
import { useEventWrapper } from "../useEventWrapper"

const Input = ({
  value,
  onChange = () => null,
  theme,
  className,
  label = "",
  placeholder = "",
  disabled,
  name,
  password,
  error,
  ...rest
}) => {
  const styleSheet = StyleSheet[theme]?.(disabled, error, value) || StyleSheet["profile"](disabled, error, value)
  const [showPassword, setShowPassword] = useState(false)

  const _onChange = useEventWrapper(onChange)

  return (
    <Wrapper label={label} className={className} name={`input-${name}`} styleSheet={styleSheet} error={error}>
      <input
        className={cs("form-input", styleSheet.input)}
        placeholder={placeholder}
        value={value ?? ""}
        onChange={_onChange}
        disabled={disabled}
        type={password && !showPassword ? "password" : "text"}
        {...rest}
      />
      {password && (
        <Icon
          name={`toggle-${name}`}
          className={cs("pl-2", "fill-[#605F5E]")}
          icon={showPassword ? "IoEyeOff" : "IoEye"}
          onClick={() => setShowPassword(!showPassword)}
          size={24}
          aria-label="Toggle show Password"
        />
      )}
    </Wrapper>
  )
}

export default Input
