import create from "zustand"
import createCallScreenSupportSlice from "./slices/CallScreenSupportSlice"
import createAuthSlice from "./slices/AuthSlice"

const useStore = create((set, get) => ({
  ...createAuthSlice(set, get),
  ...createCallScreenSupportSlice(set, get),
}))

export default useStore
