const { DTO } = require("@cloudbreakus/network-utilities")
const { serverEventNamesV2 } = require("../../constants")
const { PresenceDTO, CallSegmentsDTO, TasksDTO, ReservationDTO } = require("./processing")

class ProcessingManager {
  _processors = {}
  _config = null
  _logger = null
  _dataService = null

  _initialized = false

  constructor({ logger, config, dataService } = {}) {
    this._logger = logger
    this._config = config
    this._dataService = dataService
  }

  setLogger(logger) {
    this._logger = logger
  }

  setConfig(config) {
    this._config = config
  }

  setDataService(dataService) {
    this._dataService = dataService
  }

  /**
   * Use this function to initialize any and all processing steps we need for each individual incoming message
   * Standalone functions and DTO definitions can be imported here or injected in some way and then added to the processors
   * list for their corresponding event.
   */
  initialize() {
    this._initialized = true
    this.attach(serverEventNamesV2.AGENT_PRESENCE, new PresenceDTO())
    this.attach(serverEventNamesV2.INTERACTION_CALL_SEGMENTS, new CallSegmentsDTO())
    this.attach(serverEventNamesV2.AGENT_TASKS, new TasksDTO())
    this.attach(serverEventNamesV2.RESERVATION_UPDATED, new ReservationDTO())
  }

  attach(event, handler) {
    if (!this._processors[event]) {
      this._processors[event] = []
    }
    this._processors[event].push(handler)
  }

  async process(event, data) {
    let processedValue = data
    let processorsForEvent = this._processors[event]

    if (!processorsForEvent) {
      return processedValue
    }

    for (const processor of processorsForEvent) {
      if (processor instanceof DTO) {
        processedValue = processor.buildObjectWithData(processedValue).present()
      } else {
        processedValue = processor(processedValue, {
          logger: this._logger,
          config: this._config,
          dataService: this._dataService,
        })
      }
      if (processedValue instanceof Promise) {
        processedValue = await processedValue
      }
    }

    return processedValue
  }
}

module.exports = ProcessingManager
