import { useEffect, useState } from "react"
import useHospitalData from "hooks/useHospitalData"

const defaultDepartmentOptions = {
  name: "------",
  id: "",
}

export const useDepartmentsForForm = (setDepartment, setServerState, hospitalId, departmentId) => {
  const [departmentOptions, setDepartmentOptions] = useState([])
  const { getDepartments } = useHospitalData(hospitalId)

  useEffect(() => {
    if (getDepartments()) {
      const departments = getDepartments()
      setDepartmentOptions(
        [defaultDepartmentOptions, ...departments].map((dep) => ({
          text: dep.name,
          value: dep.id,
        }))
      )
    }
  }, [getDepartments, setDepartment, setServerState])

  useEffect(() => {
    if (!hospitalId) {
      setDepartmentOptions([defaultDepartmentOptions])
    }
  }, [hospitalId])

  useEffect(() => {
    if (departmentId && departmentOptions.length) {
      setDepartment((v) => v || departmentId)
      setServerState((state) => ({
        ...state,
        departmentId: state.departmentId || departmentId,
      }))
    }
  }, [departmentId, departmentOptions, setDepartment, setServerState])

  return {
    departmentOptions,
  }
}
