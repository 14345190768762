import React, { useCallback, useState } from "react"
import cs from "classnames"
import Wrapper from "../Wrapper"
import * as StyleSheet from "../styles.js"

const baseTestId = "radio"

const RadioInput = ({
  name,
  value,
  onChange,
  label,
  options,
  direction = "vertical",
  theme,
  disabled,
  className,
  ...rest
}) => {
  const [localValue, setLocalValue] = useState(value || null)
  const styleSheet = StyleSheet[theme]?.(disabled) || StyleSheet["profile"](disabled)

  const _onChange = useCallback(
    (v) => {
      if (onChange) {
        onChange(v)
      }
      setLocalValue(v)
    },
    [onChange]
  )

  return (
    <Wrapper label={label} className={className} name={`radio-input-${name}`} styleSheet={styleSheet}>
      <div
        className={cs("flex", {
          "flex-row": direction === "horizontal",
          "flex-col": direction !== "horizontal", // default
        })}
        id={`${baseTestId}-${name}`}
        data-testid={`${baseTestId}-${name}`}
        role={"radiogroup"}
        {...rest}
      >
        {options?.map((item) => {
          return (
            <div role="radio" key={item.value} className={"flex flex-row items-center mr-6"}>
              <input
                type="radio"
                role="radio"
                data-testid={`${baseTestId}-${name}-${item.value}`}
                id={item.value}
                name={name}
                value={item.value}
                checked={localValue === item.value}
                onChange={() => _onChange(item.value)}
                aria-checked={localValue === item.value}
                className={cs("form-radio rounded-full", styleSheet.selectors)}
              />
              <label htmlFor={item.value}>{item.text}</label>
            </div>
          )
        })}
      </div>
    </Wrapper>
  )
}

export default RadioInput
