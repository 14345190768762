import React, { memo, useCallback, useState } from "react"
import cs from "classnames"
import { createContextHook } from "../../utils/contextHelpers"
import { ToastContainer, toast } from "react-toastify"
import Error from "../../components/Error"

import "react-toastify/dist/ReactToastify.css"

const AlertsContext = React.createContext()

const _AlertsProvider = ({ children, logger }) => {
  const [title, setTitle] = useState(null)
  const [message, setMessage] = useState(null)
  const [options, setOptions] = useState(null)
  const [visible, setVisible] = useState(false)

  const hideAlert = useCallback(() => {
    setVisible(() => false)
    setTitle(() => null)
    setMessage(() => null)
    setOptions(() => null)
  }, [])

  const showAlert = useCallback(
    (title, message, options) => {
      const enhancedOptions = options
        ? options.map((opt) => ({
            ...opt,
            onClick: () => {
              hideAlert()
              opt?.onClick?.()
            },
          }))
        : [
            {
              text: "OK",
              onClick: () => {
                hideAlert()
              },
            },
          ]
      setTitle(() => title)
      setMessage(() => message)
      setOptions(() => enhancedOptions)
      setVisible(() => true)
    },
    [hideAlert]
  )

  const showToast = useCallback((message, type = "info", ...rest) => {
    if (typeof message === "string") {
      const toastType = ["success", "error", "info", "warn"].includes(type) ? type : "info"
      return toast[toastType](message, ...rest)
    }
    return toast(message, ...rest)
  }, [])

  const updateToast = useCallback((toastId, ...rest) => {
    toast.update(toastId, ...rest)
  }, [])

  /**
   * Dismisses toast with the given ID
   *
   * @param {String} toastId - Toast ID string
   */
  const dismissToast = useCallback(
    (toastId) => {
      if (typeof toastId !== "string") {
        return
      }
      try {
        return toast.dismiss(toastId)
      } catch (err) {
        logger?.error(`An error has occurred while dismissing toast by id ${toastId}`, err)
      }
    },
    [logger]
  )

  return (
    <AlertsContext.Provider
      value={{ showAlert, hideAlert, showToast, updateToast, dismissToast }}
    >
      {visible && (
        <>
          <div
            className={cs([
              "flex",
              "justify-center",
              "items-center",
              "bg-gray-900/50",
              "z-30",
              "top-0",
              "left-0",
              "right-0",
              "bottom-0",
              "fixed",
            ])}
          >
            <Error title={title} message={message} options={options} />
          </div>
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 30 }}
      />
      {children}
    </AlertsContext.Provider>
  )
}

export const useAlerts = createContextHook(AlertsContext, "useAlerts should be used inside an AlertsProvider")

export const AlertsProvider = memo(_AlertsProvider)

export default AlertsContext
