import { getLoggerInstance } from "utils/logger"

export const sendPersistentNotification = (payload) =>
  navigator?.serviceWorker?.controller?.postMessage?.({
    __type: "notification",
    payload,
  })

export const removePersistentNotifications = () =>
  navigator?.serviceWorker?.controller?.postMessage?.({
    __type: "clear-notifications",
  })

export const askForPermissionAndShowNotification = async (payload) => {
  if (!("Notification" in window)) {
    const logger = getLoggerInstance()
    logger.warn("This browser does not support desktop notification")
  } else if (Notification.permission === "granted") {
    sendPersistentNotification(payload)
  } else if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission()
    if (permission === "granted") {
      sendPersistentNotification(payload)
    }
  }
}
