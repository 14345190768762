"use strict";

var handlerFactory = require("./handlerFactory");
var _require = require("./constants"),
  HANDLERS = _require.HANDLERS,
  DATE_FORMATS = _require.DATE_FORMATS;
var validate = function validate(value, type, config) {
  return handlerFactory.getHandler(type, config).validate(value);
};
var format = function format(value, type, config) {
  return handlerFactory.getHandler(type, config).format(value);
};
module.exports = {
  validate: validate,
  format: format,
  HANDLERS: HANDLERS,
  DATE_FORMATS: DATE_FORMATS
};