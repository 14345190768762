import { useState, useEffect, useCallback } from "react"
import { SSE } from "utils/SSE"
import { useAuth } from "contexts/Auth"
import { dataService } from "utils/dataService"
import { logger } from "utils/logger"
import { sortAlphabeticalByName } from "utils/helpers"
import { useAlertMessage } from "hooks/useAlertMessage"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"

export const useLanguages = () => {
  const [languages, setLanguages] = useState([])
  const [resubscribe, setResubscribe] = useState(false)

  const { SSEopen, isAuthenticated } = useAuth()
  const { showCustomToast } = useAlertMessage()
  const enableHasuraSub = useConfigFlag("dataService.languages") === "hasura-sub"

  const getLanguages = useCallback(async () => {
    try {
      const { data } = await dataService.Languages.get()
      const languages = data.sort(sortAlphabeticalByName)
      setLanguages(languages)
      logger.info("Languages list retrieved", languages)
    } catch (err) {
      logger.error("unable to fetch languages list", err)
      showCustomToast({
        message: `Application error: Unable to fetch languages.`,
        level: "error",
        onRetry: getLanguages,
        closeable: true,
      })
    }
  }, [showCustomToast])

  useEffect(() => {
    if (!isAuthenticated || !enableHasuraSub) {
      return
    }

    let unsubscribe
    try {
      const onError = (err) => logger.warn("An error occurred with the Languages subscription", err)
      unsubscribe = dataService.Languages.subscribe(setLanguages, { onError })
      logger.debug("Successfully subscribed to Languages")
    } catch (err) {
      //TODO: look into the scenarios that cause this to throw to decide how to handle it (ie. force logout, show toast, etc.)
      logger.error("An error occurred while subscribing to Languages", err)
      showCustomToast({
        message: `Application error: Unable to subscribe to languages.`,
        level: "error",
        onRetry: () => setResubscribe((r) => !r),
        closeable: true,
      })
    }
    return () => {
      unsubscribe?.()
    }
  }, [enableHasuraSub, isAuthenticated, showCustomToast, resubscribe])

  useEffect(() => {
    if (SSEopen && !enableHasuraSub) {
      getLanguages()
      const onRinging = () => getLanguages()
      SSE.on("ringing", onRinging)
      return () => {
        SSE.off("ringing", onRinging)
      }
    }
  }, [getLanguages, SSEopen, enableHasuraSub])

  return {
    languages,
  }
}

export default useLanguages
