import { useState, useEffect, useCallback } from "react"
import { SSE } from "utils/SSE"
import { useAuth } from "contexts/Auth"
import { useProfile } from "contexts/Profile"
import { dataService } from "utils/dataService"
import { logger } from "utils/logger"
import { ON_BREAK, OFF_QUEUE } from "./presences"
import { usePresenceRefresh } from "./refresh"
import { PresenceManager } from "./PresenceManager"
import { useAlertMessage } from "hooks/useAlertMessage"

const presenceManager = new PresenceManager()
const set = (presence) => presenceManager.requestPresence(presence)

export const renameQueue = (queueName) => (queueName === ON_BREAK ? OFF_QUEUE : queueName)

/**
 * @typedef {Object} usePresenceReturn
 * @property {string} name - Human readable name of the user's presence (ie. "Available")
 * @property {string} since - JSON-parsable timestamp of when the user's presence was set (ie. "2023-06-26T14:06:45.999Z")
 * @property {function(): Promise} set - Used for setting your presence:
 *   - Resolves to true when what you set is what you get.  Resolves false otherwise
 * @property {function} getOffQueueNow - Requests a presence change to "Off Queue"/"On Break" from the server both immediately and after any unresolved presence requests resolve.
 * @property {string} expectingPresence - Name of the presence that was requested but has not yet resolved.
 * @property {Object} presences - Objet where the keys are human-readable presence names and the values are the salesforce presence ID's
 */

/**
 * usePresence hook
 * @returns {usePresenceReturn} object described in {@link usePresenceReturn}
 */
export const usePresence = () => {
  const { userData, SSEopen, isAuthenticated } = useAuth()
  const { additionalUserData } = useProfile()
  const { showErrorToast, showCustomToast } = useAlertMessage()

  const [presences, setPresences] = useState({})
  const [afterCall, setAfterCall] = useState(null)
  const [nextPresence, setNextPresence] = useState("")
  const [name, setName] = useState("")
  const [since, setSince] = useState(new Date().toJSON())
  const [expectingPresence, setExpectingPresence] = useState(false)

  const assertPresence = useCallback((presence) => {
    setName(presence?.presenceName)
    setSince(presence?.startTime)
    setExpectingPresence(false)
    presenceManager.onPresence(presence?.presenceName)
  }, [])

  usePresenceRefresh({ assertPresence, setExpectingPresence, existingPresenceName: name, since })

  const getAllPresences = useCallback(async () => {
    try {
      const response = await dataService.Presence.getAllPresences()
      logger.info(`presences fetched`, response?.data)
      setPresences(
        response?.data?.reduce(
          (prev, item) => ({
            ...prev,
            [item.presence]: item.presenceId,
          }),
          {}
        )
      )
    } catch (err) {
      logger.error(`Unable to fetch presences`, err)
      showCustomToast({
        message:
          "Unable to fetch list of statuses.  This data required for the app to function properly, keep retrying or contact a supervisor if it keeps happening",
        level: "error",
        onRetry: getAllPresences,
      })
    }
  }, [showCustomToast])

  const _set = useCallback(
    async (presence) => {
      if (!userData?.userId) {
        return
      }
      try {
        setExpectingPresence(presence)
        await dataService.Presence.set({
          agentId: userData.userId,
          id: presences[presence],
        })
        logger.info(`Requested presence update to ${presences[presence]} (${presence})`)
      } catch (err) {
        const APIerror = `Unable to request presence update to "${presence}"`
        showErrorToast(APIerror, { autoClose: true })
        logger.error(`${APIerror} (${presences[presence]})`, err)
      }
    },
    [userData?.userId, presences, showErrorToast]
  )

  useEffect(() => {
    if (nextPresence && !expectingPresence) {
      setNextPresence(null)
      _set(nextPresence)
    }
  }, [_set, nextPresence, expectingPresence])

  const getOffQueueNow = useCallback(async () => {
    logger.warn("SSE has died unexpectedly, user taken off queue")
    _set(ON_BREAK)
    setNextPresence(ON_BREAK)
  }, [_set])

  useEffect(() => {
    if (!SSEopen) {
      return
    }
    SSE.on("status", assertPresence)

    return () => {
      SSE.off("status", assertPresence)
    }
  }, [assertPresence, SSEopen])

  useEffect(() => {
    if (isAuthenticated) {
      getAllPresences()
    } else {
      assertPresence({})
      setPresences({})
    }
  }, [isAuthenticated, getAllPresences, assertPresence])

  useEffect(() => {
    assertPresence({
      presenceName: additionalUserData?.presence,
      startTime: additionalUserData?.presenceStartTime,
    })
  }, [additionalUserData, assertPresence])

  useEffect(() => {
    presenceManager.on("nextPresence", setNextPresence)
    return () => {
      presenceManager.off("nextPresence", setNextPresence)
    }
  }, [])

  return {
    name,
    since,
    afterCall,
    set,
    setAfterCall,
    getOffQueueNow,
    expectingPresence,
    presences,
  }
}

export default usePresence
