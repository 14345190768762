const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

class Presence extends DataServiceEndPoint {
  static key = "Presence"

  set = async ({ id, agentId }) => {
    const response = await this._httpService.put(
      `${this.url}/v1/agents/${agentId}/presence`,
      {
        presenceId: id,
      },
      { headers: { Authorization: `bearer ${this.token}` } }
    )
    return response
  }

  get = async (agentId) => (agentId ? await this.getMyPresence(agentId) : await this.getAllPresences())

  getAllPresences = async () => {
    const response = await this._httpService.get(`${this.url}/v1/presences`, {
      headers: { Authorization: `bearer ${this.token}` },
      "axios-retry": {
        retries: 3,
      },
    })
    return response
  }

  getMyPresence = async (agentId) => {
    return this._httpService.get(`${this.url}/v1/agents/${agentId}/presence`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }
}

module.exports = Presence
