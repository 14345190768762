"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _require = require("react"),
  useCallback = _require.useCallback,
  useState = _require.useState,
  useMemo = _require.useMemo,
  useEffect = _require.useEffect;
var _require2 = require("./StorageContext"),
  useStorageContext = _require2.useStorageContext,
  storage = _require2.storage;
var parseBoolean = function parseBoolean(str) {
  return str === "true";
};
var parsers = {
  "boolean": parseBoolean,
  number: parseInt,
  "float": parseFloat,
  object: JSON.parse
};
var parseStringAsType = function parseStringAsType(str, _type) {
  var _type$name;
  var type = "".concat((_type === null || _type === void 0 ? void 0 : (_type$name = _type.name) === null || _type$name === void 0 ? void 0 : _type$name.toLowerCase()) || (_type === null || _type === void 0 ? void 0 : _type.toLowerCase()));
  try {
    return parsers[type](str);
  } catch (err) {
    return str;
  }
};

/** React Hook for saving data in local storage
 *
 * @param {String} localKey - the local storage key (aka the variable name)
 * @param {type} type [String, Boolean, Number, "float"] - the value's type, defaults to "String"
 * @returns {Array<value,Function>} - like React's useState, it returns a value and a setter function for that value.  The type of the value is specified as a parameter.
 *
 * This function migrates data from global namespace to userId-scoped namespace.
 * It saves stringified versions of all values but returns the value in the desired type.
 */
var useStorage = function useStorage(localKey, type) {
  var _useStorageContext = useStorageContext(),
    userId = _useStorageContext.userId;
  var storageKey = useMemo(function () {
    return userId ? "".concat(userId, "-").concat(localKey) : localKey;
  }, [userId, localKey]);
  var _useState = useState(storage.get(storageKey, type)),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    _value = _useState2[0],
    _setValue = _useState2[1];
  var value = useMemo(function () {
    return parseStringAsType(_value, type);
  }, [type, _value]);
  var setValue = useCallback(function (val) {
    storage.set(storageKey, typeof val === "string" ? val : JSON.stringify(val));
  }, [storageKey]);
  useEffect(function () {
    _setValue(storage.get(storageKey, type));
    var unsubscribe = storage.onEvent(storageKey, _setValue);
    return function () {
      return unsubscribe();
    };
  }, [storageKey, type]);
  useEffect(function () {
    // migration logic
    var loggedIn = userId;
    var defaultKeySet = !(storage.get(localKey) === null);
    var scopedKeySet = !(storage.get(storageKey) === null);
    if (!loggedIn || !defaultKeySet || scopedKeySet) {
      return;
    }
    setValue(storage.get(localKey));
  }, [storageKey, localKey, userId, setValue]);
  return [value, setValue];
};
module.exports = {
  useStorage: useStorage
};