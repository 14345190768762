const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

class SSE extends DataServiceEndPoint {
  static key = "SSE"

  subscribe = (payload) => {
    return this._httpService.put(`${this._config.get("sse.v2.notificationUrl")}/subscriptions/v2`, payload, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  unsubscribe = (data) => {
    return this._httpService.delete(`${this._config.get("sse.v2.notificationUrl")}/subscriptions/v2`, {
      headers: { Authorization: `bearer ${this.token}` },
      data,
    })
  }
}

module.exports = SSE
