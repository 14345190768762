import { useCallback, useEffect, useState } from "react"
import useStore from "store"
import { useLoading } from "@cloudbreakus/ui-components"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { useAlertMessage } from "hooks/useAlertMessage"
import { useInteractionNotes } from "hooks/useInteractionNotes"
import { logger } from "utils/logger"
import { config } from "utils/configService"
import { dataService } from "utils/dataService"
import { useRoleQueues } from "contexts/Queues/useRoleQueues"
import { usePresence, ON_BREAK } from "contexts/Presence"
import { selectAnsweredCallOnly, selectHasAVIssues } from "store/selectors/call"

const attemptToTransfer = async (answeredCall, mainOperatorQueueId, supervisorQueueId) => {
  try {
    const sendToSupervisor = answeredCall?.queue?.id === mainOperatorQueueId
    const response = await dataService.Calls.transfer({
      queueId: sendToSupervisor ? supervisorQueueId : mainOperatorQueueId,
      taskId: answeredCall.taskId,
    })
    const message = `Successfully transferred call to ${sendToSupervisor ? "supervisor" : "operator"} queue.`
    logger?.debug(message, response)
    return message
  } catch (error) {
    const message = "An error has occurred while attempting to transfer to another agent."
    logger?.error(message, error, answeredCall)
    return message
  }
}

const attemptToReQueue = async (taskId, failureReason) => {
  try {
    const response = await dataService.Calls.replaceFailed({
      taskId,
      failureReason,
    })
    const message = "Successfully placed call back on queue."
    logger?.debug(message, response)
    return message
  } catch (error) {
    const message = "An error has occurred while attempting to place the call back on queue."
    logger?.error(message, error, taskId)
    return message
  }
}

export const useCallConnecting = ({ expectingAnswer, exitConfirmation, callEnded, wrapUp }) => {
  const { hideLoading } = useLoading()
  const { showWarnToast } = useAlertMessage()
  const { submitComment } = useInteractionNotes()
  const { set: setPresence } = usePresence()
  const abortSeconds = useConfigFlag("calls.timeoutOnAnswer")
  const { mainOperatorQueueId, supervisorQueueId } = useRoleQueues()

  const [aborting, setAborting] = useState(false)
  const [fireAbort, setFireAbort] = useState(false)
  const [abortReason, setAbortReason] = useState(false)

  const _abort = useCallback(
    async (reason) => {
      if (!expectingAnswer || exitConfirmation || aborting) {
        return
      }
      setAborting(true)

      let warning = [reason]
      let terminateCall = !callEnded

      const timeRanOut = !reason
      if (timeRanOut) {
        const avIssues = selectHasAVIssues(useStore.getState())[0]
        const inTime = `the expected time (${abortSeconds} seconds).`

        warning = [`No call was received ${inTime}`]
        let caseNote = [`Call automatically terminated because no call was received ${inTime}`]
        if (avIssues) {
          const avMessage = `camera or microphone could not be accessed`
          warning = [`Your ${avMessage} ${inTime}`]
          caseNote = [`Call automatically terminated because the agent's ${avMessage} ${inTime}`]
        }

        const answeredCall = selectAnsweredCallOnly(useStore.getState())

        const actionOnTimeout = config.get("calls.actionOnTimeout")
        if (actionOnTimeout === "requeue") {
          const replaceMessage = await attemptToReQueue(
            answeredCall?.taskId,
            avIssues ? "Camera/Microphone issue" : "Technical issue"
          )
          caseNote.push(replaceMessage)
          warning.push(replaceMessage)
          terminateCall = false
          setPresence(ON_BREAK)
        }
        if (actionOnTimeout == "transfer") {
          const answeredCall = selectAnsweredCallOnly(useStore.getState())
          const message = await attemptToTransfer(answeredCall, mainOperatorQueueId, supervisorQueueId)
          caseNote.push(message)
          warning.push(message)
        }

        await submitComment(caseNote.join("\n"), { id: answeredCall?.interaction?.id })
        warning.push("A Case note to this effect has been submitted.")
      }

      wrapUp({ terminate: terminateCall, navigateTo: "/" })
      hideLoading()
      showWarnToast(warning.join(" \n"), { autoClose: true })
      logger?.warn("Connecting to call aborted", warning)
    },
    [
      abortSeconds,
      callEnded,
      exitConfirmation,
      expectingAnswer,
      mainOperatorQueueId,
      supervisorQueueId,
      hideLoading,
      setPresence,
      aborting,
      showWarnToast,
      submitComment,
      wrapUp,
    ]
  )

  useEffect(() => {
    if (fireAbort) {
      setFireAbort(false)
      _abort(abortReason)
    }
  }, [fireAbort, _abort, abortReason])

  const abort = useCallback((reason) => {
    setAbortReason(reason)
    setFireAbort(true)
  }, [])

  useEffect(() => {
    if (!expectingAnswer || exitConfirmation || callEnded) {
      return
    }

    const abortCountdown = setTimeout(abort, abortSeconds * 1_000)
    const countDownStart = Date.now()

    return () => {
      clearTimeout(abortCountdown)
      const countDownDuration = Date.now() - countDownStart
      logger.debug(`Connection to call took ${Math.trunc(countDownDuration / 1000)} seconds`, countDownDuration)
    }
  }, [expectingAnswer, exitConfirmation, callEnded, abort, abortSeconds])

  useEffect(() => {
    if (exitConfirmation) {
      return
    }
    if (expectingAnswer && callEnded) {
      abort("The call could not be answered.  It was either assigned to another agent or the caller hung up.")
    }
  }, [expectingAnswer, exitConfirmation, callEnded, abort])

  useEffect(() => {
    if (!expectingAnswer) {
      setAborting(false)
    }
  }, [aborting, expectingAnswer])

  return { abort }
}
