import { merge } from "lodash"
import useStore from "store"
import { selectRole } from "store/selectors/auth"

const getCallParams = (meetingUrl, userId) => {
  let url = {}
  try {
    url = new URL(meetingUrl)
  } catch (err) {
    return {}
  }
  const searchParams = Object.fromEntries(url.searchParams.entries())
  const passback = searchParams?.passback?.replace("{PARTICIPANT_ID}", userId)
  const audio = {
    from: searchParams?.from?.replace("{AGENT_ID}", userId),
    to: searchParams?.to,
  }

  return {
    passback,
    roomId: searchParams?.roomId,
    audio,
  }
}

export const parseTaskCallData = (call, userId, _action) => {
  const {
    meetingUrl,
    id,
    interactionId,
    interactionChannel,
    interactionName,
    interactionType,
    interactionMarttiName,
    interactionMarttiId,
    interactionClientType,
    interactionMediaServer,
    interactionPartnerFriendlyName,
    interactionPartnerId,
    customerPhoneNumber,
    languageName,
    languageId,
    queueName,
    queueId,
    createdDate,
    acceptTime,
    reservationId,
    taskId,
  } = call

  const { passback, roomId, audio } = getCallParams(meetingUrl, userId)
  const role = selectRole(useStore.getState())

  return {
    taskId: id || taskId,
    meetingUrl,
    roomId,
    audio: audio || {
      from: `${userId},,${_action || "JOIN"},${role},${id || taskId}`,
      to: interactionId,
    },
    passback,
    _action,
    createTime: createdDate,
    acceptTime,
    interaction: {
      id: interactionId,
      name: interactionName,
      channel: interactionChannel,
      type: interactionType,
      clientType: interactionClientType,
      startTime: createdDate,
      unitId: interactionMarttiName || interactionMarttiId,
      hospitalId: interactionPartnerId,
      hospitalName: interactionPartnerFriendlyName,
      customerPhoneNumber,
      mediaServer: interactionMediaServer,
    },
    martti: {
      marttiId: interactionMarttiId,
      unitId: interactionMarttiName || interactionMarttiId,
    },
    queue: {
      id: queueId,
      name: queueName,
    },
    language: {
      name: languageName,
      id: languageId,
    },
    reservationId,
  }
}

export const mergeInteractionCallData = (interaction, call) => {
  const interactionCallData = {
    interaction: {
      name: interaction.name,
      channel: interaction.channel,
      unitId: interaction.marttiUnitId || interaction.accessCodeMarttiUnitId,
      hospitalId: interaction.partnerId,
      hospitalName: interaction.partnerFriendlyName,
      customerPhoneNumber: interaction.customerPhoneNumber,
    },
    martti: {
      unitId: interaction.marttiUnitId || interaction.accessCodeMarttiUnitId,
    },
    language: {
      name: interaction.languageName,
    },
    queue: {
      name: `${interaction.languageName}${interaction.channel === "AUDIO" ? "(AUDIO)" : ""}`,
    }, //TODO: remove when server provides queue name
  }
  return merge(interactionCallData, call)
}
