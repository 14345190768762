import React from "react"
import { Security as OktaSecurity } from "@okta/okta-react"
import { useEffect } from "react"
import { oktaAuth } from "./okta/client"

oktaAuth.start()

/* istanbul ignore next */
const noop = () => null

const Security = ({ children }) => {
  useEffect(() => {
    return () => oktaAuth.stop()
  }, [])
  return (
    <OktaSecurity oktaAuth={oktaAuth} restoreOriginalUri={noop}>
      {children}
    </OktaSecurity>
  )
}

export default Security
