const EventEmitter = require("eventemitter2")

/**
 * @class HTMLAudioElement
 * @description This class is a wrapper around the HTML Audio Element: https://developer.mozilla.org/en-US/docs/Web/API/HTMLAudioElement/Audio.
 * @param {string} audioFile - The URL of the audio file to play
 * @property {boolean} loop - Whether the audio should loop
 * @method play - Play the audio
 * @method stop - Stop the audio
 */
export class HTMLAudioElement extends EventEmitter {
  //TODO: implement pause method (resumable with play method)
  clip = null

  constructor(audioFile) {
    super()
    this.clip = new Audio(audioFile)
    this.clip.addEventListener("ended", this._onEnd.bind(this))
  }

  _onEnd() {
    this.emit("end")
  }

  set loop(loop) {
    this.clip.loop = loop
  }

  async play() {
    this.clip.currentTime = 0
    this.clip.play()
  }

  async stop() {
    this.clip?.pause()
  }

  get paused() {
    return this.clip.paused
  }
}
