import { useEffect, useState } from "react"
import { getUserIp, getAppVersion } from "utils/appInfo"

import { getLoggerInstance } from "utils/logger"
const logger = getLoggerInstance()

export const useLogAttributes = (userData) => {
  const [userIp, setUserIp] = useState("Unknown")

  useEffect(() => {
    const getIp = async () => {
      try {
        const ip = await getUserIp()
        setUserIp(ip)
      } catch (err) {
        logger?.error(`An error occurred fetching the user's IP address`, err)
      }
    }

    getIp()
    logger.addAttribute("version", getAppVersion())
  }, [])

  useEffect(() => {
    logger.addAttribute("ip", userIp)
  }, [userIp])

  useEffect(() => {
    if (!userData?.userId) {
      logger.addAttribute("user", "Anonymous")
    }
  }, [userData?.userId])

  useEffect(() => {
    if (userData?.partnerId) {
      logger.addAttribute("User Partner ID", userData.partnerId)
    }
  }, [userData?.partnerId])
}
