"use strict";

var _require = require("./useStorage/StorageContext"),
  StorageProvider = _require.StorageProvider;
var _require2 = require("./useStorage"),
  useStorage = _require2.useStorage;
var _require3 = require("./useStorage/StorageContext"),
  storage = _require3.storage;
module.exports = {
  StorageProvider: StorageProvider,
  useStorage: useStorage,
  storage: storage
};