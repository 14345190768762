const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

class Martti extends DataServiceEndPoint {
  static key = "Martti"
  get = async ({ marttiId }) => {
    return this._httpService.get(`${this.url}/v1/marttis/${marttiId}`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }
}

module.exports = Martti
