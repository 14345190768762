import React from "react"
import cs from "classnames"
import { useEventWrapper } from "../useEventWrapper"
import Wrapper from "../Wrapper"
import * as StyleSheet from "../styles.js"

const TextArea = ({
  name,
  value,
  onChange = () => null,
  disabled = false,
  theme,
  className,
  label = "",
  height = "h-12",
  ...rest
}) => {
  const styleSheet = StyleSheet[theme]?.(disabled) || StyleSheet["profile"](disabled)

  const _onChange = useEventWrapper(onChange)

  return (
    <Wrapper label={label} className={className} name={`text-area-${name}`} styleSheet={styleSheet}>
      <textarea
        className={cs("form-textarea", "p-1", styleSheet.input, height)}
        placeholder=""
        value={value}
        onChange={_onChange}
        disabled={disabled}
        {...rest}
      />
    </Wrapper>
  )
}

export default TextArea
