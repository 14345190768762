const EventEmitter = require("eventemitter2")

/**
 * @class AudioBufferSourceNode
 * @description This class is a wrapper around the Web Audio API's AudioBufferSourceNode: https://developer.mozilla.org/en-US/docs/Web/API/AudioBufferSourceNode.
 * @param {string} audioFile - The URL of the audio file to play
 * @property {boolean} loop - Whether the audio should loop
 * @method play - Play the audio
 * @method stop - Stop the audio
 */
export class AudioBufferSourceNode extends EventEmitter {
  //TODO: implement pause method (resumable with play method)
  audioFile = null
  source = null
  _loop = false
  _started = false
  _stopped = true

  constructor(audioFile) {
    super()
    this.audioFile = audioFile
  }

  set loop(loop) {
    if (this.source) {
      this.source.loop = loop
    }
    this._loop = loop
  }

  async _initialize() {
    //Note: this can take up to 350ms but appears to get cached after the first time
    const context = new AudioContext()
    const response = await fetch(this.audioFile)
    const raw_buffer = await response.arrayBuffer()
    const buffer = await context.decodeAudioData(raw_buffer)
    this.source = context.createBufferSource()
    this.source.buffer = buffer
    this.source.connect(context.destination)
    this.source.loop = this._loop
    this.source.addEventListener("ended", this._onEnd.bind(this))
  }

  _onEnd() {
    this._stopped = true
    this.emit("end")
  }

  async play() {
    if (this._started && !this._stopped) {
      return
    }
    this._stopped = false
    await this._initialize()
    this._started = false
    if (this._stopped) {
      return null
    }
    this.source.start()
    this._started = true
  }

  async stop() {
    this._stopped = true
    this.source?.stop()
  }

  get paused() {
    return !this._started || this._stopped
  }
}
