import { LoggerMessages } from "@cloudbreakus/logger"
import { config } from "./configService"

/**
 * Attempts to call the preload.js defined method to trigger an ipcHandler on electron
 * @param {string} methodName
 * @param  {...any} args
 */
const tryToPublishElectronMessage = (methodName, ...args) => {
  try {
    window.electronLogger?.[methodName]?.(...args)
  } catch (err) {
    /* no-op */
  }
}

const loggerMessageToMethod = {
  [LoggerMessages.DEBUG]: "debug",
  [LoggerMessages.INFO]: "info",
  [LoggerMessages.WARNING]: "warn",
  [LoggerMessages.ERROR]: "error",
  [LoggerMessages.ADD_ATTRIBUTE]: "addAttribute",
  [LoggerMessages.REMOVE_ATTRIBUTE]: "removeAttribute",
  [LoggerMessages.JSERROR]: "jsError",
  [LoggerMessages.LOG]: "log",
}

/**
 * Higher order functions that returns a proxy function that acts the same way as a logger function would
 * All of the arguments are passed down to the real logger function and intercepted to add custom behavior before the log happens
 * @param {Function} method - Function to be called
 * @param {Object} target - Object to be applied as this argument
 * @param {String} methodName - Name of the method
 * @returns
 */
const electronLoggerMethod =
  (method, target, methodName) =>
  (...args) => {
    tryToPublishElectronMessage(methodName, ...args)
    return method.apply(target, args)
  }

/**
 * Standard Proxy implementation overriding the get trap
 */
const proxyHandler = {
  get: (...args) => {
    const [target, prop] = args
    const baseMethod = Reflect.get(...args)
    if (prop === "log") {
      const info = Reflect.get(target, "info", target)
      return electronLoggerMethod(info, target, "info")
    }
    if (Object.values(loggerMessageToMethod).includes(prop)) {
      return electronLoggerMethod(baseMethod, target, prop)
    }
    return baseMethod
  },
}

const listenToEventsAndBuildProxy = (loggerInstance) => {
  for (const key of Object.keys(LoggerMessages).filter((key) => key !== LoggerMessages.LOG)) {
    const methodName = loggerMessageToMethod[key]
    window.electronLogger?.on(key, (...params) => {
      loggerInstance[methodName](...params.slice(1))
    })
  }
  window.electronLogger.ready(true)
  return new Proxy(loggerInstance, proxyHandler)
}

/**
 * Sets up proxies for logger methods, the only proxy that exists right now emits a message to electron before logging
 * It's a Base JS proxy implementation that overrides log, debug, info, warn, error and addAttribute
 * Make sure you pass a valid logger instance following the ILogger interface
 * @param {Object} loggerInstance
 * @returns
 */
export const setupLoggerProxies = (loggerInstance) => {
  return window.electronWindow?.isElectron && config.get("electron.logging.proxyEnabled")
    ? listenToEventsAndBuildProxy(loggerInstance)
    : loggerInstance
}
