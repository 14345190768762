const SSEPipeline = require("./SSEPipeline")

let instance = null

const getPipelineInstance = (services) => {
  if (!instance) {
    instance = new SSEPipeline(services)
  }
  return instance
}

const fetchEventTarget = (services) => {
  const pipeline = getPipelineInstance(services)
  pipeline.prepareEventStream()
  return pipeline
}

module.exports = {
  fetchEventTarget,
}
