import { useState, useEffect, useMemo } from "react"
import { getLoggerInstance } from "utils/logger"
import { useAlertMessage } from "hooks/useAlertMessage"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { dataService } from "utils/dataService"

const logger = getLoggerInstance()

export const useHeartbeat = (isAuthenticated) => {
  const heartbeatDuration = useConfigFlag("heartbeat.refreshIntervalSeconds", 60) * 1000
  const allowedHeartbeatFailures = useConfigFlag("heartbeat.allowedFailures", 2)
  const { showErrorToast } = useAlertMessage()
  const [failures, setFailures] = useState(0)
  const [statusCode, setStatusCode] = useState(false)

  const flatLine = useMemo(
    () => statusCode === 401 || failures > allowedHeartbeatFailures,
    [statusCode, failures, allowedHeartbeatFailures]
  )

  useEffect(() => {
    if (flatLine && isAuthenticated) {
      showErrorToast("Connection to server may soon disconnect: Consider logging out and logging back in.")
      logger.error("Heartbeat has flat-lined")
    }
    if (!isAuthenticated || !heartbeatDuration || flatLine) {
      return
    }

    const heartbeat = async () => {
      try {
        await dataService.Auth.keepAlive()
        logger.info("Refreshed agent session")
        setFailures(0)
      } catch (err) {
        setFailures((f) => f + 1)
        logger.error("heartbeat failed", err)
        setStatusCode(err?.response?.data?.statusCode)
      }
    }

    let heartbeatRefresher = setInterval(heartbeat, heartbeatDuration)
    return () => clearInterval(heartbeatRefresher)
  }, [isAuthenticated, heartbeatDuration, flatLine, showErrorToast])

  return { isTokenDead: flatLine }
}
