const { WINDOW_MESSAGE_TYPES } = require("../constants")
const BaseAdapter = require("./BaseAdapter")

class ElectronAdapter extends BaseAdapter {
  ports = null
  _nameToIdMap = {}
  _services = null
  supportedMessages = [WINDOW_MESSAGE_TYPES.PORT_RECEIVED, WINDOW_MESSAGE_TYPES.CLOSED]

  constructor(services) {
    super()
    this.ports = new Map()
    this._services = services
    this._nameToIdMap = {}
  }

  set(key, port) {
    this.ports.set(key, port)
  }

  get(key) {
    return this.ports.get(key)
  }

  send(service, payload) {
    const id = this._nameToIdMap[service]
    const port = this.get(id)
    port.postMessage(payload)
  }

  handleNewPort(event) {
    const [port] = event.ports
    const { data } = event

    this.set(data.payload.id, port)
    this._nameToIdMap[data.payload.key] = data.payload.id

    port.onmessage = (ev) => {
      this.handleIncomingMessage(ev)
    }
  }

  handleWindowClosed(payload) {
    const { screenKey } = payload
    const id = this._nameToIdMap[screenKey]
    const port = this.get(id)

    port?.close()
    this.ports.delete(id)
    delete this._nameToIdMap[screenKey]
  }

  handleGenericMessage(event) {
    if (this._services?.config?.get("inAppMessaging.logUnknownMessages")) {
      this._services?.logger?.debug(`Unknown event: `, event)
    }
  }

  handleIncomingMessage(event) {
    try {
      const { data } = event
      const type = data?.meta?.type
      switch (type) {
        case WINDOW_MESSAGE_TYPES.CLOSED:
          return this.handleWindowClosed(data.payload)
        case WINDOW_MESSAGE_TYPES.PORT_RECEIVED:
          return this.handleNewPort(event)
        default:
          return this.handleGenericMessage(event)
      }
    } catch (err) {
      this?._services?.logger?.error(err)
    }
  }
}

module.exports = ElectronAdapter
