import React, { useEffect, useRef, useState } from "react"
import MultiselectReactDropdown from "multiselect-react-dropdown"
import cs from "classnames"
import Wrapper from "../Wrapper"
import * as StyleSheet from "../styles.js"

/**
 *  Multiselect Component with autocomplete for selecting multiple options from a dropdown
 *
 * @param {Array<Object>} options (required) - Options to displayed in the component
 * @param {Function} onChange (required) - returns currently selected values when a selection is made
 * @param {string} displayValue (required) - key from the options array to be displayed in the dropdown and placeholder
 * @param {string} optionalKey - When provided, the value of this key will be used to compare items in the options array
 * @param {Array} initialSelection - List of items to be selected
 * @param {Object} theme - Styling theme (ie. "profile", "agents", "search", "searchBox").  Defaults to "agents"
 * @param {Object} className - CSS classes to apply to the parent element
 * @param {String} label - Set of columns that have numeric values (as opposed to alphabetical)
 * @param {Boolean} disabled - Whether or not the input is disabled
 * @param {Boolean} single - Whether it behaves like a normal dropdown (single select dropdown)
 * @param {string} name - used to build the unique test id of the internal components
 * @param {Boolean} error - tells the component to use error styling
 * @returns {Arguments}
 */
const MultiSelect = ({
  options,
  placeholder = "",
  onChange = () => null,
  initialSelection = [],
  optionalKey,
  displayValue,
  theme,
  className,
  label = "",
  disabled,
  name,
  error,
  single,
  ...rest
}) => {
  const multiselectRef = useRef()
  const [currentSelection, setCurrentSelection] = useState(initialSelection)
  const [_initialSelection, setInitialSelection] = useState(initialSelection)
  const styleSheet = StyleSheet[theme]?.(disabled, error) || StyleSheet["agents"](disabled, error)

  /* istanbul ignore next */
  const _onChange = (items) => {
    if (single) {
      items = items.slice(-1)
      setInitialSelection([...items])
    }
    setCurrentSelection([...items])
    onChange([...items])
  }

  const onReset = () => {
    setInitialSelection([])
    setCurrentSelection([])
    onChange([])
    multiselectRef?.current?.resetSelectedValues()
  }

  useEffect(() => {
    if (!optionalKey) {
      return
    }
    const initialSelectionTransformed = options.filter((option) =>
      initialSelection.find((item) => item[optionalKey] === option[optionalKey])
    )
    setInitialSelection(initialSelectionTransformed)
    setCurrentSelection(initialSelectionTransformed)
  }, [initialSelection, options, optionalKey])

  return (
    <Wrapper
      label={label}
      className={cs(className, currentSelection.length ? "[&_.searchBox]:placeholder-black" : "[&_.searchBox]:italic")}
      name={`multiselect-${name}`}
      styleSheet={styleSheet}
      error={error}
    >
      <MultiselectReactDropdown
        ref={multiselectRef}
        placeholder={
          currentSelection.length ? currentSelection.map((item) => item[displayValue]).join(", ") : placeholder
        }
        onRemove={_onChange}
        onSelect={_onChange}
        displayValue={displayValue}
        selectedValues={_initialSelection}
        showCheckbox
        avoidHighlightFirstOption
        hideSelectedList
        showArrow
        options={options}
        {...rest}
      />
      {!single && (
        <button
          className={cs(
            "flex flex-row absolute right-8 top-1/2 -translate-y-1/2 text-xl text-gray-400 font-bold bg-white hover:text-gray-600 focus:text-gray-600 focus:outline-none",
            { hidden: !currentSelection.length }
          )}
          onClick={onReset}
          aria-label={`deselect ${placeholder || "all"}`}
        >
          ⨉
        </button>
      )}
    </Wrapper>
  )
}

export default MultiSelect
