import React, { useEffect, useState } from "react"
import cs from "classnames"
import { useMultiSorter } from "./useMultiSorter"
import HelpButton from "../HelpButton"
import {
  TableContainer,
  TableBody,
  TableDataRow,
  TableDataCell,
  TableHeaderCell,
  TableHeaderRow,
  TableSortLabel,
  TableHead,
  tableDefaultTheme,
  TableSortHandler,
  useTableContext,
} from "./TableBase"

const TableRows = ({ transformValue, columns, rows }) => {
  const { sortedRows, alreadySorted } = useTableContext()
  const rowsToRender = alreadySorted ? rows : sortedRows
  return (rowsToRender || []).map((row, row_index) => (
    <TableDataRow key={`${row_index}-${row?.id}`}>
      {Object.keys(columns).map((col, col_index) => (
        <TableDataCell key={col_index}>
          {transformValue[col] ? transformValue[col](row[col], row_index) : row[col]}
        </TableDataCell>
      ))}
    </TableDataRow>
  ))
}

/**
 * Reusable React component for displaying tabular data
 *
 * @param {String} className - CSS classes to apply to the parent Table element
 * @param {Object} styles - optional styling object with classes for "header" and "rows" and icon for "arrow" and "tooltips"
 * @param {Object} columns (required) - Object where each key identifies each column and each value is the name displayed in the table header for that column
 * @param {Array<Object>} rows (required) - Array of objects where properties dictate which column each value is displayed in
 * @param {Object} transformValue - Object where each value is a function that transforms row data for a given column key
 * @param {Set<String>} numericColumns - Set of columns that have numeric values (as opposed to alphabetical)
 * @param {Set<String>} unsortableColumns - Set of columns for which sorting is disabled
 * @param {Array<String>} columnClassNames - Array of class names where the n-th item is applied to the n-th column header
 * @param {Boolean} multiSort (default true) - Toggles sorting by multiple columns simultaneously
 * @param {Boolean} alreadySorted - Whether the provided row data requires sorting
 * @param {Function} onSort - returns sortCriteria Object (described below) when a change to the sorting is made:
 * @param {Map<String, string>} tooltips - Map of tooltips where the key identifies the column and the value is it's tooltip text
 *
 * Table sorting is controlled by the sortCriteria Object
 *
 * sortCriteria Example:
 *   sortCriteria = {duration: 1, customer: -1, skill: 1}
 * Interpretation:
 *   First sort by duration in ascending order, then by customer in descending order, then by skill in ascending order.
 * Note:
 *   Order matters and only columns that require sorting are kept track of.
 */
export const Table = ({
  columns,
  rows,
  transformValue = {},
  numericColumns = new Set(),
  unsortableColumns = new Set(),
  columnClassNames = [],
  multiSort = true,
  alreadySorted,
  onSort,
  className,
  styles = {},
  "data-testid": testId,
  tooltips,
}) => {
  const [userRequestedSort, setUserRequestedSort] = useState(false)
  const { sorting: sortCriteria, setSorting: setSortCriteria, sorter } = useMultiSorter();

  const handleSort = (sortCriteria) => {
    if (userRequestedSort) {
      onSort?.(sortCriteria)
    }
  }

  return (
    <TableContainer
      className={className}
      data-testid={testId}
      rows={rows}
      numericColumns={numericColumns}
      multiSort={multiSort}
      alreadySorted={alreadySorted}
      theme={{
        ...tableDefaultTheme,
        headerCell: styles.header
          ? cs(
              "text-left font-semibold text-xs p-3 whitespace-pre-line align-top relative first:rounded-tl-xl last:rounded-tr-xl",
              styles.header
            )
          : tableDefaultTheme.headerCell,
        sortIcon: styles.icon ? styles.icon : tableDefaultTheme.sortIcon,
        lastSortIcon: styles.icon ? "" : tableDefaultTheme.lastSortIcon,
        dataRow: styles.rows ? styles.rows : tableDefaultTheme.dataRow,
      }}
    >
      <TableSortHandler onSort={handleSort} />
      <TableHead>
        <TableHeaderRow>
          {Object.keys(columns).map((col, index) => (
            <TableHeaderCell className={cs(columnClassNames[index])} key={index}>
              {unsortableColumns.has(col) ? (
                <div className="inline-flex">{columns[col]}</div>
              ) : (
                <TableSortLabel
                  index={index}
                  columns={columns}
                  column={col}
                  icon={styles.arrow}
                  iconClassName={styles.icon}
                  onClick={() => setUserRequestedSort(true)}
                >
                  {columns[col]}
                </TableSortLabel>
              )}
              {tooltips?.has(col) && <HelpButton tooltip={tooltips.get(col)} col={col} tooltipStyle={styles.tooltip} />}
            </TableHeaderCell>
          ))}
        </TableHeaderRow>
      </TableHead>
      <TableBody data-testid={`${testId}-table-body`}>
        <TableRows transformValue={transformValue} columns={columns} rows={rows} />
      </TableBody>
    </TableContainer>
  )
}

export default Table
