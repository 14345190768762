"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var LOG = "LOG";
var DEBUG = "DEBUG";
var INFO = "INFO";
var ERROR = "ERROR";
var WARN = "WARN";
var WARNING = "WARNING";
var JSERROR = "JSERROR";
var ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
var REMOVE_ATTRIBUTE = "REMOVE_ATTRIBUTE";
var OFF = "OFF";
var mapLogLevelToValue = (0, _defineProperty2["default"])((0, _defineProperty2["default"])((0, _defineProperty2["default"])((0, _defineProperty2["default"])((0, _defineProperty2["default"])((0, _defineProperty2["default"])((0, _defineProperty2["default"])((0, _defineProperty2["default"])({}, LOG, 0), DEBUG, 0), INFO, 1), WARNING, 2), WARN, 2), ERROR, 3), JSERROR, 3), OFF, 99);
var logLevels = [DEBUG, INFO, WARNING, ERROR, JSERROR];
var defaultLogLevel = ERROR;
var getLogLevelValue = function getLogLevelValue(level) {
  return mapLogLevelToValue[level === null || level === void 0 ? void 0 : level.toUpperCase()] || 0;
};
module.exports = {
  DEBUG: DEBUG,
  INFO: INFO,
  ERROR: ERROR,
  WARNING: WARNING,
  LOG: LOG,
  ADD_ATTRIBUTE: ADD_ATTRIBUTE,
  REMOVE_ATTRIBUTE: REMOVE_ATTRIBUTE,
  JSERROR: JSERROR,
  OFF: OFF,
  logLevels: logLevels,
  defaultLogLevel: defaultLogLevel,
  getLogLevelValue: getLogLevelValue
};