import React, { useState } from "react"
import cs from "classnames"
import Button, { buttonThemes as THEMES } from "../../../components/Button"

const Notification = ({ name, message, onRetry, dismiss }) => {
  const [clicked, setClicked] = useState(false)
  const _onRetry = () => {
    if (clicked) {
      return
    }
    setClicked(true)
    dismiss()
    onRetry()
  }

  return (
    <div className={cs("flex", "flex-row")}>
      <div className={cs("w-3/4", "text-sm")}>
        <span>{message}</span>
      </div>
      {onRetry && (
        <div className={cs("w-1/4", "flex", "flex-col", "gap-y-4", "items-center", "justify-center")}>
          <Button
            name={`${name}-retry`}
            className={cs("w-16", "text-sm")}
            halfHeight
            onClick={_onRetry}
            theme={THEMES.error}
            type="button"
          >
            Retry
          </Button>
        </div>
      )}
    </div>
  )
}

export default Notification
