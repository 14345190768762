export const CREATED = "CREATED"
export const QUEUED = "QUEUED"
export const ASSIGNED = "ASSIGNED"
export const ACTIVE = "ACTIVE"
export const COMPLETED = "COMPLETED"
export const CANCELLED = "CANCELLED"
export const FAILED = "FAILED"
export const QUEUE_TIMED_OUT = "QUEUE_TIMED_OUT"

export default {
  CREATED,
  QUEUED,
  ASSIGNED,
  ACTIVE,
  COMPLETED,
  CANCELLED,
  FAILED,
  QUEUE_TIMED_OUT,
}
