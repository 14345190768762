/* istanbul ignore file */
//TODO: actually write tests for this file once it matters
import React from "react"
import CallForm from "screens/Call/components/CallForm/CallForm"
import CallSheetContainer from "./CallSheet"

const refreshCallSheet = () => null
const useCallFormData = () => {
  return {
    saved: false,
    requiresAccessCode: false,
    validAccessCode: true,
    refreshCallSheet,
  }
}

const Dummy = ({ className }) => {
  return <div className={className}>Im a div</div>
}

const CallFormContainer = () => {
  return (
    <CallForm
      useCallFormData={useCallFormData}
      AccessCode={Dummy}
      CallSheet={CallSheetContainer}
      CaseNotes={Dummy}
      SupervisorNotes={Dummy}
      Scripts={Dummy}
      ReportIT={Dummy}
    />
  )
}

export default CallFormContainer
