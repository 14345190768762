import { useEffect } from "react"
import useMarttiData from "hooks/useMarttiData"
import useStore from "store"
import shallow from "zustand/shallow"
import { selectAccessCode } from "store/selectors/accessCode"

export const useCustomFields = (unitId, accessCodeObject) => {
  const { getMartti, martti } = useMarttiData(unitId)
  const [accessCode, setAccessCode] = useStore(selectAccessCode, shallow)

  useEffect(() => {
    const martti = getMartti()
    const accessCode = accessCodeObject ?? martti?.accessCode
    setAccessCode(accessCode)
  }, [accessCodeObject, getMartti, setAccessCode])

  return {
    accessCode,
    departmentId: martti?.departmentId || accessCode?.department || "",
  }
}
