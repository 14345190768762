const KEEP_ALIVE_DATA = "keep-alive-message"

const START = "start"
const OPEN = "open"
const MESSAGE = "message"
const CLOSE = "close"
const ABORT = "abort"
const ERROR = "error"
const CHANNEL_DISCONNECTED = "CHANNEL_DISCONNECTED"

const PUBLIC_MESSAGES = {
  START,
  MESSAGE,
  CLOSE,
  ABORT,
  ERROR,
  OPEN,
  CHANNEL_DISCONNECTED,
}

module.exports = {
  KEEP_ALIVE_DATA,
  PUBLIC_MESSAGES,
}
