const { DTO } = require("@cloudbreakus/network-utilities")

class TelemetryDTO extends DTO {
  optionalFields = ["interactionId", "userId", "statsEvent"]
  acceptNulls = false

  toPresentationalObject(data) {
    const { statsEvent, interactionId, userId } = data
    return {
      ...statsEvent,
      interactionId,
      agentId: userId,
    }
  }
}

module.exports = TelemetryDTO
