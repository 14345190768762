const EventEmitter = require("eventemitter2")

/**
 * A class for handling when an action has been taken too frequently and needs to "cool down"
 * 
 * @property {boolean} coolDown - Whether or not the action is currently cooling down
 * @property {number} threshold - The number of ticks before the action cools down
 * @property {number} intervalSeconds - The number of seconds until the cooldown ticks down
 */
class Cooler extends EventEmitter {
  coolDown = false
  threshold = 5
  intervalSeconds = 1
  _interval = null
  _count = 0

  constructor() {
    super()
  }

  /**
   * Advances the cooldown's ticks by multiples of 2
   */
  tick = () => {
    this._count = 2 * this._count + 1
    this._processCount(this._count)
    this._activateInterval()
  }

  _activateInterval = () => {
    if (this._interval) {
      return
    }
    this._interval = setInterval(this._intervalAction.bind(this), this.intervalSeconds * 1_000)
  }

  _intervalAction = () => {
    this._count--
    this._processCount(this._count)
  }

  _processCount = (count) => {
    if (count > this.threshold) {
      if (!this.coolDown) {
        this.emit("coolDown", true)
        this.coolDown = true
      }
      return
    }
    if (this.coolDown) {
      this.emit("coolDown", false)
      this.coolDown = false
    }
    if (count <= 0) {
      this.reset()
    }
  }

  /**
   * Resets the cooldown mechanism and clears the internal interval
   */
  reset = () => {
    this._count = 0
    this.coolDown = false
    clearInterval(this._interval)
    this._interval = null
  }
}

module.exports = Cooler
