import React from "react"
import { ErrorPage as BaseErrorPage } from "@cloudbreakus/ui-components"
import { useNavigate } from "react-router-dom"

const ErrorPage = (props) => {
  const navigate = useNavigate()

  const onNavigate = () => {
    navigate("/")
    navigate(0)
  }

  return <BaseErrorPage {...props} onNavigate={onNavigate} />
}

export default ErrorPage
