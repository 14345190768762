import { useState, useMemo } from 'react';
import { firstBy } from 'thenby';

// 1 represents descending order, -1 represents ascending order
export function useMultiSorter() {
  const [sorting, setSorting] = useState({});

  const sorter = useMemo(() => {
    const sortEntries = Object.entries(sorting);
    if (sortEntries.length === 0) return null;

    const [firstKey, firstDirection] = sortEntries[0];
    let sorter = firstBy(firstKey, { 
      ignoreCase: true, 
      direction: firstDirection === 1 ? 'desc' : 'asc' 
    });

    for (let i = 1; i < sortEntries.length; i++) {
      const [key, direction] = sortEntries[i];
      sorter = sorter.thenBy(key, { 
        ignoreCase: true, 
        direction: direction === 1 ? 'desc' : 'asc' 
      });
    }

    return sorter;
  }, [sorting]);

  return { sorting, setSorting, sorter };
}