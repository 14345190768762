import React, { useEffect, useRef } from "react"
import { noop } from "lodash"
import cs from "classnames"
import Icon from "@cloudbreakus/cloudbreak-react-icon"

const getThemeProps = (small, theme) =>
  ({
    primary: {
      iconClass: small ? "fill-bblue-500 stroke-bblue-500" : "fill-green-500 stroke-green-500",
      textClass: small ? "text-bblue-500" : "text-green-500",
      wrapperClass: small
        ? "ring-bblue-500 group-hover:bg-bblue-200 group-focus:bg-bblue-200"
        : "ring-green-500 group-hover:bg-green-200 group-focus:bg-green-200",
    },
    secondary: {
      iconClass: small ? "fill-morange-500 stroke-morange-500" : "fill-red-500 stroke-red-500",
      textClass: small ? "text-morange-500" : "text-red-500",
      wrapperClass: small
        ? "ring-morange-500 group-hover:bg-morange-200 group-focus:bg-morange-200"
        : "ring-red-500 group-hover:bg-red-200 group-focus:bg-red-200",
    },
  }[theme])

const channelProps = {
  audio: {
    icon: "phoneIn",
    text: "ANSWER",
  },
  video: {
    icon: "IoVideocamOutline",
    text: "ANSWER",
  },
  listen: {
    icon: "headphones",
    text: "LISTEN IN",
  },
}

const AnswerButton = ({
  children,
  channel,
  onClick,
  focus = false,
  small = false,
  disabled,
  id,
  tooltipContent,
  theme,
}) => {
  const alertRef = useRef(null)
  useEffect(() => {
    if (alertRef.current && focus) {
      alertRef.current.focus()
      alertRef.current.tabIndex = "0"
    }
  }, [focus])

  const { icon, text } = channelProps[channel?.toLowerCase()] || channelProps["audio"]
  const { iconClass, textClass, wrapperClass } = getThemeProps(small, theme) || getThemeProps(small, "primary")

  const iconSize = small ? 20 : 32

  return (
    <button
      onClick={disabled ? noop : onClick}
      ref={alertRef}
      className="group focus:outline-none"
      data-tooltip-id={id}
      data-tooltip-content={tooltipContent}
      data-testid={id}
      disabled={disabled}
    >
      <div
        className={cs(
          "flex justify-center items-center rounded-full mx-auto",
          small ? "ring-[3px] h-6 w-6" : "ring-2 h-14 w-14",
          wrapperClass
        )}
      >
        <Icon icon={icon} className={iconClass} size={iconSize} />
      </div>
      <span className={cs("font-bold", textClass)}>{children || text}</span>
    </button>
  )
}

export default AnswerButton
