"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FeatureBangProvider", {
  enumerable: true,
  get: function get() {
    return _FeatureBangContext.FeatureBangProvider;
  }
});
Object.defineProperty(exports, "useConfigFlag", {
  enumerable: true,
  get: function get() {
    return _useConfigFlag.useConfigFlag;
  }
});
Object.defineProperty(exports, "useFeatureBang", {
  enumerable: true,
  get: function get() {
    return _FeatureBangContext.useFeatureBang;
  }
});
var _FeatureBangContext = require("./contexts/FeatureBangContext");
var _useConfigFlag = require("./useConfigFlag");