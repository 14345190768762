const EventEmitter2 = require("eventemitter2")
const { clientEventNames } = require("../constants")

class ClientInterface extends EventEmitter2 {
  _loggerService = null
  _configService = null
  _dataService = null

  _serverConnection = null

  constructor({ configService, logger, dataService, serverConnection } = {}) {
    super()
    this._loggerService = logger
    this._configService = configService
    this._dataService = dataService
    this._serverConnection = serverConnection
  }

  setLogger(logger) {
    this._loggerService = logger
  }

  setConfigService(configService) {
    this._configService = configService
  }

  setDataService(dataService) {
    this._dataService = dataService
  }

  async open() {
    try {
      await this._serverConnection.open()
    } catch (err) {
      this._loggerService?.error("SSEv2: Failed to start", err)
      this.emit(clientEventNames.LOGOUT, true)
    }
  }

  unsubscribe(clientEventName) {
    return this._serverConnection.unsubscribe(clientEventName)
  }

  subscribe(clientEventName, eventParameter) {
    return this._serverConnection.subscribe(clientEventName, eventParameter)
  }

  reconnect() {
    this._loggerService?.warn("SSEv2: User requested a reconnect which is not yet supported")
  }

  async close() {
    this.removeAllListeners()
    await this._serverConnection.close()
  }

  async abort() {
    await this._serverConnection.close()
    this.removeAllListeners()
  }
}

module.exports = ClientInterface
