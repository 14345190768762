const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")
const AgentUpdateModel = require("./AgentUpdateModel")

class Profile extends DataServiceEndPoint {
  static key = "Profile"

  get = ({ agentId }) => {
    return this._httpService.get(
      `${this.url}/v1/agents/${agentId}?withLanguages=true&withPartner=true&hasMfaEnabledCheck=true`,
      {
        headers: { Authorization: `bearer ${this.token}` },
      }
    )
  }

  _update = ({ data, agentId }) => {
    return this._httpService.put(`${this.url}/v1/agents/${agentId}`, data, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  update = ({ data, ...rest }) => {
    const payload = new AgentUpdateModel(data)

    return this._update({ data: payload.present(), ...rest })
  }
}

module.exports = Profile
