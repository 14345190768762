const { DataService } = require("@cloudbreakus/network-utilities")

const Interactions = require("./Interactions")
const Participant = require("./Participant")
const Languages = require("./Languages")
const Outsource = require("./Outsource")
const Partners = require("./Partners")
const Presence = require("./Presence")
const Profile = require("./Profile")
const Scripts = require("./Scripts")
const Agents = require("./Agents")
const Config = require("./Config")
const Martti = require("./Martti")
const Queues = require("./Queues")
const Calls = require("./Calls")
const Auth = require("./Auth")
const SSE = require("./SSE")

const EndPointCollection = [
  Interactions,
  Participant,
  Languages,
  Outsource,
  Partners,
  Presence,
  Profile,
  Scripts,
  Agents,
  Config,
  Martti,
  Queues,
  Calls,
  Auth,
  SSE,
]

class MvccDataService extends DataService {
  constructor(services) {
    super(services)

    for (const NetworkService of EndPointCollection) {
      this.attach(NetworkService)
    }

    const getterObj = {}
    for (const key of Object.keys(this._services)) {
      getterObj[key] = {
        get: function () {
          return this.get(key)
        },
      }
    }
    Object.defineProperties(this, getterObj)
  }
}

module.exports = MvccDataService
