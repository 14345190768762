import React, { createContext, useContext } from "react"

const initialValue = null

const CloudThemeContext = createContext(initialValue)

export const CloudThemeProvider = ({ children, theme }) => {
  return <CloudThemeContext.Provider value={theme}>{children}</CloudThemeContext.Provider>
}

export const useTheme = () => {
  const config = useContext(CloudThemeContext)
  if (!config) {
    throw new Error("Can not use useTheme outside of a CloudTheme Provider")
  }

  return config
}
