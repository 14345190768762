const { inAppMessaging } = require("./messaging")

export const openWindowAndAttachToParent = (url, key) => {
  const child = window.open(url, "_blank")

  inAppMessaging.handleNewWindowOpened(child, key)
}

export const closeWindowAndRemove = (key) => {
  return inAppMessaging.requestCloseWindow(key)
}

/**
 * Sends a message to a connected window
 * @param {*} type - Type of message, this is what the logic branches for
 * @param {*} message - content of the message
 * @param {*} service - Key of the service that is meant to receive the message
 */
export const sendMessage = (type, message, service) => {
  return inAppMessaging.sendMessage(type, message, service)
}

/**
 * Listens to a messaging event
 * @param {String} event
 * @param {Function} handler
 * @returns {Function} unsubscribe function
 */
export const listenToEvent = (event, handler) => {
  const listener = inAppMessaging.on(event, handler, { objectify: true })
  return () => {
    listener.off()
  }
}

export const handleWindowOpened = (window, key) => {
  inAppMessaging.handleNewWindowOpened(window, key)
  return sendMessage(inAppMessaging.messages.OPEN, { windowType: key }, key)
}
