"use strict";

/* istanbul ignore file */
module.exports = {
  config: {
    get: function get(key) {
      if (key === "accessToken") {
        return "420";
      }
      if (key === "auth.useAuthService") {
        return false;
      }
      if (key === "authenticator.url") {
        return "authurl";
      }
      if (key === "authenticator.resetUrl") {
        return "reseturl";
      }
      if (key === "authenticator.logoutEndpoint") {
        return "logouturl";
      }
      return "yolo";
    }
  },
  auth: {
    token: "420"
  },
  generateNetworkClientMock: function generateNetworkClientMock() {
    return {
      get: jest.fn(),
      put: jest.fn(),
      post: jest.fn(),
      "delete": jest.fn()
    };
  }
};