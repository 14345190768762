import { useState, useCallback, useEffect } from "react"
import { SSE } from "utils/SSE"
import { ASSIGNED, ACTIVE } from "constants/statuses"
import { getLoggerInstance } from "utils/logger"
import { dataService } from "utils/dataService"
import { usePresence, ON_BREAK, ACD_ASSIGNED } from "contexts/Presence"
import { parseTaskCallData } from "./utils/parseCallData"
import { usePureCallback } from "hooks/usePureCallback"
import { useAlertMessage } from "@cloudbreakus/ui-components"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"

const logger = getLoggerInstance()

export const useInitialCallStatus = ({
  userId,
  handleRingingCall,
  setAnsweredCall,
  initializeCallScreen,
  answeredCall,
  SSEopen,
  setNmsParticipantId,
}) => {
  const Presence = usePresence()
  const { showCustomToast } = useAlertMessage()
  const staleAssignedCallThresholdSeconds = useConfigFlag("calls.staleAssignedCallThresholdSeconds")
  const staleAssignedCallThresholdMs = staleAssignedCallThresholdSeconds ? staleAssignedCallThresholdSeconds * 1000 : undefined;

  const [initialCallStatus, setInitialCallStatus] = useState(null) // true/false if call active/assigned on login or not.  null if not yet checked
  const [presenceInitiallySet, setPresenceInitiallySet] = useState(false)

  const checkAssignedCall = useCallback(async () => {
    const response = await dataService.Calls.get({ agentId: userId, status: ASSIGNED })
    if (response?.data?.length) {
      const now = Date.now()
      const assignedCall = response.data.find((call) => {
        if (staleAssignedCallThresholdMs === undefined) {
          return true
        }
        const assignTime = new Date(call.assignTime);
        // Only consider calls assigned within the threshold
        return (now - assignTime.getTime()) <= staleAssignedCallThresholdMs;
      });
      if (assignedCall) {
        logger.debug("ringing call found", assignedCall)
        handleRingingCall(parseTaskCallData(assignedCall, userId))
        return true
      } else {
        logger.debug("ringing call assigned too long ago", {
          assignedCalls: response.data,
        });
        return false
      }
    } else {
      logger.debug("no ringing calls")
      return false
    }
  }, [handleRingingCall, userId, staleAssignedCallThresholdMs])

  const checkActiveCall = useCallback(async () => {
    let task
    try {
      const response = await dataService.Calls.get({ agentId: userId, status: ACTIVE })
      if (response?.data?.length) {
        logger.debug("active call found", response.data[0])
        task = parseTaskCallData(response.data[0], userId)
        if (task.interaction.mediaServer === "NMS") {
          const { data } = await dataService.Interactions.reconnect({
            agentId: userId,
            taskId: task.taskId,
            interactionId: task.interaction.id,
            languageId: task.language.id,
          })
          setNmsParticipantId(data.participantId)
        }
        initializeCallScreen()
        setAnsweredCall(task)
        return true
      } else {
        logger.debug("no active calls")
        return false
      }
    } catch (err) {
      if (err?.response?.status === 404) {
        setNmsParticipantId("NA")
        initializeCallScreen()
        setAnsweredCall(task)
        logger.debug(`room for task ${task} not found - customer likely hung up`)
      } else {
        logger.error("checking for (&/or joining) active calls failed", err)
        throw err
      }
    }
  }, [userId, initializeCallScreen, setAnsweredCall, setNmsParticipantId])

  const checkCallStatus = usePureCallback(async () => {
    if (answeredCall || !userId) {
      return null
    }

    let callStatus

    try {
      callStatus = (await checkActiveCall()) || (await checkAssignedCall())
    } catch (err) {
      logger.error("checking call status failed", err)
      showCustomToast({
        level: "error",
        message: "Checking your current call status failed",
        onRetry: checkCallStatus,
      })
      return
    }

    setInitialCallStatus((initial) => {
      if (initial !== null) {
        return initial
      }
      return callStatus
    })
  })

  useEffect(() => {
    if (SSEopen) {
      SSE.on("connect", checkCallStatus)

      return () => {
        SSE.off("connect", checkCallStatus)
      }
    }
  }, [SSEopen, checkCallStatus])

  useEffect(() => {
    //temporary//TODO: this should be done API-side for best practices
    if (initialCallStatus === false && !presenceInitiallySet && SSEopen && Object.entries(Presence.presences).length && Presence.name) {
      setPresenceInitiallySet(true)
      if (Presence.name !== ACD_ASSIGNED) {
        Presence.set(ON_BREAK)
      }
    }
  }, [presenceInitiallySet, initialCallStatus, Presence, SSEopen])

  return { initialCallStatusChecked: initialCallStatus !== null }
}
