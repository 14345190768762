import React from "react"

/**
 * To add a new icon while also keeping tree shaking effective, import the corresponding icon name from https://react-icons.github.io/react-icons/icons?name=io5
 * and then include the imported value inside exposedIcons so the app can use it
 */
import pencil from "./assets/pencil.svg"
import phoneIn from "./assets/phoneIn.svg"
import chevronUp from "./assets/chevronUp.svg"
import spinner from "./assets/spinner.svg"
import phoneOut from "./assets/phoneOut.svg"
import refresh from "./assets/refresh.svg"
import headset from "./assets/headset.svg"
import avatar from "./assets/avatar.svg"
import help from "./assets/help.svg"
import {
  IoCaretDown,
  IoPeopleOutline,
  IoHomeOutline,
  IoLogOutOutline,
  IoSettingsOutline,
  IoVolumeHigh,
  IoVolumeHighOutline,
  IoEye,
  IoEyeOff,
  IoVolumeMuteOutline,
  IoVideocam,
  IoVideocamOutline,
  IoVideocamOffOutline,
  IoMicOutline,
  IoMicOffOutline,
  IoKeyOutline,
  IoSaveOutline,
  IoCallOutline,
  IoCheckmark,
  IoDocumentTextOutline,
  IoBugOutline,
  IoHeadsetOutline,
  IoStopwatchOutline,
  IoPause,
  IoPersonOutline,
  IoSearch,
  IoLogoWindows,
  IoLogoApple,
  IoLogoTux,
  IoArrowDown,
  IoSpeedometerOutline,
} from "react-icons/io5"

/* Stryker disable next-line all */
const reactIcons = {
  IoCaretDown,
  "down-arrow": IoArrowDown,
  IoPeopleOutline,
  IoHomeOutline,
  IoLogOutOutline,
  IoSettingsOutline,
  IoVolumeHigh,
  IoVolumeHighOutline,
  IoEye,
  IoEyeOff,
  IoVolumeMuteOutline,
  IoVideocam,
  IoVideocamOutline,
  IoVideocamOffOutline,
  IoMicOutline,
  IoMicOffOutline,
  IoMdCheckmark: IoCheckmark,
  IoMdStopwatch: IoStopwatchOutline,
  IoKeyOutline,
  MdOutlinePhone: IoCallOutline,
  MdOutlineSave: IoSaveOutline,
  MdOutlineCheck: IoCheckmark,
  MdOutlineStickyNote2: IoDocumentTextOutline,
  MdOutlineBugReport: IoBugOutline,
  headphones: IoHeadsetOutline,
  pause: IoPause,
  HiOutlineUser: IoPersonOutline,
  search: IoSearch,
  windows: IoLogoWindows,
  apple: IoLogoApple,
  linux: IoLogoTux,
  meter: IoSpeedometerOutline,
}
const svgs = { pencil, phoneIn, chevronUp, spinner, phoneOut, refresh, headset, avatar, help }

const IconWrapper = ({ icon, className, testId, ...rest }) => {
  const IconComponent = svgs[icon] || reactIcons[icon]
  const sizeProps =
    svgs[icon] && rest.size
      ? {
          width: rest.size,
          height: rest.size,
        }
      : {}
  return IconComponent ? (
    <IconComponent {...sizeProps} className={className} data-testid={testId} {...rest} />
  ) : (
    <span>🛑</span>
  )
}

export default IconWrapper
