const EventEmitter = require("eventemitter2")

export class CallFormDataManager extends EventEmitter {
  changeDetected = false
  _queue = []
  _running = false

  submit() {
    this._queue.unshift("submit")
    this.run()
  }

  refresh() {
    this._queue.push("refresh")
    this.run()
  }

  wrapUp() {
    this._queue.push("wrap-up")
    this.run()
  }

  run() {
    if (!this._queue.length) {
      return
    }
    if (this._running) {
      return
    }
    this._running = true
    this.emit("running", true)
    const latestAction = this._queue.shift()
    if (latestAction === "wrap-up") {
      return this.onWrapUp()
    }
    if (this.changeDetected && latestAction === "refresh") {
      this._queue.push("refresh")
      this._running = false
      this.emit("running", false)
      return
    }
    this.emit(latestAction)
  }

  onSubmitted() {
    this.changeDetected = false
    this._running = false
    this.emit("running", false)
    this.run()
  }
  
  onRefreshed() {
    this._running = false
    this.emit("running", false)
    this.run()
  }

  detectChange() {
    this.changeDetected = true
  }

  onWrapUp() {
    this.changeDetected = false
    this._queue = []
    this._running = false
    this.emit("running", false)
  }
}

export const callFormDataManager = new CallFormDataManager()
