import React from "react"
import cs from "classnames"
import IconWrapper from "./IconWrapper"

/**
 * Reusable React component for displaying Icons
 *
 * @param {string} icon (required) - Icon name from reactIcons or from local /assets
 * @param {String} className - CSS classes to apply to the icon element
 * @param {String} rootClassName - CSS classes to apply to the button element (when onClick is set)
 * @param {Number} size - Icon size in pixels
 * @param {Function} onClick - Fires this function when icon is gets clicked
 * @param {String} aria-label - Label for accesibility
 * @param {String} data-testid - Test id used for testing
 * @param {Boolean} disabled - Used for specifying that the button should be disabled
 *
 */
const Icon = ({
  className,
  icon,
  rootClassName,
  onClick,
  "aria-label": label,
  "data-testid": testid,
  disabled,
  ...rest
}) => {
  return onClick ? (
    <button
      type="button"
      aria-label={label}
      onClick={onClick}
      data-testid={testid}
      className={cs(
        {
          "cursor-pointer": !disabled,
        },
        rootClassName
      )}
      disabled={disabled}
    >
      <IconWrapper className={className} icon={icon} testId={`icon-${testid}`} {...rest} />
    </button>
  ) : (
    <IconWrapper className={cs(className, rootClassName)} icon={icon} testId={testid} {...rest} />
  )
}

export default Icon
