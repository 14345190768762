import React, { useCallback } from "react"
import { useAlerts } from "../../contexts/Alerts"
import Notification from "./Notification"

export const useAlertMessage = () => {
  const { showToast, dismissToast } = useAlerts()

  const showErrorToast = useCallback(
    (message, ...rest) => {
      const config = { autoClose: false, ...rest?.[0] }
      return showToast(message, "error", config, ...rest.slice(1))
    },
    [showToast]
  )

  const showSuccessToast = useCallback(
    (message, ...rest) => {
      return showToast(message, "success", ...rest)
    },
    [showToast]
  )

  const showInfoToast = useCallback(
    (message, ...rest) => {
      return showToast(message, "info", ...rest)
    },
    [showToast]
  )

  const showWarnToast = useCallback(
    (message, ...rest) => {
      const config = { autoClose: false, ...rest?.[0] }

      return showToast(message, "warn", config, ...rest.slice(1))
    },
    [showToast]
  )

  /**
   * Shows retry-able toast message
   *
   * @param {Object} - takes message (String), level (String, ie. "warn", "error", "success", "info"), onRetry (Function called when the retry button is pressed)
   * @returns {Function} - Function that can be used to dismiss the toast
   */
  const showCustomToast = useCallback(
    ({ message, level, onRetry, closeable = false }) => {
      let id = null
      const dismiss = () => dismissToast(id)
      id = showToast(<Notification dismiss={dismiss} onRetry={onRetry} message={message} />, level, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: closeable,
      })
      return dismiss
    },
    [showToast, dismissToast]
  )

  return {
    showErrorToast,
    showSuccessToast,
    showInfoToast,
    showWarnToast,
    dismissToast,
    showCustomToast,
  }
}
