const { DTO } = require("@cloudbreakus/network-utilities")
const { serverToClient: translate } = require("../translate")

class PresenceDTO extends DTO {
  requiredFields = ["id", "name", "startTime"]
  acceptNulls = true

  toPresentationalObject(data) {
    return translate.presence(data)
  }
}

class CallSegmentsDTO extends DTO {
  requiredFields = ["callSegments"]
  acceptNulls = true

  toPresentationalObject(payload) {
    return payload?.callSegments?.map(translate.callSegment)
  }
}

class TasksDTO extends DTO {
  requiredFields = ["id", "tasks"]
  acceptNulls = true

  toPresentationalObject(data) {
    const task = data?.tasks[0]
    return translate.task(task)
  }
}

class ReservationDTO extends DTO {
  requiredFields = ["interactionId", "taskId", "reservationId", "queueChannel", "interactionChannel"]
  optionalFields = ["interactionName", "status", "createTime", "callerDetail", "queueId", "queueName", "mediaServer"]
  acceptNulls = true
  allowAllFields = true

  toPresentationalObject(data) {
    return translate.reservation(data)
  }
}

module.exports = {
  PresenceDTO,
  CallSegmentsDTO,
  TasksDTO,
  ReservationDTO,
}
