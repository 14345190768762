import React, { createContext, useContext } from "react"
import { useLanguages as useLanguagesData } from "./useLanguages"

const LanguagesContext = createContext()

export const useLanguages = () => {
  const context = useContext(LanguagesContext)
  if (context === undefined) {
    throw new Error("useLanguages must be used within a LanguagesProvider")
  }
  return context
}

export const LanguagesProvider = ({ children }) => {
  const value = useLanguagesData()
  return (
    <LanguagesContext.Provider
      value={value}
    >
      {children}
    </LanguagesContext.Provider>
  )
}

export default LanguagesContext
