const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

const scriptsGraphRequest = `
Scripts {
  scripts {
    id
    order
    text
    type
  }
}`

const htmlScriptsGraphRequest = `
Scripts {
  scripts: convertedScripts {
    id
    order
    text
    type
  }
}`


class Scripts extends DataServiceEndPoint {
  static key = "Scripts"

  _hasuraProcessor = (hasuraData) => {
    this._logger.debug("[DATA] Scripts updated", hasuraData?.data?.scripts)
    return hasuraData?.data?.scripts
  }

  get = async (...params) => {
    if (this._config.get("dataService.scripts") === "martti-api") {
      return this.getMartti(...params)
    }
    if (this._config.get("dataService.scripts") === "hasura-api") {
      return this.getHasura(...params)
    }
  }

  getMartti = async () => {
    const response = await this._httpService.get(`${this.url}/v1/scripts`, {
      headers: { Authorization: `Bearer ${this.token}` },
    })
    return response
  }

  getHasura = async () => {
    const request = this._config.get("scripts.useHtmlConversion") ? htmlScriptsGraphRequest : scriptsGraphRequest
    const result = await this._hasuraService.query({ query: `query ${request}` })
    return { data: this._hasuraProcessor(result) }
  }

  subscribe(fn, {data, onError} = {}) {
    return this._hasuraService.subscribe(
      fn,
      {
        subscriptionQuery: `subscription ${scriptsGraphRequest}`,
        data,
        postProcess: this._hasuraProcessor,
      },
      onError
    )
  }
}

module.exports = Scripts
