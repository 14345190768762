import React from "react"
import { Outlet } from "react-router-dom"
import cs from "classnames"
import Container from "components/Container/Container"
import { ReactComponent as MarttiLogo } from "assets/martti_logo.svg"

const LeftPane = () => {
  return (
    <Container
      name={"sign-in-left"}
      className={["flex", "flex-1", "flex-col", "bg-white", "text-left"]}
    >
      <Container name="sign-in-content">
        <div
          className={cs("w-full", "antialiased", "text-gray-900", "pt-20")}
        >
          <div
            className={cs(
              "flex",
              "flex-col",
              "w-full",
              "items-center",
              "justify-center"
            )}
          >
            <div className={cs("w-2/3", "xl:[width:448px]")}>
              <MarttiLogo
                className="w-[280px]"
                title="Martti Agent Logo"
              />

              <Outlet />
            </div>
          </div>
        </div>
      </Container>
    </Container>
  )
}

export default LeftPane
