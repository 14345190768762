import now from "dayjs"
import { config } from "./configService"

export const getAppVersion = () => {
  return config.get("version")
}

export const getAppHash = () => {
  return process.env.REACT_APP_GIT_SHA
}

export const getBuildDate = () => {
  return now(config.get("buildTimestamp") || Date.now()).format("D-MMM-YY h:mm A")
}

export const getAppId = () => {
  if (config.get("env") === "prod") {
    return getAppVersion()
  }
  let appId = [getAppVersion()]
  if (getAppHash()) {
    appId.push(getAppHash())
  }
  appId.push(`built ${getBuildDate()}`)
  return appId.join(" ")
}

export const getUserIp = () => fetch("https://api.ipify.org").then((res) => res.text())

const getAppInfo = {
  id: getAppId(),
  version: getAppVersion(),
  hash: getAppHash(),
  date: getBuildDate(),
}

export default getAppInfo
