import { dataService } from "utils/dataService"

const currentCallsSlice = {
  activeCalls: [],
  queuedCalls: [],
  supervisorCalls: [],
}

const createCurrentCallsSlice = (set) => ({
  ...currentCallsSlice,
  getActiveCalls: async (params) => {
    const response = await dataService.Calls.getActive(params)
    const activeCalls = response?.data
    set({ activeCalls })
    return activeCalls
  },
  getQueuedCalls: async (params) => {
    const response = await dataService.Calls.getQueued(params)
    const queuedCalls = response?.data
    set({ queuedCalls })
    return queuedCalls
  },
  getSupervisorCalls: async (queueId, params) => {
    const response = await dataService.Calls.getSupervisor({ ...params, queueId })
    const supervisorCalls = response?.data
    set({ supervisorCalls })
    return supervisorCalls
  },
})

export default createCurrentCallsSlice
