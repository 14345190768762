import { useCallback, useEffect, useRef, useState, useMemo } from "react"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { AudioBufferSourceNode } from "./AudioBufferSourceNode"
import { HTMLAudioElement } from "./HTMLAudioElement"
import ringTone from "assets/incomingCall.mp3"

/**
 * @typedef {Object} UseAudioMethodsAndState
 * @property {Function} play - Play the audio
 * @property {Function} stop - Stop the audio
 * @property {Function} toggle - Toggle the audio
 * @property {Function} isPlaying - Check if the audio is actively playing
 * @property {boolean} status - The current status of the audio (either "started" or "stopped")
 */

/**
 * @typedef {Object} UseAudioOptions
 * @property {string} file - The audio file to play
 * @property {boolean} loop - Whether the audio should loop
 * @property {boolean} pausable - Whether to use the API (HTMLAudioElement) that allows pausing (true) or the API (AudioBufferSourceNode) that doesn't (false)
 */

/**
 * React hook four sound effects and music
 *
 * @param {UseAudioOptions} - Object with "file" (link to audio file), "loop" (boolean), and "pausable" (boolean)
 * @returns {UseAudioMethodsAndState} - Object with "playing" (boolean) as well as "play", "stop", and "toggle" functions
 */
export const useAudio = ({ file = ringTone, loop = false, pausable } = {}) => {
  const advancedAudioApiFlag = useConfigFlag("advancedAudioApiEnabled")
  const advancedAudioApiEnabled = useMemo(
    () => (pausable === undefined ? advancedAudioApiFlag : !pausable),
    [pausable, advancedAudioApiFlag]
  )
  const soundClip = useRef(null)
  const [shouldPlay, setShouldPlay] = useState(false)

  const toggle = useCallback(() => setShouldPlay((playing) => !playing), [])

  const play = useCallback(() => setShouldPlay(true), [])

  const stop = useCallback(() => setShouldPlay(false), [])

  useEffect(() => {
    soundClip.current = advancedAudioApiEnabled ? new AudioBufferSourceNode(file) : new HTMLAudioElement(file)
    const stopPlaying = () => setShouldPlay(false)
    soundClip.current.on("end", stopPlaying)
    return () => {
      soundClip.current.off("end", stopPlaying)
      soundClip.current.stop()
      soundClip.current = null
    }
  }, [file, advancedAudioApiEnabled])

  useEffect(() => {
    soundClip.current.loop = loop
  }, [loop, file, advancedAudioApiEnabled])

  useEffect(() => {
    if (shouldPlay) {
      soundClip.current.play()
    } else {
      soundClip.current.stop()
    }
  }, [shouldPlay])

  const isPlaying = useCallback(() => {
    /* istanbul ignore next */
    if (!soundClip.current) {
      return false
    }
    return !soundClip.current.paused
  }, [])

  return { play, stop, toggle, isPlaying, status: shouldPlay ? "started" : "stopped" }
}
