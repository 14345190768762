const { DTO } = require("@cloudbreakus/network-utilities")

class OutsourceCallModel extends DTO {
  requiredFields = ["outsourcePartnerId", "channel", "outsourceNumber", "outsourceLanguageId", "outsourceLanguageName"]
  acceptNulls = true

  toPresentationalObject = (value) => {
    const { outsourcePartnerId, channel, outsourceNumber, outsourceLanguageId, outsourceLanguageName } = value
    return {
      outsourcePartnerId,
      channel,
      outsourceNumber,
      outsourceLanguage: {
        id: outsourceLanguageId,
        name: outsourceLanguageName,
      },
    }
  }
}

module.exports = OutsourceCallModel
