import React, { createContext, useEffect, useCallback, useState, useMemo } from "react"
import { noop } from "lodash"
import { useConfigFlag } from "@cloudbreakus/featurebang-react"
import { useAuth } from "contexts/Auth"
import { config } from "utils/configService"
import { dataService } from "utils/dataService"
import { logger } from "utils/logger"
import { createContextHook } from "utils/contextHelpers"
import { SUPERVISOR } from "constants/roles"
import { sortAlphabeticalByName, renameQueues } from "utils/helpers"
import useStore from "store"
import { selectError, selectQueues, selectReset } from "store/selectors/queues"
import { useAlertMessage } from "hooks/useAlertMessage"
import QueueStatsManager from "./QueueStatManager"
import { useQueueSubscribe } from "./useQueueSubscribe"

export const queueStatsManager = new QueueStatsManager({ config, logger, dataService })

const renameAndAlphabetize = (data) => {
  const renameKey = {
    activeQueues: "active",
    availableQueues: "available",
  }
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [renameKey[key] || key, value.sort(sortAlphabeticalByName)])
  )
}

const QueuesContext = createContext()

export const useQueues = createContextHook(QueuesContext, "useQueues must be used within a QueuesProvider")

export const QueuesProvider = ({ children }) => {
  const { userData, isAuthenticated } = useAuth()
  const { showCustomToast } = useAlertMessage()
  const enableAllQueues = useConfigFlag("dataService.queues") === "hasura-sub"
  const enableMyQueues = useConfigFlag("dataService.myQueues") === "hasura-sub"
  const [queues, setQueues] = useStore(selectQueues)
  const [error, setError] = useStore(selectError)
  const resetQueues = useStore(selectReset)
  const [hasVideo, setHasVideo] = useState()

  const _setAllQueues = useCallback(
    (data) => {
      return setQueues((existing) => ({
        ...existing,
        supervisor: data.find((v) => v.service === SUPERVISOR),
        all: data
          .map(renameQueues)
          .sort(sortAlphabeticalByName)
          .filter((a) => a.id && a.name),
      }))
    },
    [setQueues]
  )

  const _setMyQueues = useCallback(
    (data) => {
      const queues = renameAndAlphabetize(data)
      setQueues((existing) => ({
        ...existing,
        ...queues,
      }))
      if (queues?.active) {
        setHasVideo(queues.active.some((queue) => queue?.channel === "VIDEO"))
      }
    },
    [setQueues]
  )

  const enableAll = useMemo(() => enableAllQueues && isAuthenticated, [enableAllQueues, isAuthenticated])
  const enableMine = useMemo(() => enableMyQueues && isAuthenticated, [enableMyQueues, isAuthenticated])
  useQueueSubscribe({
    enableAll,
    enableMine,
    agentId: userData?.userId,
    partnerId: userData?.partnerId,
    setMyQueues: _setMyQueues,
    setAllQueues: _setAllQueues,
  })

  const _getMyQueues = useCallback(async () => {
    try {
      const response = await dataService.Queues.get({ agentId: userData?.userId })
      const data = response?.data
      logger.debug("User's active/available queues retrieved", data)
      _setMyQueues(data)
    } catch (err) {
      logger.error("Failed while fetching user's active/available queues", err)
      setError(err)
      showCustomToast({
        message: `Unable to fetch active/available skills.  Operating in audio-only mode until fixed.`,
        level: "error",
        onRetry: _getMyQueues,
      })
    }
  }, [setError, _setMyQueues, userData?.userId, showCustomToast])
  const getMyQueues = useCallback(() => (enableMyQueues ? noop() : _getMyQueues()), [_getMyQueues, enableMyQueues])

  const _getAllQueues = useCallback(async () => {
    try {
      const response = await dataService.Queues.get({ partnerId: userData?.partnerId })
      const data = response?.data
      if (!data || !data.length) {
        throw new Error("Empty list of queues")
      }
      logger.debug("Global queues listing retrieved", data)
      _setAllQueues(data)
    } catch (err) {
      logger.error("Failed while fetching global queues listing", err)
      setError(err)
      showCustomToast({
        message: `Critical application error: Unable to fetch all queues.`,
        level: "error",
        onRetry: _getAllQueues,
      })
    }
  }, [userData?.partnerId, _setAllQueues, setError, showCustomToast])
  const getAllQueues = useCallback(
    () => (enableAllQueues ? noop() : _getAllQueues(enableAllQueues)),
    [_getAllQueues, enableAllQueues]
  )

  useEffect(() => {
    if (!isAuthenticated || !userData?.userId) {
      return
    }
    getMyQueues()
    getAllQueues()
    return () => resetQueues()
  }, [getAllQueues, getMyQueues, isAuthenticated, resetQueues, userData?.userId])

  return (
    <QueuesContext.Provider
      value={{
        queues,
        getMyQueues,
        getAllQueues,
        hasVideo,
        error,
      }}
    >
      {children}
    </QueuesContext.Provider>
  )
}

export default QueuesContext
