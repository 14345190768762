import { useState, useEffect, useCallback } from "react"
import StringService from "@cloudbreakus/stringify"

const generateCurrentISOTimestamp = (value) =>
  StringService.format(value ? value : new Date(), StringService.HANDLERS.TIMESTAMP)

const generateCurrentUNIXTimestamp = (startTime) => {
  return StringService.validate(startTime, StringService.HANDLERS.TIMESTAMP)
    ? Date.parse(generateCurrentISOTimestamp(startTime))
    : Date.parse(generateCurrentISOTimestamp())
}

/**
 * @typedef {Object} HowLongSinceStart
 * @property {String} duration - formatted duration hh:mm:ss (ie. "04:20:11" for 4hr 20 minutes and 11 seconds)
 * @property {Number} elapsed - number of seconds since start
 */

/**
 * React Hook that takes a start time and returns how long its been since the start
 * 
 * @param {String} start - start time in JSON format (ie. "2022-03-13T14:55:40Z")
 * @returns {HowLongSinceStart} - Object with "duration" for a string representation (ie. "04:20:11") and "elapsed" for a numeric representation in seconds (ie. 420)
 */
export const useDuration = (start) => {
  const [initialTime, setInitialTime] = useState(generateCurrentUNIXTimestamp(start))
  const [elapsed, setElapsed] = useState(Math.floor((Date.now() - initialTime) / 1000))

  const computeTime = useCallback(() => {
    const seconds = (Date.now() - initialTime) / 1000
    setElapsed(Math.trunc(seconds))
  }, [initialTime])

  useEffect(() => {
    const interval = setInterval(() => computeTime(), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [computeTime])

  useEffect(() => {
    setInitialTime(generateCurrentUNIXTimestamp(start))
  }, [start])

  return {
    duration: StringService.format(elapsed, StringService.HANDLERS.DURATION),
    elapsed,
  }
}

export default useDuration
