import { useCallback, useEffect, useMemo, useState } from "react"
import { dataService } from "utils/dataService"
import { getLoggerInstance } from "utils/logger"
const logger = getLoggerInstance()

export const useAccessCode = ({
  validatedAccessCode,
  setValidatedAccessCode,
  unitId,
  ivrAccessCode,
  answeredCall,
  interactionId,
  accessCodeObject,
  loadingAccessCode,
  getPartnerAccessCode,
  refreshCallSheet,
}) => {
  const [validatingAccessCode, setValidatingAccessCode] = useState(false)
  const [validateAccessCodeStatus, setValidationStatus] = useState(null)
  const [validateAccessCodeResponse, setValidationResponse] = useState("")

  const validAccessCode = useMemo(
    () => accessCodeObject || unitId || ivrAccessCode || validatedAccessCode,
    [accessCodeObject, ivrAccessCode, unitId, validatedAccessCode]
  )

  const requiresAccessCode = useMemo(
    () => !loadingAccessCode && !accessCodeObject && !answeredCall?.interaction?.unitId,
    [accessCodeObject, answeredCall, loadingAccessCode]
  )

  const validateAccessCode = useCallback(
    async (code) => {
      setValidatingAccessCode(true)
      try {
        await dataService.Interactions.AccessCode.validate({ interactionId, accessCode: code })
        logger.debug(`validated access code ${code}`)
        setValidatedAccessCode(true)
        setValidationResponse(`Successfully validated code ${code}`)
        setValidationStatus("success")
        const callSheetData = await refreshCallSheet()
        getPartnerAccessCode({ accessCode: code, partnerId: callSheetData.partnerId })
      } catch (err) {
        logger.warn(`validation failed for access code ${code}`, err)
        setValidatedAccessCode(false)
        setValidationResponse(err?.response?.data?.message || `Access code "${code}" not validated`)
        setValidationStatus("error")
      }
      setValidatingAccessCode(false)
    },
    [getPartnerAccessCode, interactionId, refreshCallSheet, setValidatedAccessCode]
  )

  useEffect(() => {
    if (!answeredCall) {
      setValidatedAccessCode(false)
      setValidationResponse("")
      setValidationStatus("")
    }
  }, [answeredCall, setValidatedAccessCode])

  return {
    requiresAccessCode,
    validAccessCode,
    validateAccessCode,
    validatingAccessCode,
    validateAccessCodeStatus,
    validateAccessCodeResponse,
  }
}
