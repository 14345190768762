import React, { Component } from "react"
import BaseErrorPage from "../ErrorPage"

class ErrorBoundary extends Component {
  logger = null
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
    this.logger = props.logger
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.logger?.jsError?.(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      const { ErrorPage, header = "Oops, an error has occurred" } = this.props
      return this.props.showScreen && ErrorPage ? (
        <ErrorPage header={header} title={this.state.error?.message} error={this.state.error} />
      ) : (
        <BaseErrorPage header={header} title={this.state.error?.message} error={this.state.error} />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
