import React, { useContext, createContext, useState, useEffect } from "react"
import cs from "classnames"
import Icon from "@cloudbreakus/cloudbreak-react-icon"
import { twMerge } from "tailwind-merge"
import { useMultiSorter } from "./useMultiSorter"

const cn = (...inputs) => {
  return twMerge(cs(...inputs))
}

export const tableDefaultTheme = {
  headerCell:
    "bg-bblue-500 text-white text-left font-semibold text-xs p-3 whitespace-pre-line align-top relative first:rounded-tl-xl last:rounded-tr-xl",
  dataRow: "border-b border-gray-400 odd:bg-morange-50 even:bg-gray-50",
  dataCell: "p-3 text-left whitespace-pre-line",
  sortLabel: "flex-row items-center cursor-pointer inline-flex",
  sortIcon: "absolute top-[14px] fill-white right-0",
  lastSortIcon: "right-1",
}

const nextDir = {
  [-1]: 0,
  [0]: 1,
  [1]: -1,
}

export const TableContext = createContext()

export const useTableContext = () => {
  const context = useContext(TableContext)
  if (!context) {
    throw new Error("useTableContext must be used within a TableContainer")
  }
  return context
}

const orderName = {
  "alpha up": "alphabetical",
  "alpha down": "reverse alphabetical",
  "num up": "increasing",
  "num down": "decreasing",
}

export const TableContainer = ({
  theme = tableDefaultTheme,
  initialSortCriteria = {},
  alreadySorted,
  rows,
  numericColumns,
  multiSort = true,
  ...props
}) => {
  const { sorting: sortCriteria, setSorting: setSortCriteria, sorter } = useMultiSorter();
  const [sortedRows, setSortedRows] = useState(() => rows || [])
  const [sortIndex, setSortIndex] = useState({})

  useEffect(() => {
    if (alreadySorted || !sorter) {
      setSortedRows(rows || [])
      return
    }
    setSortIndex(Object.keys(sortCriteria).reduce((props, key, index) => ({ ...props, [key]: index + 1 }), {}))
    let unsortedRows = [...(rows || [])]
    const sortedRows = unsortedRows.sort(sorter);
    setSortedRows(sortedRows)
  }, [sortCriteria, rows, alreadySorted, sorter])

  useEffect(() => {
    if (!multiSort) {
      setSortCriteria((sortCriteria) => {
        const keys = Object.keys(sortCriteria)
        return keys.length > 1 ? { [keys[0]]: sortCriteria[keys[0]] } : sortCriteria
      })
    }
  }, [multiSort])

  const handleSort = (col) => {
    const nextDirection = nextDir[sortCriteria[col] || 0] // cycles from "falsey" to "1" to "-1" to 0...
    const criteria = multiSort ? { ...sortCriteria } : {}
    delete criteria[col]
    if (nextDirection) {
      setSortCriteria({ ...criteria, [col]: nextDirection })
    } else {
      setSortCriteria({ ...criteria })
    }
  }

  return (
    <TableContext.Provider
      value={{
        sortCriteria,
        sortedRows,
        handleSort,
        theme,
        numericColumns,
        alreadySorted,
        sortIndex,
      }}
    >
      <table {...props} />
    </TableContext.Provider>
  )
}

export const TableHead = (props) => <thead {...props} />

export const TableBody = (props) => <tbody {...props} />

export const TableFooter = (props) => <tfoot {...props} />

export const TableDataRow = ({ className, ...props }) => {
  const { theme } = useTableContext()
  return <tr className={cn(theme.dataRow, className)} {...props} />
}

export const TableDataCell = ({ className, ...props }) => {
  const { theme } = useTableContext()
  return <td className={cn(theme.dataCell, className)} {...props} />
}

export const TableHeaderCell = ({ className, ...props }) => {
  const { theme } = useTableContext()
  return <th scope="col" className={cn(theme.headerCell, className)} {...props} />
}

export const TableHeaderRow = (props) => {
  return <tr {...props} />
}

export const TableSortHandler = ({ onSort }) => {
  const { sortCriteria } = useTableContext()

  useEffect(() => {
    onSort(sortCriteria)
  }, [sortCriteria, onSort])

  return null
}

export const TableSortLabel = ({
  index,
  columns,
  column,
  children,
  className,
  icon = "IoCaretDown",
  iconClassName,
  onClick,
  ...props
}) => {
  const { sortCriteria, handleSort, theme, sortIndex, numericColumns } = useTableContext()

  const handleClick = (event) => {
    handleSort(column)
    if (onClick) {
      onClick(event)
    }
  }

  const label = (col, order) => {
    const columnName = children
    if (order === -1) {
      return `stop sorting by ${columnName} column`
    }
    const orderKey = `${numericColumns?.has(col) ? "num" : "alpha"} ${order ? "up" : "down"}`
    return `sort ${columnName} column in ${orderName[orderKey]} order`
  }

  return (
    <button
      className={cn(theme.sortLabel, className)}
      onClick={handleClick}
      aria-label={label(column, sortCriteria[column])}
      {...props}
    >
      <span>{children}</span>
      <Icon
        data-testid={`sort-${column}`}
        icon={icon}
        size={12}
        className={cn(
          theme.sortIcon,
          iconClassName,
          index + 1 === Object.keys(columns).length && theme.lastSortIcon,
          { "opacity-50": !sortCriteria[column] },
          { "rotate-180": sortCriteria[column] === -1 }
        )}
      />
      {Object.keys(sortCriteria).length > 1 && (
        <span
          className={cn([
            "text-[8px] top-0 absolute",
            index + 1 === Object.keys(columns).length ? "right-2" : "right-[5px]",
          ])}
        >
          {sortIndex[column]}
        </span>
      )}
    </button>
  )
}
