const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

class Partners extends DataServiceEndPoint {
  static key = "Partners"

  get = async ({ hospitalId }) => {
    const response = await this._httpService.get(`${this.url}/v1/partners/${hospitalId}?expand=true`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
    return response
  }

  getAccessCode = async ({ partnerId, accessCode }) => {
    const response = await this._httpService.get(
      `${this.url}/v1/partners/${partnerId}/access-codes${
        accessCode ? `?code=${accessCode}` : "?partnerAccessCodeOnly=true"
      }`,
      { headers: { Authorization: `bearer ${this.token}` } }
    )
    return response
  }
}

module.exports = Partners
