const { useEffect, useCallback } = require("react")
const { logger } = require("utils/logger")
const { openWindowAndAttachToParent, sendMessage, listenToEvent, handleWindowOpened } = require("utils/windowUtils")

const useInAppMessaging = (isInitialConnectionToParent, windowKey) => {
  const _openWindow = useCallback(
    (url) => {
      openWindowAndAttachToParent(url, windowKey)
    },
    [windowKey]
  )

  const _sendMessage = useCallback(
    (type, message) => {
      sendMessage(type, message, windowKey)
    },
    [windowKey]
  )

  useEffect(() => {
    const initialize = async () => {
      if (isInitialConnectionToParent) {
        try {
          await handleWindowOpened(window.opener, windowKey)
        } catch (err) {
          logger.error(
            `An error has ocurred while attempting to register a window to communicate with - Window possibly does not have an opener`,
            err
          )
        }
      }
    }
    initialize()
  }, [windowKey, isInitialConnectionToParent])

  return {
    openWindow: _openWindow,
    send: _sendMessage,
    onEvent: listenToEvent,
  }
}

export default useInAppMessaging
