const EventEmitter = require("eventemitter2")

class PromiseObject {
  constructor({expecting, resolve}) {
    this.expecting = expecting
    this.resolve = resolve
  }
}

export class PresenceManager extends EventEmitter {
  _promises = []

  onPresence(presence) {
    const promises = [...this._promises]
    this._promises = []
    for (const promise of promises) {
      promise.resolve(presence === promise.expecting)
    }
  }

  requestPresence(presence) {
    this.emit("nextPresence", presence)
    return new Promise((resolve) => {
      this._promises.push(new PromiseObject({
        expecting: presence,
        resolve,
      }))
    })
  }
}
