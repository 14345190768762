import React from "react"
import cs from "classnames"
import { useEventWrapper } from "../useEventWrapper"
import Wrapper from "../Wrapper"
import * as StyleSheet from "../styles"

const Select = ({
  name,
  label = "",
  value,
  defaultValue,
  options = [],
  onChange = () => null,
  disabled = false,
  className,
  theme = "",
  placeholder = "",
  error,
  ...rest
}) => {
  const styleSheet = StyleSheet[theme]?.(disabled, error) || StyleSheet["profile"](disabled, error)
  const _onChange = useEventWrapper(onChange)

  return (
    <Wrapper label={label} className={className} name={`select-${name}`} styleSheet={styleSheet} error={error}>
      <select
        aria-label={`select ${label}`}
        className={cs(styleSheet.input)}
        value={value}
        onChange={_onChange}
        disabled={disabled}
        {...rest}
      >
        {placeholder && (
          <option value={defaultValue} className="hidden" defaultValue>
            {placeholder}
          </option>
        )}
        {options && Array.isArray(options)
          ? options.map((option) => {
              return typeof option === "object" ? (
                <option key={option?.value} value={option?.value}>
                  {option?.text}
                </option>
              ) : (
                <option key={option} value={option}>
                  {option}
                </option>
              )
            })
          : Object.entries(options).map(([key, val]) => (
              <option key={key} value={key}>
                {typeof val === "object" ? val.text || val.name : val}
              </option>
            ))}
      </select>
    </Wrapper>
  )
}

export default Select
