const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

class Participant extends DataServiceEndPoint {
  static key = "Participant"

  hangUp = async ({ interactionId, participantId }) =>
    this._httpService.get(`${this.url}/v1/interactions/${interactionId}/participants/${participantId}/disconnect`, {
      headers: { Authorization: `bearer ${this.token}` },
    })

  hold = async ({ interactionId, participantId }) =>
    this._httpService.get(`${this.url}/v1/interactions/${interactionId}/participants/${participantId}/hold`, {
      headers: { Authorization: `bearer ${this.token}` },
    })

  unHold = async ({ interactionId, participantId }) =>
    this._httpService.get(`${this.url}/v1/interactions/${interactionId}/participants/${participantId}/unhold`, {
      headers: { Authorization: `bearer ${this.token}` },
    })
}

module.exports = Participant
