import React, { useState } from "react"
import cs from "classnames"
import Icon from "@cloudbreakus/cloudbreak-react-icon"
import CardHeader from "./CardHeader"

// TODO: Consider passing tabs as react children for more declarativ approach to tabs
const CollapsibleCard = ({
  children,
  name,
  title,
  className,
  minimize,
  minSize,
  saved,
  tabs,
  onSelectTab,
  selectedTab,
}) => {
  const [minimized, setMinimized] = useState(minimize)
  const minimumSize = minSize ? minSize : "3rem"
  return (
    <div
      data-testid={`container-collapsible-${name}`}
      className={cs(
        "flex-none bg-white rounded shadow-md",
        className,
        { "overflow-hidden": minimized && !minSize },
        { "pb-12": minimized && minSize }
      )}
      style={
        minimized
          ? { maxHeight: minimumSize, transition: "max-height 0.15s" }
          : { maxHeight: "10000px", transition: "max-height 0.25s" }
      }
    >
      <div className="flex flex-row px-4 py-2">
        <h2 className="flex-grow text-xl font-semibold text-left">{title}</h2>
        {typeof saved === "boolean" && (
          <div className="mt-1 mr-2">
            <Icon
              data-testid="saved"
              icon={saved ? "MdOutlineCheck" : "spinner"}
              className={cs({ "animate-spin": !saved }, "stroke-green-600", "fill-icon")}
            />
          </div>
        )}
        <Icon
          icon="chevronUp"
          size={24}
          className={cs({"rotate-180": minimized}, "stroke-icon")}
          onClick={() => setMinimized(!minimized)}
          aria-label={`${minimized ? "maximize" : "minimize"} ${title}`}
        />
      </div>
      <div className="flex flex-col" style={{ maxHeight: "100%" }}>
        {tabs?.length > 0 && <CardHeader onSelectTab={onSelectTab} tabs={tabs} selected={selectedTab} />}
        <div
          className={cs({
            "flex flex-grow overflow-y-scroll float-scrollbar inset-scrollbar scroll-smooth": minimized && minSize,
          })}
          style={
            minimized && !minSize
              ? { visibility: "hidden", transition: "visibility 0.15s" }
              : { visibility: "visible", transition: "visibility 0.25s" }
          }
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default CollapsibleCard
